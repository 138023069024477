import i18next from "i18next";

import isEmpty from "../../../../utilities/isEmpty";

const validateConsentMappingForm = (marketingProgram, consentUsecaseName) => {
  const errs = {};
  if (isEmpty(marketingProgram)) {
    errs.marketingProgramError = i18next.t("errors.field_required");
  }
  if (isEmpty(consentUsecaseName)) {
    errs.consentUsecaseNameError = i18next.t("errors.field_required");
  }

  return errs;
};

export default validateConsentMappingForm;
