import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    maxWidth: "80rem",
    minHeight: "100vh",
    paddingTop: "86px",
    margin: "0 auto",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    backgroundColor: "white",
  },
  content: {
    margin: "0 auto",
    width: "100%",
  },
}));

export default useStyles;
