import { useState, useContext, createElement } from "react";

import {
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  IconButton,
  Typography,
  styled,
  Tooltip,
} from "@material-ui/core";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as LightLogo } from "../../assets/images/CRS_COLOR_LOGO.svg";
import { ReactComponent as DarkLogo } from "../../assets/images/CRS_LOGO.svg";
import { ReactComponent as HelpIcon } from "../../assets/images/help.svg";
import { ReactComponent as HomeIcon } from "../../assets/images/home.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import { ReactComponent as MenuOpenIcon } from "../../assets/images/menu_open.svg";
import { lightTheme } from "../../assets/styles/themes";
import applicationConfig from "../../config/applicationConfig";
import useUserProfile from "../../hooks/useUserProfile";
import { ColorModeContext } from "../../providers/ColorModeProvider";
import checkUserAuthorization from "../../utilities/checkUserAuthorization";

import createReactElement from "../../utilities/createReactElement";

import useStyles from "./styles";

const { sidebarMenuConfig } = applicationConfig;

const openedMixin = (theme) => ({
  width: 300,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  padding: "0 8px",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "65px",
  padding: "0 4px",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 300,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const SidebarMenuItem = ({ text, path, icon, toggleDrawer }) => {
  return (
    <ListItem
      component={Link}
      to={path}
      button
      key={text}
      onClick={toggleDrawer}
    >
      {icon && <ListItemIcon>{createReactElement(icon)}</ListItemIcon>}
      <ListItemText primary={text} style={{ textIndent: "50px" }} />
    </ListItem>
  );
};

SidebarMenuItem.defaultProps = {
  path: "",
  icon: "",
};

SidebarMenuItem.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  toggleDrawer: PropTypes.func.isRequired,
};

const Sidebar = ({ toggleDrawer, isOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const colorMode = useContext(ColorModeContext);

  const { user } = useUserProfile();

  const { t } = useTranslation();

  const [toggleMenuItems, setToggleMenuItems] = useState(() => {
    return Array.from(Array(sidebarMenuConfig.length).keys()).map(() => false);
  });

  const helpMeMenuItem = sidebarMenuConfig.find(
    (config) => config.text === t("sidebar_menu_config.help_me")
  );

  return (
    <Drawer open={isOpen} className={classes.drawer} variant="permanent">
      <DrawerHeader className={classes.drawerHeader}>
        {colorMode.mode === "light" ? <LightLogo /> : <DarkLogo />}
        <IconButton onClick={toggleDrawer}>
          {isOpen
            ? createElement(MenuOpenIcon, {
                fill:
                  colorMode.mode === "light"
                    ? lightTheme.palette.static.darkerGrey
                    : lightTheme.palette.static.white,
              })
            : createElement(MenuIcon, {
                fill:
                  colorMode.mode === "light"
                    ? lightTheme.palette.static.darkerGrey
                    : lightTheme.palette.static.white,
              })}
        </IconButton>
      </DrawerHeader>
      <ListItem
        button
        component={Link}
        to="/"
        className={clsx(
          classes.homeListItem,
          history.location.pathname === "/" ? classes.itemActive : ""
        )}
        onClick={() => {
          if (isOpen) {
            toggleDrawer();
          }
        }}
      >
        <div className={classes.listItem}>
          {createElement(HomeIcon, {
            width: 30,
            height: 30,
            fill:
              colorMode.mode === "light"
                ? lightTheme.palette.static.darkerGrey
                : lightTheme.palette.static.white,
            style: {
              marginBottom: "8px",
            },
          })}
          {isOpen && <p>Home</p>}
        </div>
      </ListItem>
      <Typography variant="overline">{isOpen ? "MODULES" : ""}</Typography>
      <div className={classes.list}>
        <List>
          {sidebarMenuConfig
            .filter(
              (menuItem) =>
                ![
                  t("sidebar_menu_config.help_me"),
                  t("common.labels.my_requests"),
                  t("common.labels.my_approvals"),
                ].includes(menuItem.text) &&
                menuItem.referenceType ===
                  (localStorage.getItem("reference-type") || "Consumer")
            )
            .map((menuItem, index) => {
              if (
                checkUserAuthorization(user.access, menuItem.accessRights) &&
                !menuItem.hidden
              ) {
                return (
                  <div key={menuItem.text}>
                    <Tooltip title={menuItem.text} placement="right">
                      <ListItem
                        key={menuItem.text}
                        button
                        className={
                          history.location.pathname.includes(menuItem.id)
                            ? classes.itemActive
                            : ""
                        }
                        onClick={() => {
                          if (isOpen || menuItem.children.length > 1) {
                            const items = [...toggleMenuItems];
                            if (!isOpen) {
                              toggleDrawer();
                              for (let i = 0; i < items.length; i += 1) {
                                if (i === index) {
                                  items[i] = 1;
                                } else {
                                  items[i] = 0;
                                }
                              }
                            } else {
                              items[index] = !items[index];
                            }
                            setToggleMenuItems(items);
                          } else {
                            history.push(menuItem.path);
                          }
                        }}
                      >
                        <div className={classes.listItem}>
                          {createElement(menuItem.icon, {
                            width: 30,
                            height: 30,
                            fill:
                              colorMode.mode === "light"
                                ? lightTheme.palette.static.darkerGrey
                                : lightTheme.palette.static.white,
                          })}
                          {isOpen && <p>{menuItem.text}</p>}
                        </div>
                        {isOpen &&
                          (toggleMenuItems[index] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          ))}
                      </ListItem>
                    </Tooltip>
                    {isOpen && (
                      <Collapse in={toggleMenuItems[index]}>
                        {menuItem.children &&
                          menuItem.children.map((item, i) => {
                            if (
                              checkUserAuthorization(
                                user.access,
                                item.accessRights
                              ) &&
                              !item.hidden
                            ) {
                              return (
                                <div
                                  key={item.path}
                                  data-testid={`children-items-${i}`}
                                >
                                  <SidebarMenuItem
                                    text={item.text}
                                    path={item.path}
                                    toggleDrawer={toggleDrawer}
                                  />
                                </div>
                              );
                            }
                            return undefined;
                          })}
                      </Collapse>
                    )}
                  </div>
                );
              }
              return undefined;
            })}
        </List>
      </div>
      <div style={{ marginBottom: "15px" }}>
        <ListItem
          button
          className={clsx(
            classes.helpListItem,
            !toggleMenuItems[toggleMenuItems.length - 1]
              ? {}
              : classes.helpOpen,
            colorMode.mode === "dark" ? classes.helpListItemDark : {}
          )}
          onClick={() => {
            const items = [...toggleMenuItems];

            if (!isOpen) {
              toggleDrawer();
              for (let i = 0; i < items.length; i += 1) {
                if (i === items.length - 1) {
                  items[i] = 1;
                } else {
                  items[i] = 0;
                }
              }
            } else {
              items[items.length - 1] = !items[items.length - 1];
            }

            setToggleMenuItems(items);
          }}
        >
          <div className={classes.listItem}>
            {createElement(HelpIcon, {
              width: 30,
              height: 30,
              fill:
                colorMode.mode === "light"
                  ? lightTheme.palette.static.darkerGrey
                  : lightTheme.palette.static.white,
            })}
            {isOpen && <p>{helpMeMenuItem.text}</p>}
          </div>
          {isOpen &&
            (toggleMenuItems[toggleMenuItems.length - 1] ? (
              <ExpandLess
                style={{
                  fill:
                    colorMode.mode === "light"
                      ? lightTheme.palette.static.darkerGrey
                      : lightTheme.palette.static.white,
                }}
              />
            ) : (
              <ExpandMore
                style={{
                  fill:
                    colorMode.mode === "light"
                      ? lightTheme.palette.static.darkerGrey
                      : lightTheme.palette.static.white,
                }}
              />
            ))}
        </ListItem>
        {isOpen && (
          <Collapse
            in={toggleMenuItems[toggleMenuItems.length - 1]}
            className={clsx(
              classes.helpSubMenu,
              colorMode.mode === "dark" ? classes.helpSubMenuDark : {}
            )}
          >
            {helpMeMenuItem.children &&
              helpMeMenuItem.children.map((item) => {
                return (
                  <ListItem button key={item.text} onClick={toggleDrawer}>
                    <a href={item.path} target="_blank" rel="noreferrer">
                      <ListItemText primary={item.text} />
                    </a>
                  </ListItem>
                );
              })}
          </Collapse>
        )}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default Sidebar;
