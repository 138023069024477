import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    dummyContainer: {
      "& .MuiDataGrid-colCellWrapper": {
        backgroundColor: theme.colorTheme.bluishWhite,
      },
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    mb10: {
      marginBottom: "10px",
    },
  };
});

export default useStyles;
