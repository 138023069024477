import { useState, useEffect } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ReactComponent as EditIcon } from "../../../../assets/images/edit.svg";
import useGlobalStyles from "../../../../assets/styles/global";

import useAccordionStyles from "../../../../components/AccordionModal/styles";
import AccordionRowDetails from "../../../../components/AccordionRowDetails";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import CustomModal from "../../../../components/CustomModal";

import consentsModuleConfig from "../../../../config/consentsModuleConfig";
import languages from "../../../../config/languages";

import replaceValuesWithPlaceholders from "../helpers/replaceValuesWithPlaceholders";
import updateQuillContent from "../helpers/updateQuillContext";

const ReactQuill = require("react-quill");

const TemplateTexts = ({
  updatedConsentTexts,
  isDisabled,
  templateVariablesValues,
  onSubmit,
}) => {
  const { consentTemplateKeys } = consentsModuleConfig;
  const accordionClasses = useAccordionStyles();
  const [showModal, setShowModal] = useState(false);
  const [consentInfo, setConsentInfo] = useState({});
  const globalStyles = useGlobalStyles();
  const [consentTextMap, setConsentTextMap] = useState(updatedConsentTexts);
  const [openOnCloseWarningModal, setOpenOnCloseWarningModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setConsentTextMap(updatedConsentTexts);
  }, [updatedConsentTexts]);

  const groupedConsentTexts = Object.groupBy(
    Object.values(updatedConsentTexts),
    ({ consentTemplateType }) => consentTemplateType
  );

  const groupingConsentTexts = Object.keys(groupedConsentTexts)?.map(
    (groupingKey) => {
      const grouping = groupedConsentTexts[groupingKey].map((item) => {
        const consentTextKey = `${item.consentTemplateTypeId}-${item.countryCode}-${item.languageCode}`;
        const language = languages.find(
          (l) => l.languageCode === item.languageCode.toLowerCase()
        )?.languageName;

        const { consentText, noticeText } = item;

        return (
          <div style={{ paddingTop: 5 }}>
            <div
              className={clsx(globalStyles.flexContainer)}
              style={{ paddingBottom: 0 }}
            >
              <Accordion
                key={`${consentTextKey}-${item.languageCode}`}
                className={clsx(accordionClasses.accordion)}
              >
                <AccordionSummary
                  data-testid="accordion-summary"
                  className={accordionClasses.accordionHeader}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={accordionClasses.systemType}>
                    {language}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={accordionClasses.systemName}
                    dangerouslySetInnerHTML={{
                      __html: consentText,
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails className={accordionClasses.accordionContent}>
                  <AccordionRowDetails
                    keys={consentTemplateKeys}
                    row={{
                      consentText: updateQuillContent(
                        consentText || "",
                        templateVariablesValues
                      ),
                      noticeText: updateQuillContent(
                        noticeText || "",
                        templateVariablesValues
                      ),
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              {!isDisabled && (
                <IconButton
                  className={globalStyles.editButton}
                  style={{
                    width: 45,
                    height: 45,
                    position: "relative",
                    bottom: "10px",
                  }}
                  onClick={() => {
                    setConsentInfo({
                      consentTextKey,
                      ...item,
                    });
                    setShowModal(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>
          </div>
        );
      });
      return (
        <div
          style={{
            border: "1px solid #E0E0E0",
            padding: "20px",
            boxShadow: "0px 2px 3px #00000029",
            borderRadius: "25px",
          }}
        >
          {groupingKey}
          {grouping}
        </div>
      );
    }
  );

  return (
    <>
      {groupingConsentTexts}
      <CustomModal
        open={showModal}
        onClose={() => {
          setOpenOnCloseWarningModal(true);
        }}
        title={consentInfo?.consentTextKey}
        subtitle="Consent Text & Notice Text is listed below"
        showCloseIcon
      >
        <div style={{ marginBottom: 25 }}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            Consent Text
          </Typography>
          <ReactQuill
            theme="snow"
            value={updateQuillContent(
              consentTextMap[consentInfo?.consentTextKey]?.consentText || "",
              templateVariablesValues
            )}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onKeyPress={(e) => {
              e.preventDefault();
            }}
            onKeyUp={(e) => {
              e.preventDefault();
            }}
            onChange={(value, delta) => {
              const res = replaceValuesWithPlaceholders(
                value,
                consentInfo?.consentText || "",
                templateVariablesValues
              );
              if (
                (delta.ops || []).filter(
                  (ops) =>
                    Object.keys(ops).filter(
                      (key) => key !== "retain" && key !== "attributes"
                    ).length > 0
                ).length > 0
              ) {
                // if (source === "user") {
                //   setConsentTextMap({
                //     ...item,
                //   });
                // }
              } else {
                setConsentTextMap((ct) => ({
                  ...ct,
                  [consentInfo?.consentTextKey]: {
                    ...consentInfo,
                    consentText: res,
                  },
                }));
              }
            }}
            readOnly={isDisabled}
            modules={{
              toolbar: [["bold", "italic", "underline"]],
            }}
          />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            Notice Text
          </Typography>
          <ReactQuill
            theme="snow"
            value={updateQuillContent(
              consentInfo?.noticeText || "",
              templateVariablesValues
            )}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onKeyPress={(e) => {
              e.preventDefault();
            }}
            onKeyUp={(e) => {
              e.preventDefault();
            }}
            onChange={(value, delta) => {
              const res = replaceValuesWithPlaceholders(
                value,
                consentInfo?.noticeText || "",
                templateVariablesValues
              );
              if (
                (delta.ops || []).filter(
                  (ops) =>
                    Object.keys(ops).filter(
                      (key) => key !== "retain" && key !== "attributes"
                    ).length > 0
                ).length > 0
              ) {
                // if (source === "user") {
                //   setConsentTextMap({
                //     ...item,
                //   });
                // }
              } else {
                setConsentTextMap((ct) => ({
                  ...ct,
                  [consentInfo?.consentTextKey]: {
                    ...consentInfo,
                    noticeText: res,
                  },
                }));
              }
            }}
            readOnly={isDisabled}
            modules={{
              toolbar: [["bold", "italic", "underline"]],
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            classes={{
              root: globalStyles.btn,
            }}
            color="primary"
            onClick={() => {
              onSubmit(consentTextMap);
              setShowModal(false);
            }}
            style={{ marginTop: 20 }}
          >
            Submit
          </Button>
        </div>
      </CustomModal>
      {openOnCloseWarningModal && (
        <ConfirmationModal
          open={openOnCloseWarningModal}
          onClose={() => setOpenOnCloseWarningModal(false)}
          title={t("dialogs.confirm_remove")}
          message="Are you sure you want to close? Any Unsaved changes will be lost."
          btn1Text={t("common.cancel")}
          btn2Text={t("common.ok")}
          btn2Action={async () => {
            setConsentInfo({});
            setConsentTextMap(updatedConsentTexts);
            setShowModal(false);
            setOpenOnCloseWarningModal(false);
          }}
          type="error"
        />
      )}
    </>
  );
};

TemplateTexts.defaultProps = {
  isDisabled: false,
};

TemplateTexts.propTypes = {
  updatedConsentTexts: PropTypes.objectOf(PropTypes.string).isRequired,
  isDisabled: PropTypes.bool,
  templateVariablesValues: PropTypes.objectOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TemplateTexts;
