const getTraitEcosystemsApiResponse = () => ({
  apiVersion: "2.0.0",
  data: {
    items: Array.from({ length: 3 }).map((_, i) => ({
      ecosystemName: "segment",
      ecosystemTraitName: `traitChildrenIdValue${i}`,
      dataType: "string",
      dataClass: "Persona Traits",
      ecosystemTraitType: "Custom Trait",
      ecosystemTraitMiscProperty: "",
      personalDataException: false,
    })),
  },
});

const getTraitMarketingProgramsApiResponse = () => ({
  apiVersion: "2.0.0",
  data: {
    items: [
      {
        description: "EDS-CAN-1",
        ecosystems: "lytics",
      },
      {
        description: "Haircode-USA-1",
        ecosystems: "lytics",
      },
      {
        description: "OralCare-USA-1",
        ecosystems: "lytics",
      },
      {
        description: "SKII-JPN-1",
        ecosystems: "segment,lytics,dataLake,mapp,bigQuery",
      },
    ],
  },
});

const getSourceMarketingProgramsApiResponse = () => ({
  apiVersion: "2.0.0",
  data: {
    items: [
      {
        marketingProgramDescription: "EDS-CAN-1",
        marketingProgramNumber: 1,
      },
      {
        marketingProgramDescription: "Haircode-USA-1",
        marketingProgramNumber: 2,
      },
      {
        marketingProgramDescription: "OralCare-USA-1",
        marketingProgramNumber: 3,
      },
      {
        marketingProgramDescription: "SKII-JPN-1",
        marketingProgramNumber: 4,
      },
    ],
  },
});

const getSourceEcosystemsApiResponse = () => ({
  apiVersion: "2.0.0",
  data: {
    items: [
      {
        ecosystemName: "segment",
        ecosystemSourceKey: "",
        ecosystemSourceId: 1,
        ecosystemSourceLink: null,
        ecosystemSourceName: "JPN GrowingFamilies i11 (1001)",
        ecosystemSourceSlug: "jpn_growingfamilies_i11_1001",
        ecosystemSourceType: "http-api",
      },
      {
        ecosystemName: "segment",
        ecosystemSourceKey: "",
        ecosystemSourceLink: null,
        ecosystemSourceId: 2,
        ecosystemSourceName: "JPN GrowingFamilies i11 (1001)",
        ecosystemSourceSlug: "jpn_growingfamilies_i11_1001",
        ecosystemSourceType: "http-api",
      },
      {
        ecosystemName: "segment",
        ecosystemSourceKey: "",
        ecosystemSourceLink: null,
        ecosystemSourceId: 3,
        ecosystemSourceName: "JPN GrowingFamilies i11 (1001)",
        ecosystemSourceSlug: "jpn_growingfamilies_i11_1001",
        ecosystemSourceType: "http-api",
      },
    ],
  },
});

const getTraitsApiResponse = (_opts) => {
  const opts = _opts || {
    perPage: 50,
    startIndexOffset: 0,
  };
  return {
    data: {
      items: Array.from({ length: opts.perPage }).map((v, i) => ({
        traitId: opts.startIndexOffset ? i + 1 + opts.startIndexOffset : i + 1,
        traitName: `childrenIdValue${i}`,
        description: "Children",
        personalData: false,
        dataType: "string",
        marketingPrograms: [
          `Haircode-USA${i}`,
          "OralCare-USA-1",
          "EDS-CAN-1",
          "EDS-CAN-2",
        ],
        ecosystems: [
          `segment-traitChildrenIdValue${i}`,
          "lytics-trait_children_id_value",
          "mapp-traitChildrenIdValue",
          "mapp-traitChildrenIdValues",
        ],
      })),
      totalItems: 200,
    },
  };
};

const getEventsApiResponse = (_events) => {
  const events = _events || {
    perPage: 50,
    startIndexOffset: 0,
  };
  return {
    data: {
      items: Array.from({ length: events.perPage }).map((v, i) => ({
        eventId: events.startIndexOffset ? i + events.startIndexOffset : i,
        eventName: `event-name${i}`,
        eventDescription: `event-description${i}`,
        gacaEventName: `event_plan${i}`,
        actionTakenBy: `event_action${i}`,
        properties: i > 20 ? "" : "bigCommerceId,brand,brandGroup,brandGroup2",
        marketingPrograms:
          i > 20
            ? ""
            : "CHN Living Artist,IND Golden Household,JPN Golden Household,KOR Olay",
      })),
      totalItems: 200,
    },
  };
};

const getEventRequestSubmission = () => ({
  apiVersion: "1.5.0",
  data: {
    items: [
      {
        requestId: 922,
        status: "approved",
        errorMessage: null,
        eventProperties: [
          {
            propertyName: "brandGroup",
            dataType: "",
            description:
              "Brand Group Name (created for and maintained by US PGGE)",
            valueType: ["ALL"],
          },
        ],
        marketingPrograms: [
          {
            marketingProgramNumber: 3,
            marketingProgramName: "Olay-KOR-1",
            description: "KOR Olay",
          },
        ],
        ecosystems: [{ ecosystemId: 2, ecosystemName: "lytics" }],
        output: {
          items: [
            {
              eventName: "Add to Subscription Plan",
              eventProperty: "brandGroup",
              eventSubProperty: "",
              eventDataType: "",
              eventValueType: "ALL",
              marketingProgramNumber: 3,
              marketingProgramName: "Olay-KOR-1",
              marketingProgramDescription: "KOR Olay",
              ecosystemId: 2,
              ecosystemName: "lytics",
              mappingNew: 0,
              new: false,
              mappingWarning:
                "Event Property Mapping Add to Subscription Plan brandGroup   ALL already exists",
            },
          ],
          warnings: [],
        },
      },
    ],
  },
});

const getEventRequestDetails = () => ({
  apiVersion: "1.8.2",
  data: {
    requestId: 922,
    name: "Add to Subscription Plan",
    requestDate: "2022-08-03T09:34:30.000Z",
    status: "approved",
    requestedBy: "developer.approve",
    reviewedBy: "developer.approve",
    comments: "Auto Approved",
    output: {
      items: [
        {
          new: false,
          eventName: "Add to Subscription Plan",
          mappingNew: 0,
          ecosystemId: 2,
          ecosystemName: "lytics",
          eventDataType: "",
          eventProperty: "brandGroup",
          eventValueType: "ALL",
          mappingWarning:
            "Event Property Mapping Add to Subscription Plan brandGroup   ALL already exists",
          eventSubProperty: "",
          marketingProgramName: "Olay-KOR-1",
          marketingProgramNumber: 3,
          marketingProgramDescription: "KOR Olay",
        },
      ],
      warnings: [],
    },
    geoRegionCodes: ["APAC"],
    requestDetails: {
      eventName: "Add to Subscription Plan",
      ecosystems: [{ ecosystemId: 2, ecosystemName: "lytics" }],
      eventProperties: [
        {
          dataType: "",
          valueType: ["ALL"],
          description:
            "Brand Group Name (created for and maintained by US PGGE)",
          propertyName: "brandGroup",
        },
      ],
      marketingPrograms: [
        {
          description: "KOR Olay",
          marketingProgramName: "Olay-KOR-1",
          marketingProgramNumber: 3,
        },
      ],
    },
  },
});

const getEventDetailsApiResponse = () => ({
  apiVersion: "1.0.0",
  data: {
    eventName: "Event Name",
    description: "Event Description",
    properties: [
      {
        propertyName: "Property1",
        dataType: "dataType",
        required: false,
        sampleValue: "value1",
        subProperty: "subproperty1",
        description: "property description 1",
      },
      {
        propertyName: "Property2",
        dataType: "dataType2",
        required: false,
        sampleValue: "value2",
        subProperty: "subproperty2",
        description: "property description 2",
      },
    ],
  },
});

const getEventMappings = () => ({
  apiVersion: "1.6.0",
  data: {
    eventName: "Add to Subscription Plan",
    description: "",
    marketingProgramEventProperties: [
      {
        marketingProgramNumber: 1,
        description: "IND Golden Household",
        eventProperties: [
          {
            eventProperty: "bigCommerceId",
            eventPropertyDataType: "",
            eventPropertyValyeType: ["ALL", "LATEST"],
            eventPropertyDescription:
              "customer's unique id in the Big Commerce System",
          },
          {
            eventProperty: "brandGroupCode",
            eventPropertyDataType: "",
            eventPropertyValyeType: ["LATEST"],
            eventPropertyDescription:
              "Brand Group Code (created for and maintained by US PGGE)",
          },
          {
            eventProperty: "sector",
            eventPropertyDataType: "string",
            eventPropertyValyeType: ["LATEST", "ALL"],
            eventPropertyDescription: "P&G Sector",
          },
        ],
      },
      {
        marketingProgramNumber: 2,
        description: "JPN Golden Household",
        eventProperties: [
          {
            eventProperty: "brand",
            eventPropertyDataType: "",
            eventPropertyValyeType: ["LATEST"],
            eventPropertyDescription: "P&G Brand",
          },
          {
            eventProperty: "brandGroupCode",
            eventPropertyDataType: "",
            eventPropertyValyeType: ["ALL"],
            eventPropertyDescription:
              "Brand Group Code (created for and maintained by US PGGE)",
          },
          {
            eventProperty: "consumerId",
            eventPropertyDataType: "String",
            eventPropertyValyeType: ["LATEST", "ALL"],
            eventPropertyDescription:
              "Customer id from the main system registering the consumer for this program.  This is usually Janrain if the program is using Janrain for it's CIAM system.  It is Big Commerce customer id if using Big Commerce alone.  This IS NOT to be used by offline sources.",
          },
          {
            eventProperty: "leadKey",
            eventPropertyDataType: "",
            eventPropertyValyeType: ["LATEST", "ALL"],
            eventPropertyDescription:
              "unique key generated to represent a send of a communication to a consumer.  Unique by program/source/consumer",
          },
          {
            eventProperty: "productId",
            eventPropertyDataType: "",
            eventPropertyValyeType: ["LATEST"],
            eventPropertyDescription: "product ID",
          },
          {
            eventProperty: "productName",
            eventPropertyDataType: "",
            eventPropertyValyeType: ["ALL", "LATEST"],
            eventPropertyDescription: "Product Name",
          },
          {
            eventProperty: "subBrand",
            eventPropertyDataType: "String",
            eventPropertyValyeType: ["ALL"],
            eventPropertyDescription: "P&G sub Brand",
          },
        ],
      },
      {
        marketingProgramNumber: 3,
        description: "KOR Olay",
        eventProperties: [
          {
            eventProperty: "brand",
            eventPropertyDataType: "",
            eventPropertyValyeType: ["ALL"],
            eventPropertyDescription: "P&G Brand",
          },
          {
            eventProperty: "brandGroup",
            eventPropertyDataType: "",
            eventPropertyValyeType: ["LATEST", "ALL"],
            eventPropertyDescription:
              "Brand Group Name (created for and maintained by US PGGE)",
          },
        ],
      },
      {
        marketingProgramNumber: 13,
        description: "NZL Herbal Essence",
        eventProperties: [
          {
            eventProperty: "segment",
            eventPropertyDataType: "string",
            eventPropertyValyeType: ["ALL"],
            eventPropertyDescription: "P&G Segment",
          },
        ],
      },
      {
        marketingProgramNumber: 38,
        description: "CHN Living Artist",
        eventProperties: [
          {
            eventProperty: "subBrand",
            eventPropertyDataType: "String",
            eventPropertyValyeType: ["LATEST"],
            eventPropertyDescription: "P&G sub Brand",
          },
        ],
      },
    ],
  },
});

const getUseCasesApiResponse = (_opts) => {
  const opts = _opts || {
    perPage: 48,
    startIndexOffset: 0,
  };

  const items = Array.from({ length: opts.perPage }).map((v, i) => ({
    useCaseId: opts.startIndexOffset ? i + 1 + opts.startIndexOffset : i + 1,
    useCaseName: `childrenIdValue${i}`,
    useCaseDescription: "Children",
    traits: [
      {
        traitId: i,
        traitName: `referral-${i}`,
      },
      {
        traitId: i + 1,
        traitName: `referral-${i + 1}`,
      },
    ],
  }));

  return {
    data: {
      items: [
        {
          useCaseId: 71,
          useCaseName: "childrenIdValue12",
          useCaseDescription: "Children",
          traits: [
            {
              traitId: 1,
              traitName: "referral-1",
            },
            {
              traitId: 3,
              traitName: "referral-3",
            },
            {
              traitId: 7,
              traitName: "referral-7",
            },
            {
              traitId: 8,
              traitName: "referral-8",
            },
          ],
        },
        {
          useCaseId: 79,
          useCaseName: "childrenIdValue12",
          useCaseDescription: "Children",
          traits: [],
        },
        ...items,
      ],
      totalItems: 200,
    },
  };
};

const getUseCaseDetailsApiResponse = () => ({
  apiVersion: "1.0.0",
  data: {
    useCaseId: 1,
    useCaseName: "Receipt Scan Verified via Consumer Events API",
    useCaseDescription: "Receipt Scan Verified via Consumer Events A #2",
    marketingProgramTraits: [
      {
        marketingProgramNumber: 1,
        marketingProgramName: "GHH-IND-1",
        marketingProgramDescription: "IND Golden Household",
        traits: [
          {
            traitId: 2,
            dataType: "string",
            traitName: "referral",
            description: "Referral",
            personalData: false,
            traitResponses: null,
          },
          {
            traitId: 2174,
            dataType: "string",
            traitName: "growHair",
            description: "Grow Hair",
            personalData: false,
            traitResponses: null,
          },
        ],
      },
      {
        marketingProgramNumber: 2,
        marketingProgramName: "GHH-JPN-1",
        marketingProgramDescription: "JPN Golden Household",
        traits: [
          {
            traitId: 1,
            dataType: "string",
            traitName: "childrenIdValue",
            description: "Children",
            personalData: false,
            traitResponses: null,
          },
        ],
      },
    ],
  },
});

const getSourcesApiResponse = (_opts) => {
  const opts = _opts || {
    itemsPerPage: 50,
    page: 1,
  };
  return {
    data: {
      items: Array.from({ length: opts.perPage }).map((v, i) => ({
        sourceId: opts.startIndexOffset ? i + 1 + opts.startIndexOffset : i + 1,
        sourceName: `i2dmg-i5GA-i11dmg/www/${i + 1}`,
        description: "Children",
        marketingPrograms: [
          `JPN Golden Household-${i + 1}`,
          "JPN Whisper",
          "JPN Corporate Program",
        ],
        ecosystemSourceNames: [
          `JPN GrowingFamilies BazaarVoice ${i + 1}`,
          "JPN GrowingFamilies Bazaar Website",
          "JPN GrowingFamilies Website",
        ],
      })),
      totalItems: 200,
    },
  };
};

const getOptsApiResponse = (_opts) => {
  const opts = _opts || {
    itemsPerPage: 50,
    page: 1,
  };
  return {
    data: {
      items: Array.from({ length: opts.perPage }).map((v, i) => ({
        ciamOptId: opts.startIndexOffset
          ? `${i + 1 + opts.startIndexOffset}_00`
          : `${i + 1}_00`,
        optTextEnglish: "legacy Opt - pre-GRS",
        cdpMarketingPrograms: [`${i + 1} - IND Golden Household`],
        ciamMarketingProgram: "21 - AUS Silver Houseleave",
        traitNames: [`emailFrance-${i + 1}`],
      })),
      totalItems: 200,
      page: 1,
    },
  };
};

const getOptVersionsResponse = () => ({
  data: {
    apiVersion: "1.0.0",
    items: [
      {
        ciamOptVersionNumber: 123,
        createdDate: "2021-10-03T16:33:29.000Z",
        optStartDate: "2021-10-03T16:33:29.000Z",
        optTextEnglish:
          "Pourquoi collectons nous ces données? Vous vous inscrivez comme utilisateur de Pampers dont Procter & Gamble est le responsable du traitement. En vous inscrivant, confirmez que: Inscription aux e-mails de marketing. Vous souhaitez recevoir des e-mails, des offres et des bons de réduction de la part de Pampers et d’autres marques de confiance de P&G. Vos choix. Vous pouvez faire valoir vos close Comme nous l’expliquons dans notre politique de confidentialité, vous pouvez accéder, corriger, supprimer, transférer vers un autre fournisseur, refuser ou restreindre notre traitement des données personnelles que nous détenons à votre sujet, ou retirer votre consentement. droits liés à la protection des données ou modifier vos préférences et vos abonnements en cliquant ici. Conditions générales et politique de confidentialité. Vous avez au moins 16 ans et vous avez lu et accepté les Conditions générales et la Politique de confidentialité de .",
        optTextLocalLanguage: "local text",
        cdpMarketingPrograms: [
          "1 - IND Golden Household",
          "20 - IND Corporate Program",
        ],
      },
    ],
  },
});

const getOptTextScopeMappingResponse = () => ({
  data: {
    apiVersion: "1.0.0",
    items: [
      {
        cdpMarketingProgramDescription: "IND Golden Household",
        cdpMarketingProgramNumber: 1,
        contactPointCategoryCode: "EP",
        contactPointTypeCode: "E",
        primaryIndicator: "Primary",
        serviceName: "RewardMe India",
      },
    ],
  },
});

const getOptTraitMappingsResponse = () => ({
  response: {
    status: 200,
    data: {
      apiVersion: "1.0.0",
      items: [
        {
          cdpMarketingProgramDescription: "FRA Golden Household",
          traitId: 2830,
          traitName: "emailFranceCorporateNewsletterOptIndicator",
          serviceNames: ["France Corporate Newsletter"],
          ecosystemTraitNames: [
            {
              ecosystemName: "segment",
              ecosystemTraitName: "emailFranceCorporateNewsletterOptIndicator",
            },
            {
              ecosystemName: "1CP",
              ecosystemTraitName: "emailFranceCorporateNewsletterOptIndicator",
            },
          ],
        },
      ],
    },
  },
});

const getOptMappingsApiResponse = () => ({
  data: {
    totalItems: 4,
    items: [
      {
        ciamOptId: "1_00",
        optTextEnglish: "default",
        subscriptionOptNumber: 1,
        serviceName: "RewardMe India",
        contactPointTypeCode: "E",
        contactPointTypeName: "Email Address",
      },
      {
        ciamOptId: "1_00",
        optTextEnglish: "legacy Opt - pre-GRS",
        subscriptionOptNumber: 1,
        serviceName: "RewardMe India",
        contactPointTypeCode: "E",
        contactPointTypeName: "Email Address",
      },
      {
        ciamOptId: "1_01",
        optTextEnglish:
          "By registering, I agree to receive emails from Rewardme and other trusted <a href=http://us.pg.com/our-brands target=_blank>P&G brands</a> and programs. Click to read <a href=http://www.pg.com/en_IN/terms_conditions/index.shtml target=_blank>P&G Terms and Conditions</a> and <a href=http://www.pg.com/privacy/english/privacy_notice.shtml target=_blank>P&G Privacy Policy </a>.",
        subscriptionOptNumber: 1,
        serviceName: "RewardMe India",
        contactPointTypeCode: "E",
        contactPointTypeName: "Email Address",
      },
      {
        ciamOptId: "1_01",
        optTextEnglish:
          "By registering, I agree to receive emails from Rewardme and other trusted <a href=http://us.pg.com/our-brands target=_blank>P&G brands</a> and programs. Click to read <a href=http://www.pg.com/en_IN/terms_conditions/index.shtml target=_blank>P&G Terms and Conditions</a> and <a href=http://www.pg.com/privacy/english/privacy_notice.shtml target=_blank>P&G Privacy Policy </a>.",
        subscriptionOptNumber: 4,
        serviceName: "RewardMe India",
        contactPointTypeCode: "E",
        contactPointTypeName: "Email Address",
      },
    ],
  },
});

const getTraitsApiErrorResponse = () => ({
  response: {
    status: 400,
    data: {
      apiVersion: "1.0.0",
      error: {
        code: 400,
        message: "Bad Request",
        errors: [
          {
            msg: "traitage is an invalid query parameter.",
            param: "traitage",
            location: "query",
          },
        ],
      },
    },
  },
});

const getNetworkErrorResponse = () => ({
  data: {
    userMessage: "API Unavailable",
  },
});

const getMarketingProgramsApiResponse = () => ({
  apiVersion: "0.1.0",
  data: {
    totalItems: 881,
    page: 1,
    itemsPerPage: 3,
    items: [
      {
        marketingProgramNumber: 1,
        marketingProgramName: "GHH-IND-1",
        legalEntityName: "India",
        legalEntityNumber: 1,
        countryCode: "IND",
        description: "JPN Golden Household",
        marketingProgramDescription: "JPN Golden Household",
      },
      {
        marketingProgramNumber: 2,
        marketingProgramName: "GHH-JPN-1",
        legalEntityName: null,
        legalEntityNumber: 2,
        countryCode: "JPN",
        description: "GHH Golden Household",
        marketingProgramDescription: "GHH Golden Household",
      },
      {
        marketingProgramNumber: 3,
        marketingProgramName: "Olay-KOR-1",
        legalEntityName: null,
        legalEntityNumber: 3,
        countryCode: "KOR",
        marketingProgramDescription: "KOR Olay Household",
      },
    ],
  },
});

const getLegalEntitiesApiResponse = () => {
  return {
    data: {
      items: [
        {
          geocRegion: `APAC`,
          geocSubRegion: 1,
          legalEntityName: "Japan",
          legalEntityNumber: 1,
          legalEntityShortName: "JPN",
        },
        {
          geocRegion: `APAC`,
          geocSubRegion: 2,
          legalEntityName: "India",
          legalEntityNumber: 2,
          legalEntityShortName: "IND",
        },
        {
          geocRegion: `APAC`,
          geocSubRegion: 3,
          legalEntityName: "USA",
          legalEntityNumber: 3,
          legalEntityShortName: "US",
        },
      ],
      totalItems: 50,
      page: 1,
      itemsPerPage: 50,
    },
  };
};

const getSearchMarketingProgramsApiResponse = () => {
  return {
    data: {
      items: Array.from({ length: 50 }).map((v, i) => ({
        marketingProgramNumber: i + 1,
        marketingProgramDescription: `IND Golden Household ${i + 1}`,
        legalEntityNumber: i + 1,
        legalEntityName: `India ${i + 1}`,
        mpBusinessName: `GHH-IND-${i + 1}`,
        brandName: `Golden Household ${i + 1}`,
        marketingProgramStatus: `ACTIVE-JANRAIN ${i + 1}`,
        countryCodes: "IND",
        ecosystems: [
          {
            ecosystemId: 1,
            ecosystemName: "segment",
            ecosystemMarketingProgramName: `IND${i + 1}`,
          },
          {
            ecosystemId: 2,
            ecosystemName: "lytics",
            ecosystemMarketingProgramName: `PG${i + 1}`,
          },
          {
            ecosystemId: 4,
            ecosystemName: "mapp",
            ecosystemMarketingProgramName: `India${i + 1}`,
          },
          {
            ecosystemId: 6,
            ecosystemName: "janrain",
            ecosystemMarketingProgramName: `GHH${i + 1}`,
          },
        ],
      })),
      totalItems: 200,
      page: 1,
      itemsPerPage: 20,
    },
  };
};

const getMarketingProgramsEcosystemsApiResponse = () => ({
  data: {
    totalItems: 4,
    page: 1,
    itemsPerPage: 20,
    items: [
      {
        ecosystemId: 1,
        marketingProgramNumber: 1,
        ecosystemMarketingProgramName: "IND",
        ecosystemMarketingProgramKey: "spa_1W8oZWgy0ApqylbMkxLOqOc3iQx",
        ecosystemMarketingProgramInstance: null,
      },
      {
        ecosystemId: 2,
        marketingProgramNumber: 1,
        ecosystemMarketingProgramName: "PG",
        ecosystemMarketingProgramKey: "3425",
        ecosystemMarketingProgramInstance: null,
      },
      {
        ecosystemId: 4,
        marketingProgramNumber: 1,
        ecosystemMarketingProgramName: "India",
        ecosystemMarketingProgramKey: "6000503748",
        ecosystemMarketingProgramInstance: "pg_c360india_ghh",
      },
      {
        ecosystemId: 6,
        marketingProgramNumber: 1,
        ecosystemMarketingProgramName: "GHH",
        ecosystemMarketingProgramKey: null,
        ecosystemMarketingProgramInstance: "us",
      },
    ],
  },
});

const getMarketingProgramOptsApiResponse = () => ({
  apiVersion: "0.1.0",
  data: {
    totalItems: 3,
    page: 1,
    itemsPerPage: 3,
    items: [
      {
        cdpMarketingProgramDescription: "IND Golden Household",
        cdpMarketingProgramNumber: 1,
        contactPointCategoryCode: "EP",
        contactPointTypeCode: "E",
        primaryIndicator: "Primary",
        serviceName: "RewardMe India",
        subscriptionOptNumber: 1,
      },
      {
        marketingProgramNumber: 2,
        marketingProgramName: "GHH-JPN-1",
        legalEntityName: null,
        legalEntityNumber: 2,
        countryCode: "JPN",
        description: "GHH Golden Household",
      },
      {
        marketingProgramNumber: 3,
        marketingProgramName: "Olay-KOR-1",
        legalEntityName: null,
        legalEntityNumber: 3,
        countryCode: "KOR",
        description: "Olay KOR Household",
      },
    ],
  },
});

const getServiceNamesApiResponse = () => ({
  apiVersion: "0.1.0",
  data: [
    {
      serviceName: "IND Olay 234",
      description: null,
    },
    {
      serviceName: "IND Olay wer",
      description: null,
    },
    {
      serviceName: "IND Olay werwer",
      description: null,
    },
  ],
});

const createTraitRequestResponse = () => ({
  apiVersion: "0.1.0",
  data: {
    warnings: [],
    items: [
      {
        requestId: "CDS51",
        errorMessage: null,
        trait: { traitId: 1, traitName: "childrenIdValue" },
        ecosystems: [
          { ecosystemId: 4, ecosystemName: "mapp" },
          { ecosystemId: 3, ecosystemName: "dataLake" },
        ],
        marketingPrograms: [
          { marketingProgramNumber: 2, marketingProgramName: "GHH-JPN-1" },
          { marketingProgramNumber: 1, marketingProgramName: "GHH-IND-1" },
        ],
        status: "approved",
      },
      {
        requestId: "CDS52",
        errorMessage: null,
        trait: { traitId: 2, traitName: "referral" },
        ecosystems: [
          { ecosystemId: 4, ecosystemName: "mapp" },
          { ecosystemId: 3, ecosystemName: "dataLake" },
        ],
        marketingPrograms: [
          { marketingProgramNumber: 2, marketingProgramName: "GHH-JPN-1" },
          { marketingProgramNumber: 1, marketingProgramName: "GHH-IND-1" },
        ],
        status: "rejected",
      },
      {
        requestId: "CDS53",
        errorMessage: null,
        trait: { traitId: 18, traitName: "traitUseBrandGroomingIdValue" },
        ecosystems: [
          { ecosystemId: 4, ecosystemName: "mapp" },
          { ecosystemId: 3, ecosystemName: "dataLake" },
        ],
        marketingPrograms: [
          { marketingProgramNumber: 2, marketingProgramName: "GHH-JPN-1" },
          { marketingProgramNumber: 1, marketingProgramName: "GHH-IND-1" },
        ],
        status: "pending",
      },
      {
        requestId: "CDS54",
        errorMessage: null,
        trait: { traitId: 18, traitName: "traitUseBrandGroomingIdValue" },
        ecosystems: [
          { ecosystemId: 4, ecosystemName: "mapp" },
          { ecosystemId: 3, ecosystemName: "dataLake" },
        ],
        marketingPrograms: [
          { marketingProgramNumber: 2, marketingProgramName: "GHH-JPN-1" },
          { marketingProgramNumber: 1, marketingProgramName: "GHH-IND-1" },
        ],
        status: "error",
      },
      {
        requestId: "CDS55",
        errorMessage: null,
        trait: { traitName: "werwerqw" },
        ecosystems: [
          { ecosystemId: 4, ecosystemName: "mapp" },
          { ecosystemId: 3, ecosystemName: "dataLake" },
        ],
        marketingPrograms: [
          { marketingProgramNumber: 2, marketingProgramName: "GHH-JPN-1" },
          { marketingProgramNumber: 1, marketingProgramName: "GHH-IND-1" },
        ],
        status: "approved",
      },
    ],
  },
});

const createSourceRequestApiResponse = () => ({
  apiVersion: "2.1.0",
  data: {
    items: [
      {
        requestId: "CDS227",
        status: "approved",
        errorMessage: null,
        dataSource: {
          sourceName: "IND Golden Household s",
        },
        marketingProgram: {
          marketingProgramNumber: 1,
          marketingProgramName: "GHH-IND-1",
          description: "IND Golden Household",
        },
        output: {
          items: [
            {
              sourceId: 123568,
              sourceName: "IND Golden Household s",
              description: "wer wer wer wer ",
              mappingWarning: null,
              new: true,
            },
            {
              sourceId: 123569,
              sourceName: "IND Golden Household s",
              description: "wer wer wer wer ",
              mappingWarning: "Mapping warning",
              new: true,
            },
            {
              sourceId: 123570,
              sourceName: "IND Golden Household s",
              description: "wer wer wer wer ",
              mappingWarning: null,
              new: false,
            },
            {
              sourceId: 123568,
              sourceName: "IND Golden Household s",
              description: "wer wer wer wer ",
              marketingProgramNumber: 1,
              marketingProgramDescription: "IND Golden Household",
              mappingWarning: null,
              new: true,
            },
          ],
          warnings: [],
        },
      },
    ],
  },
});

const createSegmentSourceRequestApiResponse = () => ({
  apiVersion: "2.2.0",
  data: {
    items: [
      {
        requestId: "CDS263",
        status: "approved",
        errorMessage: null,
        marketingProgram: {
          marketingProgramNumber: 2,
          description: "JPN Golden Household",
        },
        dataSources: [
          {
            sourceId: 1004,
            sourceName: "i4-i10-i60/Other/Multi/Multi/Multi/Multi/Multi",
          },
          {
            sourceId: 1174,
            sourceName: "i60/Other/Multi/Multi/Multi/Multi/Corbus",
          },
        ],
        segmentSource: {
          ecosystemSourceId: 13,
          ecosystemSourceName: "USA Olay COT (1174)",
        },
        output: {
          items: [
            {
              mappingWarning:
                "Marketing Program(2), Data Source(1004) and Ecosystem Source(13) mapping already exists.",
              new: false,
              ecosystemSourceId: 13,
              ecosystemSourceName: "USA Olay COT (1174)",
              marketingProgramNumber: 2,
              marketingProgramDescription: "JPN Golden Household",
              sourceId: 1004,
              sourceName: "i4-i10-i60/Other/Multi/Multi/Multi/Multi/Multi",
            },
            {
              mappingWarning:
                "Marketing Program(2), Data Source(1174) and Ecosystem Source(13) mapping already exists.",
              new: false,
              ecosystemSourceId: 13,
              ecosystemSourceName: "USA Olay COT (1174)",
              marketingProgramNumber: 2,
              marketingProgramDescription: "JPN Golden Household",
              sourceId: 1174,
              sourceName: "i60/Other/Multi/Multi/Multi/Multi/Corbus",
            },
          ],
          warnings: [],
        },
      },
    ],
  },
});

const createOptMappingApiResponse = () => ({
  apiVersion: "2.3.0",
  data: {
    items: [
      {
        requestId: "CDS580",
        status: "approved",
        errorMessage: null,
        marketingProgram: {
          marketingProgramNumber: 1,
          description: "IND Golden Household",
        },
        opt: {
          optId: "1_01",
          optText:
            "By registering, I agree to receive emails from Rewardme and other trusted <a href=http://us.pg.com/our-brands target=_blank>P&G brands</a> and programs. Click to read <a href=http://www.pg.com/en_IN/terms_conditions/index.shtml target=_blank>P&G Terms and Conditions</a> and <a href=http://www.pg.com/privacy/english/privacy_notice.shtml target=_blank>P&G Privacy Policy </a>.",
          marketingProgramNumber: 1,
          marketingProgramDescription: "IND Golden Household",
        },
        service: {
          serviceName: "RewardMe India",
          description: null,
          isNew: false,
          channel: "E-Email Address",
          contactPointCategoryCode: "EP-Personal Email",
          primaryIndicator: "Secondary",
          subscriptionOptNumber: "4",
        },
        trait: {
          optChoiceDate: {
            traitName: "OptChoiceDateTraitName",
          },
          optIndicator: {
            traitName: "OptChoiceIndicatorTraitName",
          },
        },
        output: {
          items: [
            {
              new: true,
              traitId: 4409,
              traitName: "emailJapanGoldenHouseholdOptIndicator",
              mappingWarning: null,
              traitDescription:
                "Opt indicator trait for Email channel for JPN Golden Household",
            },
            {
              new: true,
              traitId: 4410,
              traitName: "emailJapanGoldenHouseholdOptChoiceDate",
              mappingWarning: null,
              traitDescription:
                "Opt choice date trait for Email channel for JPN Golden Household",
            },
            {
              new: true,
              serviceName: "GHH Japan",
              mappingWarning: null,
              traitIdOptIndicator: 4409,
              traitIdOptChoiceDate: 4410,
              traitIdOptIndicatorName: "emailJapanGoldenHouseholdOptIndicator",
              traitIdOptChoiceDateName:
                "emailJapanGoldenHouseholdOptChoiceDate",
            },
          ],
          warnings: [],
        },
        optMappings: [
          {
            id: "1",
            optText:
              "By registering, you will receive an email regarding myrepi and other <a href=http://us.pg.com/our-brands target=_blank> P & G brands and programs </a> I accept. Click the <a href=https://www.pg.com/ja_Clean/terms_conditions/index.shtml target=_blank> P & G Terms of Use * and </a> <a href = http Please see: //www.pg.com/privacy/japanese/privacy_notice.shtml target = _ blank > and privacy policy </a>",
            ciamOptId: "2_01",
            serviceName: "GHH Japan",
            optTextEnglish:
              "By registering, you will receive an email regarding myrepi and other <a href=http://us.pg.com/our-brands target=_blank> P & G brands and programs </a> I accept. Click the <a href=https://www.pg.com/ja_Clean/terms_conditions/index.shtml target=_blank> P & G Terms of Use * and </a> <a href = http Please see: //www.pg.com/privacy/japanese/privacy_notice.shtml target = _ blank > and privacy policy </a>",
            contactPointTypeCode: "E",
            contactPointTypeName: "Email Address",
            subscriptionOptNumber: 1,
          },
        ],
        contactPointType: {
          contactPointTypeCode: "E",
          contactPointTypeName: "Email Address",
        },
      },
    ],
  },
});

const createNewOptApiResponse = () => ({
  apiVersion: "2.6.2",
  data: {
    items: [
      {
        requestId: "CDS827",
        status: "approved",
        errorMessage: null,
        marketingProgram: {
          marketingProgramNumber: 2,
          marketingProgramName: "GHH-JPN-1",
          description: "JPN Golden Household",
          legalEntityNumber: 2,
          legalEntityName: "Japan",
        },
        opt: {
          optTextEnglish: "wer wer wer wer wer wer wer wer wer",
          optTextLocalLanguage: "wer wer wer wer",
        },
        marketingProgramOpts: [
          {
            primaryIndicator: "Secondary",
            marketingProgram: {
              marketingProgramNumber: 16,
              description: "JPN Whisper",
            },
            contactPointType: {
              contactPointTypeCode: "P",
              contactPointTypeName: "Phone Number",
            },
            contactPointCategory: {
              contactPointCategoryCode: "PM",
              description: "Mobile Phone",
            },
            subscriptionOptNumber: 1,
            service: {
              isNew: false,
              serviceName: "JPN Whisper dfwe",
            },
          },
        ],
        output: {
          items: [
            {
              optMasterId: 3166,
              ciamOptId: "2_20",
              mappingWarning: null,
              new: true,
              marketingProgramDescription: "JPN Golden Household",
            },
            {
              mappingWarning: null,
              new: true,
              ciamOptId: "2_20",
              optTextEnglish: "wer wer wer wer wer wer wer wer wer",
              contactPointCategoryCode: "PM",
              contactPointCategoryDescription: "Mobile Phone",
              contactPointTypeCode: "P",
              contactPointTypeName: "Phone Number",
              cdpMarketingProgramNumber: 16,
              marketingProgramDescription: "JPN Whisper",
              serviceName: "JPN Whisper dfwe",
            },
            {
              mappingWarning: null,
              new: true,
              optTextEnglish: "wer wer wer wer wer wer wer wer wer",
              contactPointCategoryCode: "PM",
              contactPointCategoryDescription: "Mobile Phone",
              contactPointTypeCode: "P",
              contactPointTypeName: "Phone Number",
              cdpMarketingProgramNumber: 16,
              marketingProgramDescription: "JPN Whisper",
              serviceName: "JPN Whisper dfwe",
            },
            {
              mappingWarning: "Warning 1",
              new: true,
              ciamOptId: "2_20",
              optTextEnglish: "wer wer wer wer wer wer wer wer wer",
              contactPointCategoryCode: "PM",
              contactPointCategoryDescription: "Mobile Phone",
              contactPointTypeCode: "P",
              contactPointTypeName: "Phone Number",
              cdpMarketingProgramNumber: 16,
              marketingProgramDescription: "JPN Whisper",
              serviceName: "JPN Whisper dfwe",
            },
          ],
          warnings: [
            {
              optMasterId: 3166,
              ciamOptId: "2_20",
              mappingWarning: null,
              new: true,
              cdpMarketingProgramNumber: 16,
              marketingProgramDescription: "JPN Golden Household",
            },
          ],
        },
      },
    ],
  },
});

const createOptTraitsApiResponse = () => ({
  apiVersion: "2.3.0",
  data: {
    items: [
      {
        requestId: "CDS580",
        status: "approved",
        errorMessage: null,
        marketingProgram: {
          marketingProgramNumber: 1,
          description: "IND Golden Household",
        },
        opt: {
          optId: "1_01",
          optText:
            "By registering, I agree to receive emails from Rewardme and other trusted <a href=http://us.pg.com/our-brands target=_blank>P&G brands</a> and programs. Click to read <a href=http://www.pg.com/en_IN/terms_conditions/index.shtml target=_blank>P&G Terms and Conditions</a> and <a href=http://www.pg.com/privacy/english/privacy_notice.shtml target=_blank>P&G Privacy Policy </a>.",
          marketingProgramNumber: 1,
          marketingProgramDescription: "IND Golden Household",
        },
        service: {
          serviceName: "RewardMe India",
          description: null,
          isNew: false,
          channel: "E-Email Address",
          contactPointCategoryCode: "EP-Personal Email",
          primaryIndicator: "Secondary",
          subscriptionOptNumber: "4",
        },
        trait: {
          optChoiceDate: {
            traitName: "OptChoiceDateTraitName",
          },
          optIndicator: {
            traitName: "OptChoiceIndicatorTraitName",
          },
        },
        output: {
          items: [
            {
              new: true,
              traitId: 4409,
              traitName: "emailJapanGoldenHouseholdOptIndicator",
              mappingWarning: "mapping warning",
              serviceName: "Service Name",
              traitDescription:
                "Opt indicator trait for Email channel for JPN Golden Household",
            },
            {
              new: true,
              traitId: 4410,
              traitName: "emailJapanGoldenHouseholdOptChoiceDate",
              mappingWarning: null,
              traitDescription:
                "Opt choice date trait for Email channel for JPN Golden Household",
            },
            {
              new: true,
              serviceName: "GHH Japan",
              mappingWarning: null,
              traitIdOptIndicator: 4409,
              traitIdOptChoiceDate: 4410,
              traitIdOptIndicatorName: "emailJapanGoldenHouseholdOptIndicator",
              traitIdOptChoiceDateName:
                "emailJapanGoldenHouseholdOptChoiceDate",
            },
          ],
          warnings: [
            {
              new: true,
              serviceName: "GHH Japan",
              mappingWarning: "Mapping Warning",
              traitIdOptIndicator: 4409,
              traitIdOptChoiceDate: 4410,
              traitIdOptIndicatorName: "emailJapanGoldenHouseholdOptIndicator",
              traitIdOptChoiceDateName:
                "emailJapanGoldenHouseholdOptChoiceDate",
            },
          ],
        },
        optMappings: [
          {
            id: "1",
            optText:
              "By registering, you will receive an email regarding myrepi and other <a href=http://us.pg.com/our-brands target=_blank> P & G brands and programs </a> I accept. Click the <a href=https://www.pg.com/ja_Clean/terms_conditions/index.shtml target=_blank> P & G Terms of Use * and </a> <a href = http Please see: //www.pg.com/privacy/japanese/privacy_notice.shtml target = _ blank > and privacy policy </a>",
            ciamOptId: "2_01",
            serviceName: "GHH Japan",
            optTextEnglish:
              "By registering, you will receive an email regarding myrepi and other <a href=http://us.pg.com/our-brands target=_blank> P & G brands and programs </a> I accept. Click the <a href=https://www.pg.com/ja_Clean/terms_conditions/index.shtml target=_blank> P & G Terms of Use * and </a> <a href = http Please see: //www.pg.com/privacy/japanese/privacy_notice.shtml target = _ blank > and privacy policy </a>",
            contactPointTypeCode: "E",
            contactPointTypeName: "Email Address",
            subscriptionOptNumber: 1,
          },
        ],
        contactPointType: {
          contactPointTypeCode: "E",
          contactPointTypeName: "Email Address",
        },
      },
    ],
  },
});

const createMarketingProgramsApiResponse = () => ({
  apiVersion: "2.3.0",
  data: [
    {
      requestId: "CDS576",
      status: "approved",
      errorMessage: null,
      marketingProgram: {
        brandProgramName: "programName",
        brandProgramShortName: "programShortName",
        marketingProgramName: "programShortName-IND-1",
        description: "IND testing",
        mpBusinessName: "programShortName1-IND-1",
      },
      legalEntity: {
        legalEntityName: "India",
        legalEntityNumber: 1,
        legalEntityShortName: "IND",
      },
      ecosystems: [
        {
          ecosystemId: 1,
          ecosystemName: "segment",
        },
      ],
      contactPointTypes: [
        {
          contactPointTypeCode: "A",
          contactPointTypeName: "Postal Address",
        },
      ],
      output: {
        items: [
          {
            marketingProgramNumber: 60530,
            marketingProgramName: "programShortName-IND-1",
            description: "IND testing",
            mappingWarning: null,
            new: true,
            countryCode: "IND",
          },
          {
            traitId: 4491,
            traitName: "postalIndiaprogramNameOptIndicator",
            traitDescription:
              "Opt indicator trait for Postal Address channel for IND testing",
            mappingWarning: null,
            new: true,
          },
          {
            traitId: 4492,
            traitName: "postalIndiaprogramNameOptChoiceDate",
            traitDescription:
              "Opt choice date trait for Postal Address channel for IND testing",
            mappingWarning: null,
            new: true,
          },
          {
            marketingProgramNumber: 60530,
            serviceName: "IND testing Postal",
            mappingWarning: null,
            new: true,
          },
          {
            pgDataSourceId: 1174,
            sourceName: "i60/Other/Multi/Multi/Multi/Multi/Corbus",
            marketingProgramNumber: 60530,
            mappingWarning: null,
            new: true,
          },
          {
            ecosystemSourceId: 1968,
            ecosystemSourceName: "IND testing Corbus (1174)",
            ecosystemId: 1,
            ecosystemName: "segment",
            mappingWarning: null,
            new: true,
          },
          {
            pgDataSourceId: 13463,
            sourceName: "Consumer Privacy Center",
            marketingProgramNumber: 60530,
            mappingWarning: null,
            new: true,
          },
          {
            ecosystemSourceId: 1969,
            ecosystemSourceName: "IND testing CPC (13463)",
            ecosystemId: 1,
            ecosystemName: "segment",
            mappingWarning: null,
            new: true,
          },
          {
            pgDataSourceId: 14324,
            sourceName: "Data Remediation Source",
            marketingProgramNumber: 60530,
            mappingWarning: null,
            new: true,
          },
          {
            ecosystemSourceId: 1970,
            ecosystemSourceName: "IND testing Data Remediation (14324)",
            ecosystemId: 1,
            ecosystemName: "segment",
            mappingWarning: null,
            new: true,
          },
          {
            pgDataSourceId: 12230,
            sourceName: "Retention Transmitter Source",
            marketingProgramNumber: 60530,
            mappingWarning: null,
            new: true,
          },
          {
            ecosystemSourceId: 1971,
            ecosystemSourceName: "IND testing Retention Rules (12230)",
            ecosystemId: 1,
            ecosystemName: "segment",
            mappingWarning: null,
            new: true,
          },
        ],
        warnings: [],
      },
    },
  ],
});

const createMarketingProgramsEcosystemsApiResponse = () => ({
  apiVersion: "2.11.0",
  data: [
    {
      requestId: "CDS652",
      status: "approved",
      errorMessage: null,
      marketingProgram: {
        marketingProgramNumber: 10,
        description: "NZL Olay",
      },
      ecosystems: [
        {
          ecosystemId: 1,
          ecosystemName: "segment",
          automationEnabled: true,
          ecosystemMarketingProgramKey: "spa_qweqwe234",
          ecosystemMarketingProgramName: "IND testing",
          ecosystemMarketingProgramInstance: "pg-global-dev",
        },
        {
          ecosystemId: 4,
          ecosystemName: "mapp",
          automationEnabled: true,
          ecosystemMarketingProgramKey: "90909090909",
          ecosystemMarketingProgramName: "jlkkj",
          ecosystemMarketingProgramInstance: "pg_12_12",
        },
        {
          ecosystemId: 6,
          ecosystemName: "janrain",
          automationEnabled: true,
          ecosystemMarketingProgramKey: "sdasdasd",
          ecosystemMarketingProgramName: "asd_asd_12",
          ecosystemMarketingProgramInstance: "eu",
        },
      ],
      output: {
        items: [
          {
            ecosystemId: 1,
            ecosystemName: "segment",
            automationEnabled: true,
            ecosystemMarketingProgramKey: "spa_updated",
            ecosystemMarketingProgramName: "IND updated",
            ecosystemMarketingProgramInstance: "pg-global-dev",
            mappingWarning: null,
            new: true,
          },
          {
            ecosystemId: 4,
            ecosystemName: "mapp",
            automationEnabled: true,
            ecosystemMarketingProgramKey: "90909090909",
            ecosystemMarketingProgramName: "jlkkj",
            ecosystemMarketingProgramInstance: "pg_12_12",
            mappingWarning:
              "Ecosystem details already exists for this ecosystem. Automation will be enabled.",
            new: false,
          },
          {
            ecosystemId: 6,
            ecosystemName: "janrain",
            automationEnabled: true,
            ecosystemMarketingProgramKey: "asdasd",
            ecosystemMarketingProgramName: "qasd_asd_123",
            ecosystemMarketingProgramInstance: "eu",
            mappingWarning: null,
            new: true,
          },
        ],
        warnings: [
          {
            ecosystemId: 4,
            ecosystemName: "mapp",
            automationEnabled: true,
            ecosystemMarketingProgramKey: "90909090909",
            ecosystemMarketingProgramName: "jlkkj",
            ecosystemMarketingProgramInstance: "pg_12_12",
            new: false,
            message: "warning-message",
          },
        ],
      },
    },
  ],
});

const createUseCaseApiResponse = () => ({
  apiVersion: "1.0.0",
  data: [
    {
      requestId: 786,
      status: "approved",
      errorMessage: null,
      dataUseCase: {
        useCaseName: "werwer",
        useCaseDescription: "wer wer wer wer ",
      },
      marketingProgram: {
        marketingProgramNumber: 2,
        marketingProgramName: "GHH-JPN-1",
        description: "JPN Golden Household",
      },
      traits: [
        {
          traitId: 18,
          traitName: "traitUseBrandGroomingIdValue",
        },
        {
          traitId: 1,
          traitName: "childrenIdValue",
        },
      ],
      output: {
        items: [
          {
            useCaseName: "werwer",
            useCaseDescription: "wer wer wer wer ",
            useCaseId: 22,
            mappingWarning: null,
            new: true,
          },
          {
            marketingProgramTraitUseCaseId: 248,
            description: "JPN Golden Household",
            marketingProgramName: "GHH-JPN-1",
            marketingProgramNumber: 2,
            traitId: 18,
            traitName: "traitUseBrandGroomingIdValue",
            useCaseId: 22,
            mappingWarning: "Warning 1",
            new: true,
          },
          {
            marketingProgramTraitUseCaseId: 248,
            description: "JPN Golden Household",
            marketingProgramName: "GHH-JPN-1",
            marketingProgramNumber: 2,
            traitId: 18,
            traitName: "traitUseBrandGroomingIdValue",
            useCaseId: 22,
            mappingWarning: null,
            new: true,
          },
          {
            marketingProgramTraitUseCaseId: 249,
            description: "JPN Golden Household",
            marketingProgramName: "GHH-JPN-1",
            marketingProgramNumber: 2,
            traitId: 1,
            deleted: true,
            traitName: "childrenIdValue",
            useCaseId: 22,
            mappingWarning: null,
          },
          {
            marketingProgramTraitUseCaseId: 248,
            description: "JPN Golden Household",
            marketingProgramName: "GHH-JPN-1",
            marketingProgramNumber: 2,
            traitId: 18,
            traitName: "traitUseBrandGroomingIdValue",
            useCaseId: 22,
            mappingWarning: null,
            new: false,
          },
        ],
        warnings: [
          {
            useCaseName: "werwer",
            useCaseDescription: "wer wer wer wer ",
            useCaseId: 22,
            traitId: 18,
            mappingWarning: null,
            message: "Warning Message",
            new: true,
          },
        ],
      },
    },
  ],
});

const getEcosystemsApiResponse = () => ({
  apiVersion: "0.1.0",
  data: {
    totalItems: 3,
    items: [
      {
        ecosystemId: 1,
        ecosystemName: "segment",
        description: "segment",
        ecoSystemTraitCharacterLimit: "10",
        aliasName: "seg",
        personalDataAllowed: true,
        ecoSystemTraitPrefix: "trait",
        ecoSystemTraitSuffix: "IdValue",
        ecoSystemTraitDelimiter: null,
      },
      {
        ecosystemId: 2,
        ecosystemName: "lytics",
        description: "lytics",
        aliasName: "lyt",
        personalDataAllowed: false,
        ecoSystemTraitPrefix: "trait_",
        ecoSystemTraitSuffix: "_id_value",
        ecoSystemTraitDelimiter: "_",
      },
      {
        ecosystemId: 3,
        ecosystemName: "dataLake",
        description: "data Lake",
        aliasName: "dl",
        personalDataAllowed: false,
        ecoSystemTraitPrefix: "trait",
        ecoSystemTraitSuffix: "IdValue",
        ecoSystemTraitDelimiter: null,
      },
      {
        aliasName: "mapp",
        description: "mapp",
        ecoSystemTraitCharacterLimit: "25",
        ecoSystemTraitDelimiter: null,
        ecoSystemTraitPrefix: "trait",
        ecoSystemTraitSuffix: "IdValue",
        ecosystemId: 4,
        ecosystemName: "mapp",
        personalDataAllowed: true,
      },
      {
        aliasName: "bq",
        description: "bigQuery",
        ecoSystemTraitCharacterLimit: null,
        ecoSystemTraitDelimiter: "_",
        ecoSystemTraitPrefix: "trait_",
        ecoSystemTraitSuffix: "_id_value",
        ecosystemId: 5,
        ecosystemName: "bigQuery",
        personalDataAllowed: true,
      },
      {
        aliasName: "jan",
        description: "janrain",
        ecoSystemTraitCharacterLimit: null,
        ecoSystemTraitDelimiter: null,
        ecoSystemTraitPrefix: "trait",
        ecoSystemTraitSuffix: ".traitValue",
        ecosystemId: 6,
        ecosystemName: "janrain",
        personalDataAllowed: true,
      },
      {
        aliasName: "1cp",
        description: "P&G 1CP",
        ecoSystemTraitCharacterLimit: null,
        ecoSystemTraitDelimiter: null,
        ecoSystemTraitPrefix: null,
        ecoSystemTraitSuffix: null,
        ecosystemId: 7,
        ecosystemName: "1CP",
        personalDataAllowed: true,
      },
    ],
  },
});

const getEventsRequestsApiResponse = () => ({
  apiVersion: "1.8.3",
  data: {
    items: [
      {
        requestId: 954,
        name: "Add to Subscription Plan",
        requestDate: "2022-08-16T12:05:25.000Z",
        status: "pending",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 929,
        name: "Add to Subscription Plan",
        requestDate: "2022-08-12T12:20:36.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 928,
        name: "Add to Subscription Plan",
        requestDate: "2022-08-12T12:02:50.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 927,
        name: "Add to Subscription Plan",
        requestDate: "2022-08-12T12:01:43.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 926,
        name: "Add to Subscription Plan",
        requestDate: "2022-08-06T15:13:33.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA", "APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 923,
        name: "Add to Subscription Plan",
        requestDate: "2022-08-03T11:06:31.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 922,
        name: "Add to Subscription Plan",
        requestDate: "2022-08-03T09:34:30.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 920,
        name: "Add to Subscription Plan",
        requestDate: "2022-08-03T07:27:24.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 919,
        name: "Order Cancelled",
        requestDate: "2022-08-03T03:57:32.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 911,
        name: "Add to Subscription Plan",
        requestDate: "2022-08-02T05:13:48.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 907,
        name: "Buy-it-now exit to retailer",
        requestDate: "2022-07-29T07:29:28.000Z",
        status: "rejected",
        requestedBy: "developer.approve",
        reviewedBy: "maroju.k",
        comments: "REJECTION REASON: reject test",
        geoRegionCodes: ["AMA", "GC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 906,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-29T07:25:46.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["GC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 905,
        name: "Buy-it-now click-thru",
        requestDate: "2022-07-29T07:22:35.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 904,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-29T07:21:36.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 901,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-29T05:55:36.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 900,
        name: "Banner Click",
        requestDate: "2022-07-29T05:43:28.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA", "APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 899,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-29T05:40:54.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 897,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-29T03:50:01.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA", "APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 896,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-29T03:40:07.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA", "APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 894,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-28T16:10:27.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "REJECTION REASON: Auto Approved",
        geoRegionCodes: ["AMA", "APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 893,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-28T16:08:40.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "REJECTION REASON: Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 892,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-28T15:48:40.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "REJECTION REASON: Auto Approved",
        geoRegionCodes: ["AMA", "APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 891,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-28T15:46:43.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "REJECTION REASON: Auto Approved",
        geoRegionCodes: ["AMA", "APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 890,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-28T11:07:17.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "REJECTION REASON: Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 889,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-28T11:05:30.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "REJECTION REASON: Auto Approved",
        geoRegionCodes: ["AMA", "APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 888,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-28T10:45:47.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "REJECTION REASON: Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 886,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-27T13:45:03.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "REJECTION REASON: Auto Approved",
        geoRegionCodes: ["AMA", "APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 884,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-27T13:26:38.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "REJECTION REASON: Auto Approved",
        geoRegionCodes: ["AMA", "APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
      {
        requestId: 883,
        name: "Add to Subscription Plan",
        requestDate: "2022-07-27T05:25:13.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "REJECTION REASON: Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "events",
        isNew: false,
      },
    ],
    totalItems: 29,
    page: "1",
    itemsPerPage: "50",
  },
});

const getTraitsRequestsApiResponse = () => ({
  apiVersion: "0.1.0",
  data: {
    totalItems: 50,
    page: 1,
    itemsPerPage: 50,
    items: [
      {
        requestId: "CDS1",
        requestDate: "2021-07-15T04:02:50.000Z",
        status: "pending",
        requestedBy: "anandan.a.1",
        reviewedBy: "anandan.a.1",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: 0,
        module: "traits",
        name: "childrenIdValue",
      },
      {
        requestId: "CDS2",
        requestDate: "2021-07-15T04:02:50.000Z",
        status: "approved",
        requestedBy: "anandan.a.1",
        reviewedBy: "anandan.a.1",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: 0,
        module: "traits",
        name: "childrenIdValue2",
      },
      {
        requestId: "CDS3",
        requestDate: "2021-07-15T04:02:50.000Z",
        status: "approved",
        requestedBy: "anandan.a.1",
        reviewedBy: "anandan.a.1",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: 0,
        module: "traits",
        name: "childrenIdValue3",
      },
      {
        requestId: "CDS4",
        requestDate: "2021-07-17T05:10:54.000Z",
        status: "rejected",
        requestedBy: "anbu.sk",
        reviewedBy: "anbu.sk",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA", "APAC", "LA"],
        isRevised: 0,
        module: "traits",
        name: "childrenIdValue4",
      },
      {
        requestId: "CDS5",
        requestDate: "2021-07-17T05:10:54.000Z",
        status: "returned",
        requestedBy: "anbu.sk",
        reviewedBy: "anbu.sk",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA", "APAC", "LA"],
        isRevised: 0,
        module: "traits",
        name: "childrenIdValue5",
      },
      {
        requestId: "CDS6",
        requestDate: "2021-07-17T05:10:54.000Z",
        status: "unknown",
        requestedBy: "anbu.sk",
        reviewedBy: "anbu.sk",
        comments: "Unknown status",
        geoRegionCodes: ["AMA", "APAC", "LA"],
        isRevised: 0,
        module: "traits",
        name: "childrenIdValue6",
      },
    ],
  },
});

const getEventsRequestDetailsApiResponse = (status) => ({
  apiVersion: "1.8.3",
  data: {
    requestId: 954,
    name: "Add to Subscription Plan",
    requestDate: "2022-08-16T12:05:25.000Z",
    status: status || "approved",
    requestedBy: "developer.approve",
    reviewedBy: "developer.approve",
    comments: "Auto Approved",
    output: {
      items: [
        {
          new: false,
          eventName: "Add to Subscription Plan",
          ecosystemId: 2,
          ecosystemName: "lytics",
          eventDataType: "",
          eventProperty: "productId",
          eventValueType: "LATEST",
          mappingWarning:
            "Event Property Mapping productId  LATEST already exists",
          eventSubProperty: "",
          marketingProgramNumber: 2,
          marketingProgramDescription: "JPN Golden Household",
        },
      ],
      warnings: [],
    },
    geoRegionCodes: ["APAC"],
    requestDetails: {
      eventName: "Add to Subscription Plan",
      ecosystems: [{ ecosystemId: 2, ecosystemName: "lytics" }],
      eventProperties: [
        {
          dataType: "",
          valueType: ["LATEST"],
          description: "product ID",
          propertyName: "productId",
        },
      ],
      marketingPrograms: [
        { description: "JPN Golden Household", marketingProgramNumber: 2 },
      ],
    },
    originalRequestDetails: {
      eventName: "Add to Subscription Plan",
      ecosystems: [{ ecosystemId: 2, ecosystemName: "lytics" }],
      eventProperties: [
        {
          dataType: "",
          valueType: ["LATEST", "ALL"],
          description: "product ID",
          propertyName: "productId",
        },
      ],
      marketingPrograms: [
        { description: "JPN Golden Household", marketingProgramNumber: 2 },
      ],
    },
  },
});

const getTraitRequestDetailsApiResponse = () => ({
  apiVersion: "1.0.0",
  data: {
    requestId: "CDS2",
    name: "wer",
    requestDate: "2021-08-25T11:00:17.000Z",
    status: "approved",
    requestedBy: "developer.approve",
    reviewedBy: "developer.approve",
    comments: "Auto Approved",
    output: {
      items: [
        {
          new: true,
          traitId: 4274,
          traitName: "wer",
          mappingWarning: null,
          traitDescription: "werwer wer wer we",
        },
        {
          new: true,
          traitId: 4274,
          traitName: "wer",
          traitDescription: "werwer wer wer we",
          marketingProgramName: "GHH-JPN-1",
          marketingProgramNumber: 2,
        },
        {
          new: true,
          traitId: 4274,
          traitName: "wer",
          ecosystemId: 4,
          ecosystemName: "mapp",
          mappingWarning: null,
          traitDescription: "werwer wer wer we",
          ecosystemTraitName: "traitWerIdValue",
          marketingProgramName: "GHH-JPN-1",
          marketingProgramNumber: 2,
        },
      ],
      warnings: [],
    },
    geoRegionCodes: ["APAC"],
    module: "traits",
    requestDetails: {
      trait: {
        dataType: "string",
        dataClass: "Persona Traits",
        formInput: "Number field",
        traitName: "wer",
        traitType: "Custom Trait",
        description: "werwer wer wer we",
        personalData: false,
        exampleValues: "wer wer wer wer",
        ecosystemTraits: [
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 1,
            ecosystemName: "segment",
            ecosystemTraitName: "traitWerIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 2,
            ecosystemName: "lytics",
            ecosystemTraitName: "traitwer_id_value",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "Wer",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 3,
            ecosystemName: "dataLake",
            ecosystemTraitName: "traitWerIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 4,
            ecosystemName: "mapp",
            ecosystemTraitName: "traitWerIdValue",
            ecosystemTraitType: "Member",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 5,
            ecosystemName: "bigQuery",
            ecosystemTraitName: "traitwer_id_value",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 6,
            ecosystemName: "janrain",
            ecosystemTraitName: "traitWer.traitValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 7,
            ecosystemName: "1CP",
            ecosystemTraitName: "wer",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 8,
            ecosystemName: "Braze",
            ecosystemTraitName: "traitWerIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
        ],
        uDataChangeFrequency: null,
        sensitivePersonalData: false,
      },
      ecosystems: [
        {
          ecosystemId: 4,
          ecosystemName: "mapp",
        },
        {
          ecosystemId: 3,
          ecosystemName: "dataLake",
        },
      ],
      marketingPrograms: [
        {
          marketingProgramName: "GHH-JPN-1",
          marketingProgramNumber: 2,
        },
        {
          marketingProgramName: "Olay-KOR-1",
          marketingProgramNumber: 3,
        },
      ],
    },
    originalRequestDetails: {
      trait: {
        dataType: "string",
        dataClass: "Persona Traits",
        formInput: "Number field",
        traitName: "wer123",
        traitType: "Custom Trait",
        description: "werwer wer wer we",
        personalData: false,
        exampleValues: "wer wer wer wer",
        ecosystemTraits: [
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 1,
            ecosystemName: "segment",
            ecosystemTraitName: "traitWerIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 2,
            ecosystemName: "lytics",
            ecosystemTraitName: "traitwer_id_value",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "Wer",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 3,
            ecosystemName: "dataLake",
            ecosystemTraitName: "traitWerIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 4,
            ecosystemName: "mapp",
            ecosystemTraitName: "traitWerIdValue",
            ecosystemTraitType: "Member",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 5,
            ecosystemName: "bigQuery",
            ecosystemTraitName: "traitwer_id_value",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 6,
            ecosystemName: "janrain",
            ecosystemTraitName: "traitWer.traitValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 7,
            ecosystemName: "1CP",
            ecosystemTraitName: "wer",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 8,
            ecosystemName: "Braze",
            ecosystemTraitName: "traitWerIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
        ],
        uDataChangeFrequency: null,
        sensitivePersonalData: false,
      },
      ecosystems: [
        {
          ecosystemId: 4,
          ecosystemName: "mapp1",
        },
        {
          ecosystemId: 3,
          ecosystemName: "dataLake2",
        },
      ],
      marketingPrograms: [
        {
          marketingProgramName: "GHH-JPN-111",
          marketingProgramNumber: 2,
        },
        {
          marketingProgramName: "Olay-KOR-111",
          marketingProgramNumber: 3,
        },
      ],
    },
  },
});

const getSourcesRequestsApiResponse = () => ({
  apiVersion: "0.1.0",
  data: {
    totalItems: 50,
    page: 1,
    itemsPerPage: 50,
    items: [
      {
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        0: "APAC",
        isRevised: false,
        module: "data-sources",
        name: "JPN Golden Household werwer",
        requestDate: "2021-10-04T06:34:14.000Z",
        requestId: "CDS290",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        status: "approved",
      },
      {
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "data-sources",
        name: "JPN Golden Household Bottle",
        requestDate: "2021-10-01T11:32:22.000Z",
        requestId: "CDS289",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        status: "approved",
      },
      {
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: true,
        module: "data-sources",
        name: "JPN Golden Household Bottle source 1",
        requestDate: "2021-10-01T11:32:22.000Z",
        requestId: "CDS289",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        status: "returned",
      },
      {
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: true,
        module: "data-sources",
        name: "JPN Golden Household Bottle source 2",
        requestDate: "2021-10-01T11:32:22.000Z",
        requestId: "CDS289",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        status: "rejected",
      },
      {
        comments: "Pending",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "data-sources",
        name: "JPN Golden Household Bottle 123",
        requestDate: "2021-10-01T11:32:22.000Z",
        requestId: "CDS289",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        status: "pending",
      },
      {
        comments: "Unkown status",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "data-sources",
        name: "JPN Golden Household Bottle 321",
        requestDate: "2021-10-01T11:32:22.000Z",
        requestId: "CDS289",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        status: "unkown",
      },
    ],
  },
});

const getOptsRequestsResponse = {
  apiVersion: "1.7.2",
  data: {
    totalItems: 10,
    page: 1,
    itemsPerPage: 50,
    items: [
      {
        requestId: "CDS828",
        name: "IND Corporate Program - New Opt ID",
        requestDate: "2022-06-19T18:22:50.000Z",
        status: "pending",
        requestedBy: "developer.write",
        reviewedBy: "developer.approve",
        comments: "Checking return",
        geoRegionCodes: ["AMA"],
        isRevised: true,
        module: "opts",
        isNew: true,
      },
      {
        requestId: "CDS627",
        name: "IND Being Girl werwe",
        requestDate: "2022-03-23T10:31:35.000Z",
        status: "pending",
        requestedBy: "developer.write",
        reviewedBy: "developer.approve",
        comments: null,
        geoRegionCodes: ["AMA"],
        isRevised: true,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS618",
        name: "postalIndiaCorporateProgram2OptIndicator",
        requestDate: "2022-03-03T08:14:13.000Z",
        status: "pending",
        requestedBy: "developer.write",
        reviewedBy: "developer.approve",
        comments: null,
        geoRegionCodes: ["AMA"],
        isRevised: true,
        module: "opts",
        isNew: true,
      },
      {
        requestId: "CDS615",
        name: "werwerpostalIndiaGoldenHouseholdOptIndicator",
        requestDate: "2022-03-03T07:37:52.000Z",
        status: "pending",
        requestedBy: "developer.write",
        reviewedBy: "developer.approve",
        comments: "Return Request",
        geoRegionCodes: ["AMA"],
        isRevised: true,
        module: "opts",
        isNew: true,
      },
      {
        requestId: "CDS614",
        name: "phoneIndiaGoldenHouseholdOptIndicator",
        requestDate: "2022-03-03T07:34:43.000Z",
        status: "pending",
        requestedBy: "developer.write",
        reviewedBy: null,
        comments: null,
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: true,
      },
      {
        requestId: "CDS613",
        name: "IND Being Girl werwer",
        requestDate: "2022-03-03T07:32:02.000Z",
        status: "pending",
        requestedBy: "developer.write",
        reviewedBy: "anbu.sk",
        comments: "update",
        geoRegionCodes: ["AMA"],
        isRevised: true,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS605",
        name: "phoneIndiaCorporateProgramOptIndicator",
        requestDate: "2022-03-02T17:28:02.000Z",
        status: "pending",
        requestedBy: "developer.write",
        reviewedBy: null,
        comments: null,
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: true,
      },
      {
        requestId: "CDS601",
        name: "emailIndiasGoldenHouseholdOptIndicator",
        requestDate: "2022-03-02T17:25:12.000Z",
        status: "pending",
        requestedBy: "developer.write",
        reviewedBy: null,
        comments: null,
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: true,
      },
      {
        requestId: "CDS600",
        name: "emailIndiaGoldensHouseholdOptIndicator",
        requestDate: "2022-03-02T17:24:10.000Z",
        status: "pending",
        requestedBy: "developer.write",
        reviewedBy: null,
        comments: null,
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: true,
      },
      {
        requestId: "CDS598",
        name: "IND Olay werwerwerwerwer",
        requestDate: "2022-03-02T17:22:54.000Z",
        status: "pending",
        requestedBy: "developer.write",
        reviewedBy: "developer.approve",
        comments: null,
        geoRegionCodes: ["AMA"],
        isRevised: true,
        module: "opts",
        isNew: true,
      },
    ],
  },
};

const getOptsRequestsApiResponse = () => ({
  apiVersion: "0.1.0",
  data: {
    totalItems: 50,
    page: 1,
    itemsPerPage: 50,
    items: [
      {
        requestId: "CDS480",
        name: "RewardMe India",
        requestDate: "2021-12-07T07:54:08.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS479",
        name: "USA VocalPoint wew",
        requestDate: "2021-12-07T07:48:19.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["NA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS478",
        name: "IND Olay wer",
        requestDate: "2021-12-06T12:39:08.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS477",
        name: "IND Being Girl wer",
        requestDate: "2021-12-06T12:36:40.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS476",
        name: "IND Olay werwer",
        requestDate: "2021-12-06T12:34:03.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS475",
        name: "IND Olay wer",
        requestDate: "2021-12-06T11:23:24.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS474",
        name: "IND Olay werwer",
        requestDate: "2021-12-06T09:16:41.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS473",
        name: "IND Olay wer",
        requestDate: "2021-12-06T08:50:20.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS472",
        name: "RewardMe India",
        requestDate: "2021-12-06T06:22:27.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS471",
        name: "IND Being Girl wer",
        requestDate: "2021-12-06T06:21:38.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS480",
        name: "IND Olay 234",
        requestDate: "2021-12-06T06:07:33.000Z",
        status: "pending",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS469",
        name: "IND Olay wer",
        requestDate: "2021-12-06T06:01:07.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS468",
        name: "IND Olay wer",
        requestDate: "2021-12-06T05:47:58.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS466",
        name: "IND Olay 234",
        requestDate: "2021-12-06T04:44:59.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
      {
        requestId: "CDS465",
        name: "Testing New Service",
        requestDate: "2021-12-03T10:13:20.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["NA"],
        isRevised: false,
        module: "opts",
        isNew: false,
      },
    ],
  },
});

const getOptsTraitsRequestsApiResponse = () => ({
  apiVersion: "1.2.1",
  data: {
    totalItems: 51,
    page: 1,
    itemsPerPage: 50,
    items: [
      {
        requestId: "CDS526",
        name: "emailJapanGoldenHouseholdOptIndicator",
        requestDate: "2021-12-17T09:20:14.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["APAC"],
        isRevised: false,
        module: "opts",
        isNew: true,
      },
      {
        requestId: "CDS525",
        name: "postalIndiaGoldenHouseholdertertertOptIndicator",
        requestDate: "2021-12-17T08:25:55.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "opts",
        isNew: true,
      },
    ],
  },
});

const getOptsTraitRequestDetailsApiResponse = (status) => ({
  apiVersion: "1.2.1",
  data: {
    requestId: "CDS526",
    name: "emailJapanGoldenHouseholdOptIndicator",
    requestDate: "2021-12-17T09:20:14.000Z",
    status: status || "approved",
    requestedBy: "developer.approve",
    reviewedBy: "developer.approve",
    comments: "Auto Approved",
    output: {
      items: [
        {
          new: true,
          traitId: 4409,
          traitName: "emailJapanGoldenHouseholdOptIndicator",
          mappingWarning: null,
          traitDescription:
            "Opt indicator trait for Email channel for JPN Golden Household",
        },
        {
          new: true,
          traitId: 4410,
          traitName: "emailJapanGoldenHouseholdOptChoiceDate",
          mappingWarning: null,
          traitDescription:
            "Opt choice date trait for Email channel for JPN Golden Household",
        },
        {
          new: true,
          serviceName: "GHH Japan",
          mappingWarning: null,
          traitIdOptIndicator: 4409,
          traitIdOptChoiceDate: 4410,
          traitIdOptIndicatorName: "emailJapanGoldenHouseholdOptIndicator",
          traitIdOptChoiceDateName: "emailJapanGoldenHouseholdOptChoiceDate",
        },
      ],
      warnings: [],
    },
    geoRegionCodes: ["APAC"],
    module: "opts",
    requestDetails: {
      trait: {
        optIndicator: {
          traitName: "emailJapanGoldenHouseholdOptIndicator",
        },
        optChoiceDate: {
          traitName: "emailJapanGoldenHouseholdOptChoiceDate",
        },
      },
      ecosystems: [
        {
          ecosystemId: 5,
          ecosystemName: "bigQuery",
        },
        {
          ecosystemId: 4,
          ecosystemName: "mapp",
        },
      ],
      optMappings: [
        {
          id: "1",
          optText:
            "By registering, you will receive an email regarding myrepi and other <a href=http://us.pg.com/our-brands target=_blank> P & G brands and programs </a> I accept. Click the <a href=https://www.pg.com/ja_Clean/terms_conditions/index.shtml target=_blank> P & G Terms of Use * and </a> <a href = http Please see: //www.pg.com/privacy/japanese/privacy_notice.shtml target = _ blank > and privacy policy </a>",
          ciamOptId: "2_01",
          serviceName: "GHH Japan",
          optTextEnglish:
            "By registering, you will receive an email regarding myrepi and other <a href=http://us.pg.com/our-brands target=_blank> P & G brands and programs </a> I accept. Click the <a href=https://www.pg.com/ja_Clean/terms_conditions/index.shtml target=_blank> P & G Terms of Use * and </a> <a href = http Please see: //www.pg.com/privacy/japanese/privacy_notice.shtml target = _ blank > and privacy policy </a>",
          contactPointTypeCode: "E",
          contactPointTypeName: "Email Address",
          subscriptionOptNumber: 1,
        },
      ],
      contactPointType: {
        contactPointTypeCode: "E",
        contactPointTypeName: "Email Address",
      },
      marketingProgram: {
        description: "JPN Golden Household",
        marketingProgramNumber: 2,
      },
    },
    originalRequestDetails: {
      trait: {
        optIndicator: {
          traitName: "postalIndiaCorporateProgram2OptIndicator",
        },
        optChoiceDate: {
          traitName: "postalIndiaCorporateProgram2OptChoiceDate",
        },
      },
      ecosystems: [
        {
          ecosystemId: 1,
          ecosystemName: "segment",
        },
        {
          ecosystemId: 2,
          ecosystemName: "lytics",
        },
      ],
      optMappings: [
        {
          id: "20_02-1",
          optText:
            "<p>Yes! Sign me up to receive occasional emails from Gillette as well as emails from other trusted P&G brands which will help me: <br/><UL><LI>Save money with exclusive coupons</LI><LI>Get inspired with useful tips & ideas</LI><LI>Learn about free samples, new products, exciting promotions and other information from P&G and carefully-selected business partners</LI>",
          ciamOptId: "20_02",
          serviceName: "Corporate India",
          optTextEnglish:
            "<p>Yes! Sign me up to receive occasional emails from Gillette as well as emails from other trusted P&G brands which will help me: <br/><UL><LI>Save money with exclusive coupons</LI><LI>Get inspired with useful tips & ideas</LI><LI>Learn about free samples, new products, exciting promotions and other information from P&G and carefully-selected business partners</LI>",
          contactPointTypeCode: "A",
          contactPointTypeName: "Postal Address",
          subscriptionOptNumber: 1,
        },
        {
          id: "20_02-2",
          optText:
            "By clicking the “I AGREE” button below, I confirm and agree that: (iv)I am above 20 years.",
          ciamOptId: "20_02",
          serviceName: "Corporate India",
          optTextEnglish:
            "By clicking the “I AGREE” button below, I confirm and agree that: (iv)I am above 20 years.",
          contactPointTypeCode: "A",
          contactPointTypeName: "Postal Address",
          subscriptionOptNumber: 1,
        },
      ],
      contactPointType: {
        contactPointTypeCode: "A",
        contactPointTypeName: "Postal Address",
      },
      marketingProgram: {
        description: "IND Corporate Program",
        marketingProgramNumber: 20,
      },
    },
  },
});

const getOptRequestDetailsWithMarketingProgramOtps = {
  apiVersion: "1.7.2",
  data: {
    requestId: "CDS828",
    name: "IND Corporate Program - New Opt ID",
    requestDate: "2022-06-19T18:22:50.000Z",
    status: "pending",
    requestedBy: "developer.write",
    reviewedBy: "developer.approve",
    comments: "Checking return",
    output: null,
    geoRegionCodes: ["AMA"],
    module: "opts",
    requestDetails: {
      opt: {
        optTextEnglish: "Testing opt text with SMS for corporate program",
        optTextLocalLanguage: "Testing opt text with SMS for corporate program",
      },
      marketingProgram: {
        description: "IND Corporate Program",
        legalEntityName: "India",
        legalEntityNumber: 1,
        marketingProgramName: "GenCorp-IND-1",
        marketingProgramNumber: 20,
      },
      marketingProgramOpts: [
        {
          service: {
            isNew: true,
            serviceName: "IND Olay we",
          },
          contactPointType: {
            contactPointTypeCode: "P",
            contactPointTypeName: "Phone Number",
          },
          marketingProgram: {
            description: "IND Olay",
            marketingProgramNumber: 7,
          },
          primaryIndicator: "Primary",
          contactPointCategory: {
            description: "Business Phone",
            contactPointCategoryCode: "PB",
          },
          subscriptionOptNumber: 1,
        },
        {
          service: {
            isNew: false,
            serviceName: "IND Being Girl wer",
          },
          contactPointType: {
            contactPointTypeCode: "E",
            contactPointTypeName: "Email Address",
          },
          marketingProgram: {
            description: "IND Being Girl",
            marketingProgramNumber: 15,
          },
          primaryIndicator: "Primary",
          contactPointCategory: {
            description: "Business Email",
            contactPointCategoryCode: "EB",
          },
          subscriptionOptNumber: 2,
        },
      ],
    },
    originalRequestDetails: {
      opt: {
        optTextEnglish: "Testing opt text with SMS for corporate program",
        optTextLocalLanguage: "Testing opt text with SMS for corporate program",
      },
      marketingProgram: {
        description: "IND Corporate Program",
        legalEntityName: "India",
        legalEntityNumber: 1,
        marketingProgramName: "GenCorp-IND-1",
        marketingProgramNumber: 20,
      },
      marketingProgramOpts: [
        {
          service: {
            isNew: true,
            serviceName: "IND Olay we",
          },
          contactPointType: {
            contactPointTypeCode: "P",
            contactPointTypeName: "Phone Number",
          },
          marketingProgram: {
            description: "IND Olay",
            marketingProgramNumber: 7,
          },
          primaryIndicator: "Primary",
          contactPointCategory: {
            description: "Mobile Phone",
            contactPointCategoryCode: "PM",
          },
          subscriptionOptNumber: 1,
        },
        {
          service: {
            isNew: false,
            serviceName: "IND Being Girl wer",
          },
          contactPointType: {
            contactPointTypeCode: "E",
            contactPointTypeName: "Email Address",
          },
          marketingProgram: {
            description: "IND Being Girl",
            marketingProgramNumber: 15,
          },
          primaryIndicator: "Primary",
          contactPointCategory: {
            description: "Business Email",
            contactPointCategoryCode: "EB",
          },
          subscriptionOptNumber: 2,
        },
      ],
    },
  },
};

const getOptRequestDetailsApiResponse = (status) => ({
  apiVersion: "1.2.0",
  data: {
    requestId: "CDS480",
    name: "RewardMe India",
    requestDate: "2021-12-07T07:54:08.000Z",
    status: status || "approved",
    requestedBy: "developer.approve",
    reviewedBy: "developer.approve",
    comments: "Auto Approved",
    output: {
      items: [
        {
          new: true,
          optId: "1_01",
          serviceName: "RewardMe India",
          mappingWarning: null,
          marketingProgramNumber: 1,
        },
        {
          new: false,
          serviceName: "RewardMe India",
          mappingWarning: null,
          marketingProgramNumber: 1,
        },
        {
          new: false,
          optId: "2_01",
          serviceName: "RewardMe India",
          mappingWarning: null,
          marketingProgramNumber: 1,
        },
        {
          new: true,
          optId: "1_01",
          serviceName: "RewardMe India",
          mappingWarning: null,
          marketingProgramNumber: 1,
          message: "warning-message",
        },
      ],
      warnings: [
        {
          new: false,
          optId: "1_01",
          serviceName: "RewardMe India",
          mappingWarning: "Mapping Warning",
          marketingProgramNumber: 1,
          message: "warning-message",
        },
      ],
    },
    geoRegionCodes: ["AMA"],
    module: "opts",
    requestDetails: {
      opt: {
        optId: "1_01",
        optText: "legacy Opt - pre-GRS",
        marketingProgramNumber: 1,
        marketingProgramDescription: "IND Golden Household",
      },
      service: {
        isNew: true,
        channel: "E-Email Address",
        description: "wer wer wer wer",
        serviceName: "IND Olay wer wer",
        primaryIndicator: "Primary",
        subscriptionOptNumber: "6",
        contactPointCategoryCode: "EB-Business email",
      },
      marketingProgram: {
        description: "IND Olay",
        marketingProgramNumber: 7,
      },
    },
    originalRequestDetails: {
      opt: {
        optId: "1_02",
        optText: "legacy Opt - pre-GRS",
        marketingProgramNumber: 1,
        marketingProgramDescription: "IND Golden Household",
      },
      service: {
        isNew: false,
        channel: "E-Email Address",
        serviceName: "IND Olay werwerwerwerwer",
        primaryIndicator: "Primary",
        subscriptionOptNumber: "6",
        contactPointCategoryCode: "EB-Business email",
      },
      marketingProgram: {
        description: "IND Olay",
        marketingProgramNumber: 7,
      },
    },
  },
});

const getMarketingProgramsRequestsApiResponse = () => ({
  apiVersion: "1.4.0",
  data: {
    totalItems: 21,
    page: 1,
    itemsPerPage: 50,
    items: [
      {
        requestId: "CDS577",
        name: "IND programShortwerwerwerName",
        requestDate: "2022-02-15T12:14:31.000Z",
        status: "pending",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "marketing-programs",
        isNew: true,
      },
      {
        requestId: "CDS576",
        name: "IND testing",
        requestDate: "2022-02-15T11:21:10.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "marketing-programs",
        isNew: true,
      },
      {
        requestId: "CDS571",
        name: "IND werwerwerwerwer",
        requestDate: "2022-02-14T06:02:56.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "marketing-programs",
        isNew: true,
      },
      {
        requestId: "CDS570",
        name: "IND shortnamesare",
        requestDate: "2022-02-11T09:05:15.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "marketing-programs",
        isNew: true,
      },
      {
        requestId: "CDS569",
        name: "IND shortname",
        requestDate: "2022-02-11T07:59:00.000Z",
        status: "approved",
        requestedBy: "developer.approve",
        reviewedBy: "developer.approve",
        comments: "Auto Approved",
        geoRegionCodes: ["AMA"],
        isRevised: false,
        module: "marketing-programs",
        isNew: true,
      },
    ],
  },
});

const getMySegmentSourceRequestDetailsApiResponse = (status) => ({
  apiVersion: "2.4.0",
  data: {
    requestId: "CDS2",
    requestDate: "2021-07-16T04:02:50.000Z",
    status: status || "approved",
    requestedBy: "anandan.a.1",
    reviewedBy: "anandan.a.1",
    comments: "Auto Approved",
    geoRegionCodes: ["AMA", "APAC", "LA"],
    output: {
      items: [
        {
          new: 1,
          mappingWarning: null,
          ecosystemSourceId: 1894,
          ecosystemSourceName: "JPN Golden Household werwer (1004)",
        },
        {
          ecosystemSourceId: 1894,
          ecosystemSourceName: "JPN Golden Household werwer (1004)",
          mappingWarning: null,
          marketingProgramDescription: "JPN Golden Household",
          marketingProgramNumber: 2,
          new: true,
          sourceId: 1004,
          sourceName: "i4-i10-i60/Other/Multi/Multi/Multi/Multi/Multi",
        },
        {
          ecosystemSourceId: 1895,
          ecosystemSourceName: "JPN Golden Household werwer (1004)",
          mappingWarning: "test warning",
          marketingProgramDescription: "JPN Golden Household",
          marketingProgramNumber: 2,
          new: true,
          sourceId: 1004,
          sourceName: "i4-i10-i60/Other/Multi/Multi/Multi/Multi/Multi",
        },
        {
          ecosystemSourceId: 1896,
          ecosystemSourceName: "JPN Golden Household werwer (1004)",
          mappingWarning: null,
          marketingProgramDescription: "JPN Golden Household",
          marketingProgramNumber: 2,
          new: false,
          sourceId: 1004,
          sourceName: "i4-i10-i60/Other/Multi/Multi/Multi/Multi/Multi",
        },
      ],
      warnings: [
        {
          sourceId: "12",
          sourceName: "abc",
          marketingProgramNumber: "123",
          marketingProgramDescription: "test",
          message: "test message",
        },
      ],
    },
    originalRequestDetails: {
      marketingProgram: {
        description: "JPN Golden Household",
        marketingProgramNumber: 2,
      },
    },
    requestDetails: {
      dataSources: [
        {
          sourceId: 1004,
          sourceName: "i4-i10-i60/Other/Multi/Multi/Multi/Multi/Multi",
        },
      ],
      marketingProgram: {
        description: "JPN Golden Household",
        marketingProgramNumber: 2,
      },
      segmentSource: {
        ecosystemSourceName: "JPN Golden Household werwerwer wer ",
        ecosystemSourceType: "i5 Offline",
      },
    },
  },
});

const getMySourceRequestDetailsApiResponse = (status) => ({
  apiVersion: "2.4.0",
  data: {
    requestId: "CDS2",
    requestDate: "2021-07-16T04:02:50.000Z",
    status: status || "approved",
    requestedBy: "anandan.a.1",
    reviewedBy: "anandan.a.1",
    comments: "Auto Approved",
    geoRegionCodes: ["AMA", "APAC", "LA"],
    output: {
      items: [
        {
          new: 1,
          mappingWarning: null,
          ecosystemSourceId: 1894,
          ecosystemSourceName: "JPN Golden Household werwer (1004)",
        },
        {
          ecosystemSourceId: 1894,
          ecosystemSourceName: "JPN Golden Household werwer (1004)",
          mappingWarning: null,
          marketingProgramDescription: "JPN Golden Household",
          marketingProgramNumber: 2,
          new: true,
          sourceId: 1004,
          sourceName: "i4-i10-i60/Other/Multi/Multi/Multi/Multi/Multi",
        },
        {
          ecosystemSourceId: 1895,
          ecosystemSourceName: "JPN Golden Household werwer (1004)",
          mappingWarning: "test warning",
          marketingProgramDescription: "JPN Golden Household",
          marketingProgramNumber: 2,
          new: true,
          sourceId: 1004,
          sourceName: "i4-i10-i60/Other/Multi/Multi/Multi/Multi/Multi",
        },
        {
          ecosystemSourceId: 1896,
          ecosystemSourceName: "JPN Golden Household werwer (1004)",
          mappingWarning: null,
          marketingProgramDescription: "JPN Golden Household",
          marketingProgramNumber: 2,
          new: false,
          sourceId: 1004,
          sourceName: "i4-i10-i60/Other/Multi/Multi/Multi/Multi/Multi",
        },
      ],
      warnings: [
        {
          sourceId: "12",
          sourceName: "abc",
          marketingProgramNumber: "123",
          marketingProgramDescription: "test",
          message: "test message",
        },
      ],
    },
    requestDetails: {
      dataSource: {
        agencyName: "",
        businessFacingName: "JPN Golden Household ertert",
        coControllerInd: false,
        consumerFacingDescription: "ert ert ert ert ert",
        dataSourceWebsite: false,
        dataType: "Purchase Data,Dependent Data",
        description: "erter er ert ert ert",
        descriptionOfProcess: "ert ert ert ert ert",
        managedByExternalAgency: false,
        registrationPageUrl: "",
        ropaId: "",
        sourceName: "JPN Golden Household ertert",
        systemDisplayingPage: "",
        url: "",
      },
      marketingProgram: {
        description: "JPN Golden Household",
        marketingProgramName: "GHH-JPN-1",
        marketingProgramNumber: 2,
      },
    },
  },
});

const getTraitResponseDetailsWithOutput = {
  apiVersion: "1.10.0",
  data: {
    requestId: 1123,
    name: "childrenIdValue",
    module: "traits",
    requestDate: "2022-09-22T16:13:33.000Z",
    status: "approved",
    requestedBy: "cddawebapp.im",
    reviewedBy: "cddawebapp.im",
    comments: "Auto Approved",
    output: {
      items: [
        {
          new: true,
          traitId: 1,
          language: "Arabic",
          languageCode: "ar",
          traitResponse: "1",
          marketingProgramNumber: 1,
          traitResponseLocalLanguage: "1",
          marketingProgramPredefinedTraitResponseMapId: 247,
        },
      ],
      warnings: [],
    },
    geoRegionCodes: ["AMA"],
    requestDetails: {
      trait: {
        traitId: 1,
        traitName: "childrenIdValue",
        traitDescription: "Children",
      },
      traitResponses: [
        {
          language: "Arabic",
          languageCode: "ar",
          traitResponse: "1",
          traitResponseLocalLanguage: "1",
        },
      ],
      marketingProgram: {
        description: "IND Golden Household",
        marketingProgramNumber: 1,
      },
    },
  },
};

const getTraitResponseDetailsApiResponse = {
  apiVersion: "1.10.0",
  data: {
    requestId: 1115,
    name: "selfDescriptionSkin",
    module: "traits",
    requestDate: "2022-09-22T01:52:57.000Z",
    status: "pending",
    requestedBy: "developer.approve",
    reviewedBy: "developer.approve",
    comments: "",
    output: null,
    geoRegionCodes: ["EURO"],
    requestDetails: {
      trait: {
        traitId: 28,
        traitName: "selfDescriptionSkin",
        traitDescription: "Self-description - skin type",
      },
      traitResponses: [
        {
          language: "Hindi",
          languageCode: "hi",
          traitResponse: "HHH",
          predefinedTraitNumber: 80,
          globalStandardResponse: "Sensitive",
          traitResponseLocalLanguage: "HHH",
        },
      ],
      marketingProgram: {
        description: "ESP Golden Household",
        marketingProgramNumber: 290,
      },
    },
    originalRequestDetails: {
      trait: {
        traitId: 28,
        traitName: "selfDescriptionSkin",
        traitDescription: "Self-description - skin type",
      },
      traitResponses: [
        {
          language: "Malayalam",
          languageCode: "ml",
          traitResponse: "MMM",
          predefinedTraitNumber: 78,
          globalStandardResponse: "Mostly oily",
          traitResponseLocalLanguage: "MMM",
        },
      ],
      marketingProgram: {
        description: "ESP Golden Household",
        marketingProgramNumber: 290,
      },
    },
  },
};

const getUseCaseRequestDetailsApiResponse = {
  apiVersion: "1.7.2",
  data: {
    requestId: "CDS823",
    name: "jump king",
    requestDate: "2022-06-17T07:58:06.000Z",
    status: "pending",
    requestedBy: "developer.write",
    reviewedBy: "developer.approve",
    comments: null,
    output: null,
    geoRegionCodes: ["AMA"],
    module: "traits",
    requestDetails: {
      traits: [
        {
          traitId: 2,
          dataType: "string",
          traitName: "referral",
          description: "Referral",
          personalData: false,
        },
        {
          traitId: 1027,
          dataType: "string",
          traitName: "birthdate",
          description: "DOB - approximate",
          personalData: true,
        },
        {
          traitId: 1,
          dataType: "string",
          traitName: "childrenIdValue",
          description: "Children",
          personalData: false,
        },
      ],
      dataUseCase: {
        useCaseId: 24,
        useCaseName: "jump king",
        useCaseDescription: "fruits veg nonveg biriyani",
      },
      marketingProgram: {
        description: "JPN Pampers",
        marketingProgramName: "Pamp-JPN-1",
        marketingProgramNumber: 43,
      },
    },
    originalRequestDetails: {
      traits: [
        {
          deleted: true,
          traitId: 1,
          dataType: "string",
          traitName: "childrenIdValue",
          description: "Children",
          personalData: false,
        },
        {
          traitId: 2,
          dataType: "string",
          traitName: "referral",
          description: "Referral",
          personalData: false,
        },
      ],
      dataUseCase: {
        useCaseId: 24,
        useCaseName: "jump king",
        useCaseDescription: "fruits veg nonveg biriyani",
      },
      marketingProgram: {
        description: "IND Golden Household",
        marketingProgramName: "GHH-IND-1",
        marketingProgramNumber: 1,
      },
    },
  },
};

const getMyRequestDetailsApiResponse = (status) => ({
  apiVersion: "2.4.0",
  data: {
    requestId: "CDS2",
    requestDate: "2021-07-16T04:02:50.000Z",
    status: status || "approved",
    requestedBy: "anandan.a.1",
    reviewedBy: "anandan.a.1",
    comments: "Auto Approved",
    geoRegionCodes: ["AMA", "APAC", "LA"],
    output: {
      items: [
        {
          new: 1,
          traitId: 4257,
          traitName: "headsetBrandName",
          mappingWarning: null,
          traitDescription: "headset brand name details",
        },
        {
          new: 1,
          traitId: 4257,
          traitName: "headsetBrandName",
          traitDescription: "headset brand name details",
          marketingProgramName: "GHH-JPN-1",
          marketingProgramNumber: 2,
        },
        {
          new: 0,
          traitId: 4257,
          traitName: "headsetBrandName",
          ecosystemId: 2,
          ecosystemName: "lytics",
          mappingWarning: 1,
          traitDescription: "headset brand name details",
          ecosystemTraitName: "trait_headsetbrand_id_value",
          marketingProgramName: "GHH-JPN-1",
          marketingProgramNumber: 2,
        },
      ],
      warnings: [],
    },
    requestDetails: {
      trait: {
        dataType: "string",
        dataClass: "Persona Traits",
        formInput: "Dropdown",
        traitName: "headsetBrandName-revised",
        traitType: "Custom Trait",
        description: "headset brand name details",
        personalData: true,
        exampleValues: "airpods pixelbuds beatsx boat",
        ecosystemTraits: [
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 1,
            ecosystemName: "segment",
            ecosystemTraitName: "traitheadsetbrandIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 2,
            ecosystemName: "lytics",
            ecosystemTraitName: "trait_headsetbrand_id_value",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "trait headsetbrand id_value",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 3,
            ecosystemName: "dataLake",
            ecosystemTraitName: "traitheadsetbrandIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 4,
            ecosystemName: "mapp",
            ecosystemTraitName: "traitheadsetbrandIdValue",
            ecosystemTraitType: "Member",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 5,
            ecosystemName: "bigQuery",
            ecosystemTraitName: "trait_headsetbrand_id_value",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 6,
            ecosystemName: "janrain",
            ecosystemTraitName: "traitheadsetbrand.traitValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 7,
            ecosystemName: "1CP",
            ecosystemTraitName: "headsetbrand",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 8,
            ecosystemName: "Braze",
            ecosystemTraitName: "traitheadsetbrandIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
        ],
        uDataChangeFrequency: "Once a year or less",
        sensitivePersonalData: true,
      },
      ecosystems: [{ ecosystemId: 2, ecosystemName: "lytics" }],
      marketingPrograms: [
        { marketingProgramName: "GHH-JPN-1", marketingProgramNumber: 2 },
      ],
    },
    originalRequestDetails: {
      trait: {
        dataType: "string",
        dataClass: "Persona Traits",
        formInput: "Dropdown",
        traitName: "headsetBrandName",
        traitType: "Custom Trait",
        description: "headset brand name details",
        personalData: true,
        exampleValues: "airpods pixelbuds beatsx boat",
        ecosystemTraits: [
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 1,
            ecosystemTraitName: "traitheadsetbrandIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 2,
            ecosystemTraitName: "trait_headsetbrand_id_value",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "trait headsetbrand id_value",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 3,
            ecosystemTraitName: "traitheadsetbrandIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 4,
            ecosystemTraitName: "traitheadsetbrandIdValue",
            ecosystemTraitType: "Member",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 5,
            ecosystemTraitName: "trait_headsetbrand_id_value",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 6,
            ecosystemTraitName: "traitheadsetbrand.traitValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 7,
            ecosystemTraitName: "headsetbrand",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
          {
            dataType: "string",
            dataClass: "Persona Traits",
            ecosystemId: 8,
            ecosystemTraitName: "traitheadsetbrandIdValue",
            ecosystemTraitType: "Custom Trait",
            ecosystemTraitMiscProperty1: "",
          },
        ],
        uDataChangeFrequency: "Once a year or less",
        sensitivePersonalData: true,
      },
      ecosystems: [{ ecosystemId: 2, ecosystemName: "lytics" }],
      marketingPrograms: [
        { marketingProgramName: "GHH-JPN-1", marketingProgramNumber: 2 },
      ],
    },
  },
});

const getOptMappingRequestDetailsApiResponse = () => ({
  apiVersion: "1.2.0",
  data: {
    requestId: "CDS480",
    name: "RewardMe India",
    requestDate: "2021-12-07T07:54:08.000Z",
    status: "approved",
    requestedBy: "developer.approve",
    reviewedBy: "developer.approve",
    comments: "Auto Approved",
    output: {
      items: [
        {
          new: true,
          optId: "1_01",
          serviceName: "RewardMe India",
          mappingWarning: null,
          marketingProgramNumber: 1,
        },
      ],
      warnings: [],
    },
    geoRegionCodes: ["AMA"],
    module: "opts",
    requestDetails: {
      opt: {
        optId: "1_01",
        optText: "By registering, I agree to receive emails.",
        marketingProgramNumber: 1,
        marketingProgramDescription: "IND Golden Household",
      },
      service: {
        isNew: false,
        channel: "E-Email Address",
        description: null,
        serviceName: "RewardMe India",
        primaryIndicator: "Secondary",
        subscriptionOptNumber: "4",
        contactPointCategoryCode: "EP-Personal Email",
      },
      marketingProgram: {
        description: "IND Golden Household",
        marketingProgramNumber: 1,
      },
    },
  },
});

const getMarketingProgramRequestDetailsApiResponse = (status) => ({
  apiVersion: "1.4.0",
  data: {
    requestId: "CDS576",
    name: "IND testing",
    requestDate: "2022-02-15T11:21:10.000Z",
    status: status || "approved",
    requestedBy: "developer.approve",
    reviewedBy: "developer.approve",
    comments: "Auto Approved",
    output: {
      items: [
        {
          new: true,
          countryCode: "IND",
          description: "IND testing",
          mappingWarning: "Mapping Warning",
          marketingProgramName: "programShortName-IND-1",
          marketingProgramNumber: 60530,
        },
        {
          new: true,
          countryCode: "IND",
          description: "IND testing",
          mappingWarning: null,
          marketingProgramName: "programShortName-IND-1",
          marketingProgramNumber: 60530,
        },
        {
          new: true,
          traitId: 4491,
          traitName: "postalIndiaprogramNameOptIndicator",
          mappingWarning: null,
          traitDescription:
            "Opt indicator trait for Postal Address channel for IND testing",
        },
        {
          new: false,
          traitId: 4492,
          traitName: "postalIndiaprogramNameOptChoiceDate",
          mappingWarning: null,
          message: "Warning Message",
          traitDescription:
            "Opt choice date trait for Postal Address channel for IND testing",
        },
        {
          new: true,
          serviceName: "IND testing Postal",
          mappingWarning: null,
          marketingProgramNumber: 60530,
        },
        {
          new: true,
          sourceName: "i60/Other/Multi/Multi/Multi/Multi/Corbus",
          mappingWarning: null,
          pgDataSourceId: 1174,
          marketingProgramNumber: 60530,
        },
        {
          new: true,
          ecosystemId: 1,
          ecosystemName: "segment",
          mappingWarning: null,
          ecosystemSourceId: 1968,
          ecosystemSourceName: "IND testing Corbus (1174)",
        },
        {
          new: true,
          sourceName: "Consumer Privacy Center",
          mappingWarning: null,
          pgDataSourceId: 13463,
          marketingProgramNumber: 60530,
        },
        {
          new: true,
          ecosystemId: 1,
          ecosystemName: "segment",
          mappingWarning: null,
          ecosystemSourceId: 1969,
          ecosystemSourceName: "IND testing CPC (13463)",
        },
        {
          new: true,
          sourceName: "Data Remediation Source",
          mappingWarning: null,
          pgDataSourceId: 14324,
          marketingProgramNumber: 60530,
        },
        {
          new: true,
          ecosystemId: 1,
          ecosystemName: "segment",
          mappingWarning: null,
          ecosystemSourceId: 1970,
          ecosystemSourceName: "IND testing Data Remediation (14324)",
        },
        {
          new: true,
          sourceName: "Retention Transmitter Source",
          mappingWarning: null,
          pgDataSourceId: 12230,
          marketingProgramNumber: 60530,
        },
        {
          new: true,
          ecosystemId: 1,
          ecosystemName: "segment",
          mappingWarning: null,
          ecosystemSourceId: 1971,
          ecosystemSourceName: "IND testing Retention Rules (12230)",
        },
      ],
      warnings: [
        {
          new: true,
          countryCode: "IND",
          description: "IND testing",
          mappingWarning: null,
          marketingProgramName: "programShortName-IND-1",
          marketingProgramNumber: 60530,
          message: "Mapping warning",
        },
        {
          new: true,
          countryCode: "IND",
          description: "IND testing",
          mappingWarning: null,
          marketingProgramName: "programShortName-IND-1",
          marketingProgramNumber: 60530,
          message: "Mapping warning",
        },
        {
          new: true,
          traitId: 4491,
          traitName: "postalIndiaprogramNameOptIndicator",
          mappingWarning: null,
          traitDescription:
            "Opt indicator trait for Postal Address channel for IND testing",
          message: "Mapping warning",
        },
      ],
    },
    geoRegionCodes: ["AMA"],
    module: "marketing-programs",
    requestDetails: {
      ecosystems: [
        {
          ecosystemId: 1,
          ecosystemName: "segment",
        },
      ],
      legalEntity: {
        legalEntityName: "India",
        legalEntityNumber: 1,
        legalEntityShortName: "IND",
      },
      marketingProgram: {
        description: "IND testing",
        mpBusinessName: "programShortName1-IND-1",
        brandProgramName: "programName",
        marketingProgramName: "programShortName-IND-1",
        brandProgramShortName: "programShortName",
      },
      contactPointTypes: [
        {
          contactPointTypeCode: "A",
          contactPointTypeName: "Postal Address",
        },
      ],
    },
  },
});

const getMarketingProgramEcosystemRequestDetailsApiResponse = (status) => ({
  apiVersion: "1.4.0",
  data: {
    requestId: "CDS647",
    name: "AUS Herbal Essence",
    requestDate: "2022-04-08T07:31:17.000Z",
    status: status || "approved",
    requestedBy: "developer.write",
    reviewedBy: "developer.approve",
    comments: "Return Request",
    geoRegionCodes: ["APAC"],
    module: "marketing-programs",
    output: {
      items: [
        {
          new: 0,
          ecosystemId: 1,
          gcpSecretName: "CDS_SEGMENT_TOKEN_POC",
          mappingWarning:
            "Ecosystem details already exists for this ecosystem. Automation is enabled.",
          automationEnabled: 1,
          marketingProgramNumber: 16,
          ecosystemMarketingProgramKey: "spa_1ZCUWY1MjIZw75mTBLxBL3TuJNT",
          ecosystemMarketingProgramName: "JPN Whisper",
          ecosystemMarketingProgramInstance: "pg-global-dev",
        },
        {
          new: 1,
          ecosystemId: 3,
          mappingWarning: null,
          automationEnabled: 1,
          marketingProgramNumber: 16,
          ecosystemMarketingProgramName: "Data Lake - JPN Whisper",
        },
        {
          new: 1,
          ecosystemId: 5,
          mappingWarning: null,
          automationEnabled: 1,
          marketingProgramNumber: 16,
          ecosystemMarketingProgramKey: "personas_jpn_whisper",
          ecosystemMarketingProgramName: "JPN Whisper",
          ecosystemMarketingProgramInstance: "none",
        },
        {
          new: false,
          ecosystemId: 1,
          gcpSecretName: "CDS_SEGMENT_TOKEN_POC",
          mappingWarning:
            "Ecosystem Source with name Personas JPN Whisper already exists.",
          automationEnabled: 1,
          ecosystemSourceId: -1,
          ecosystemSourceName: "Personas JPN Whisper",
          marketingProgramNumber: 16,
          ecosystemMarketingProgramKey: "spa_1ZCUWY1MjIZw75mTBLxBL3TuJNT",
          ecosystemMarketingProgramName: "JPN Whisper",
          ecosystemMarketingProgramInstance: "pg-global-dev",
        },
        {
          new: 0,
          ecosystemId: 2,
          gcpSecretName: "CDS_LYTICS_TOKENS_16",
          mappingWarning:
            "Ecosystem details already exists for this ecosystem. Automation is enabled.",
          automationEnabled: 1,
          marketingProgramNumber: 16,
          ecosystemMarketingProgramKey: "3532",
          ecosystemMarketingProgramName: "PG - QA - Japan - Whisper",
          ecosystemMarketingProgramInstance: "",
        },
        {
          new: 0,
          ecosystemId: 4,
          mappingWarning:
            "Ecosystem details already exists for this ecosystem. Automation is enabled.",
          automationEnabled: 1,
          marketingProgramNumber: 16,
          ecosystemMarketingProgramKey: "6600000027",
          ecosystemMarketingProgramName: "Japan Whisp-JPN-1 MP 16 Master Group",
          ecosystemMarketingProgramInstance: "pg_c360jp_multibrand",
        },
      ],
      warnings: [],
    },
    requestDetails: {
      ecosystems: [
        {
          ecosystemId: 1,
          ecosystemName: "segment",
          automationEnabled: true,
          ecosystemMarketingProgramKey: "spa_qweqwe234",
          ecosystemMarketingProgramName: "IND testing",
          ecosystemMarketingProgramInstance: "pg-global-dev",
        },
        {
          ecosystemId: 4,
          ecosystemName: "mapp",
          automationEnabled: true,
          ecosystemMarketingProgramKey: "90909090909",
          ecosystemMarketingProgramName: "jlkkj",
          ecosystemMarketingProgramInstance: "pg_12_12",
        },
        {
          ecosystemId: 6,
          ecosystemName: "janrain",
          automationEnabled: true,
          ecosystemMarketingProgramKey: "sdasdasd",
          ecosystemMarketingProgramName: "asd_asd_12",
          ecosystemMarketingProgramInstance: "eu",
        },
      ],
      marketingProgram: {
        description: "AUS Herbal Essence",
        marketingProgramNumber: 12,
      },
    },
    originalRequestDetails: {
      ecosystems: [
        {
          ecosystemId: 1,
          ecosystemName: "segment",
          automationEnabled: true,
          ecosystemMarketingProgramKey: "spa_qweqwe",
          ecosystemMarketingProgramName: "IND sdas qweqwe",
          ecosystemMarketingProgramInstance: "pg-global-dev",
        },
        {
          ecosystemId: 4,
          ecosystemName: "mapp",
          automationEnabled: true,
          ecosystemMarketingProgramKey: "90909090909",
          ecosystemMarketingProgramName: "jlkkj",
          ecosystemMarketingProgramInstance: "pg_12_12",
        },
        {
          ecosystemId: 6,
          ecosystemName: "janrain",
          automationEnabled: true,
          ecosystemMarketingProgramKey: "sdasdasd",
          ecosystemMarketingProgramName: "asd_asd_12",
          ecosystemMarketingProgramInstance: "eu",
        },
      ],
      marketingProgram: {
        description: "AUS Herbal Essence",
        marketingProgramNumber: 12,
      },
    },
  },
});

const getUpdatedStatusApiResponse = (status) => ({
  apiVersion: "2.4.1",
  data: {
    requestId: "60",
    status,
  },
});

const getJobsApiResponsePagination = (_opts) => {
  const opts = _opts || {
    perPage: 48,
    startIndexOffset: 0,
  };

  const items = Array.from({ length: opts.perPage }).map((v, i) => ({
    jobId: opts.startIndexOffset ? i + 1 + opts.startIndexOffset : i + 1,
    jobName: `test-${i}`,
    batchProcessingStartTime: null,
    batchProcessingEndTime: null,
    jobStartTime: "2020-04-13T10:29:00.000Z",
    jobEndTime: "2022-12-30T19:59:30.000Z",
    status: "failed",
    message: "Processing of 2 segment sources failed.",
    entity: {
      ecosystemSourceIds: [1988, 2115],
    },
    retryCount: 0,
    maxRetries: 3,
    retryJob: false,
    prerequisiteJobIds: [123],
  }));

  return {
    data: {
      items,
      totalItems: 200,
    },
  };
};

const getJobsApiResponse = () => ({
  apiVersion: "1.7.0",
  data: {
    totalItems: 50,
    page: 1,
    itemsPerPage: 50,
    items: [
      {
        jobId: 772,
        jobName: "segment_sources",
        batchProcessingStartTime: null,
        batchProcessingEndTime: null,
        jobStartTime: "2020-04-13T10:29:00.000Z",
        jobEndTime: "2022-12-30T19:59:30.000Z",
        status: "failed",
        message: "Processing of 2 segment sources failed.",
        entity: {
          ecosystemSourceIds: [1988, 2115],
        },
        retryCount: 0,
        maxRetries: 3,
        retryJob: false,
        prerequisiteJobIds: [123],
      },
      {
        jobId: 782,
        jobName: "segment_sources",
        batchProcessingStartTime: null,
        batchProcessingEndTime: null,
        jobStartTime: "2022-01-13T11:37:00.000Z",
        jobEndTime: "2022-04-13T11:37:06.000Z",
        status: "success-partial",
        message: "1 out of 2 segment sources processed successfully.",
        entity: {
          ecosystemSourceIds: [1988, 2115],
        },
        retryCount: 3,
        maxRetries: 3,
        retryJob: true,
        prerequisiteJobIds: null,
      },
      {
        jobId: 785,
        jobName: "segment_tracking_plans",
        batchProcessingStartTime: null,
        batchProcessingEndTime: null,
        jobStartTime: "2022-04-10T12:00:00.000Z",
        jobEndTime: "2022-04-13T12:00:10.000Z",
        status: "success",
        message:
          "Processing of Job ID 785 with 1 marketing programs completed sucessfully.",
        entity: {
          environmentId: "DEV",
          ecosystemSourceId: 2115,
          marketingProgramNumber: 16,
        },
        retryCount: 1,
        maxRetries: 3,
        retryJob: true,
        prerequisiteJobIds: null,
      },
      {
        jobId: 789,
        jobName: "segment_destinations",
        batchProcessingStartTime: null,
        batchProcessingEndTime: null,
        jobStartTime: "2022-04-13T12:31:20.000Z",
        jobEndTime: null,
        status: "running",
        message: "Processing Job ID 789",
        entity: {
          jobName: "segment_destinations",
          destination: "catalog/destinations/repeater",
          ecosystemId: 1,
          ecosystemSourceId: 1988,
          marketingProgramNumber: 16,
        },
        retryCount: 1,
        maxRetries: 3,
        retryJob: true,
        prerequisiteJobIds: null,
      },
      {
        jobId: 790,
        jobName: "segment_destinations_plan",
        batchProcessingStartTime: null,
        batchProcessingEndTime: null,
        jobStartTime: "2022-04-13T12:31:20.000Z",
        jobEndTime: null,
        status: "running",
        message: "Processing Job ID 790",
        entity: {
          jobName: "segment_destinations",
          destination: "catalog/destinations/repeater",
          ecosystemId: 1,
          ecosystemSourceId: 1988,
          marketingProgramNumber: 16,
        },
        retryCount: 1,
        maxRetries: 3,
        retryJob: true,
        retryImmediately: true,
        prerequisiteJobIds: null,
      },
      {
        jobId: 791,
        jobName: "segment_destinations_plan_test",
        batchProcessingStartTime: null,
        batchProcessingEndTime: null,
        jobStartTime: "2022-04-13T12:31:20.000Z",
        jobEndTime: null,
        status: "success-partial",
        message: "Processing Job ID 791",
        entity: {
          jobName: "segment_destinations",
          destination: "catalog/destinations/repeater",
          ecosystemId: 1,
          ecosystemSourceId: 1988,
          marketingProgramNumber: 16,
        },
        retryCount: 1,
        maxRetries: 3,
        retryJob: true,
        retryImmediately: true,
        prerequisiteJobIds: null,
      },
      {
        jobId: 792,
        jobName: "segment_destinations_plan_test_1",
        batchProcessingStartTime: null,
        batchProcessingEndTime: null,
        jobStartTime: "2022-04-13T12:31:20.000Z",
        jobEndTime: null,
        status: "success-partial",
        message: "Processing Job ID 792",
        entity: {
          jobName: "segment_destinations",
          destination: "catalog/destinations/repeater",
          ecosystemId: 1,
          ecosystemSourceId: 1988,
          marketingProgramNumber: 16,
        },
        retryCount: 1,
        maxRetries: 3,
        retryJob: true,
        prerequisiteJobIds: null,
      },
    ],
  },
});

const getEventProperties = () => ({
  apiVersion: "1.5.0",
  data: [
    {
      propertyName: "bigCommerceId",
      dataType: "",
      description: "customer's unique id in the Big Commerce System",
    },
    {
      propertyName: "brand",
      dataType: "",
      description: "P&G Brand",
    },
    {
      propertyName: "brandGroup",
      dataType: "",
      description: "Brand Group Name (created for and maintained by US PGGE)",
    },
    {
      propertyName: "brandGroupCode",
      dataType: "",
      description: "Brand Group Code (created for and maintained by US PGGE)",
    },
    {
      propertyName: "category",
      dataType: "",
      description: "P&G category",
    },
    {
      propertyName: "consumerId",
      dataType: "String",
      description:
        "Customer id from the main system registering the consumer for this program.  This is usually Janrain if the program is using Janrain for it's CIAM system.  It is Big Commerce customer id if using Big Commerce alone.  This IS NOT to be used by offline sources.",
    },
    {
      propertyName: "endingWebUrl",
      dataType: "",
      description: "ending web url of at the time of the event",
    },
    {
      propertyName: "googleAnalyticsClientId",
      dataType: "",
      description: "Google Analytics Client ID.",
    },
    {
      propertyName: "leadKey",
      dataType: "",
      description:
        "unique key generated to represent a send of a communication to a consumer.  Unique by program/source/consumer",
    },
    {
      propertyName: "marketingProgramNumber",
      dataType: "string",
      description: "P&G marketing program number",
    },
    {
      propertyName: "productGTIN",
      dataType: "",
      description: "GTIN of the product",
    },
    {
      propertyName: "productId",
      dataType: "",
      description: "product ID",
    },
    {
      propertyName: "productName",
      dataType: "",
      description: "Product Name",
    },
    {
      propertyName: "sector",
      dataType: "string",
      description: "P&G Sector",
    },
    {
      propertyName: "segment",
      dataType: "string",
      description: "P&G Segment",
    },
    {
      propertyName: "sourceId",
      dataType: "string",
      description: "P&G assigned sourceId of the system sending the data",
    },
    {
      propertyName: "startingWebUrl",
      dataType: "String",
      description: "URL when starting event",
    },
    {
      propertyName: "subBrand",
      dataType: "String",
      description: "P&G sub Brand",
    },
    {
      propertyName: "subSector",
      dataType: "String",
      description: "P&G Sub Sector",
    },
    {
      propertyName: "tradeDeskId",
      dataType: "Integer",
      description:
        "Unique Id assigned to the consumer device on our website by the Trade Desks Platform",
    },
  ],
});

const getJobDetailsApiResponse = () => ({
  apiVersion: "1.7.0",
  data: {
    jobId: 772,
    jobName: "segment_sources",
    jobStartTime: "2022-04-13T10:29:00.000Z",
    jobEndTime: "2022-04-13T10:29:00.000Z",
    status: "failed",
    message: "Processing of 2 segment sources failed.",
    retryCount: 0,
    maxRetries: 3,
    output: {
      attempt1: [
        {
          jobId: 772,
          entity: {
            type: "Segment Source Creation",
            environmentId: "DEV",
            ecosystemSourceId: 1988,
          },
          status: "failed",
          message: "ecosystemSourceType not configured for 1988",
          requestId: 223,
          retryRequest: false,
        },
        {
          jobId: 772,
          entity: {
            type: "Segment Source Creation",
            environmentId: "DEV",
            ecosystemSourceId: 2115,
          },
          status: "failed",
          message: "ecosystemSourceType not configured for 2115",
          requestId: 224,
          retryRequest: false,
        },
      ],
    },
    retryJob: false,
    prerequisiteJobIds: null,
    ecosystemSourceIds: [1988, 2115],
  },
});

const getTraitsResponse = () => ({
  apiVersion: "2.18.1",
  data: {
    traitId: 1,
    traitName: "childrenIdValue",
    traitDescription: "Children",
    scope: "global",
    mappedTraitResponses: [
      {
        traitResponses: [
          {
            predefinedTraitResponse: "Four",
            predefinedTraitNumber: 341,
          },
          {
            predefinedTraitResponse: "One",
            predefinedTraitNumber: 306,
          },
          {
            predefinedTraitResponse: "Three",
            predefinedTraitNumber: 314,
          },
          {
            predefinedTraitResponse: "Two",
            predefinedTraitNumber: 307,
          },
        ],
      },
    ],
  },
});

const getTraitsResponses = () => ({
  apiVersion: "2.18.1",
  data: {
    items: [
      {
        traitId: 1,
        traitName: "childrenIdValue",
        traitDescription: "Children",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 307, predefinedTraitResponse: "Two" },
          { predefinedTraitNumber: 314, predefinedTraitResponse: "Three" },
          { predefinedTraitNumber: 306, predefinedTraitResponse: "One" },
          { predefinedTraitNumber: 341, predefinedTraitResponse: "Four" },
        ],
      },
      {
        traitId: 2,
        traitName: "referral",
        traitDescription: "Referral",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 309,
            predefinedTraitResponse: "Ember Spirit",
          },
          {
            predefinedTraitNumber: 297,
            predefinedTraitResponse: "Earth Spirit",
          },
          {
            predefinedTraitNumber: 295,
            predefinedTraitResponse: "Storm Spirit",
          },
          { predefinedTraitNumber: 312, predefinedTraitResponse: "new global" },
          { predefinedTraitNumber: 311, predefinedTraitResponse: "test1" },
          {
            predefinedTraitNumber: 310,
            predefinedTraitResponse: "GlobalResponse",
          },
          {
            predefinedTraitNumber: 298,
            predefinedTraitResponse: "Void spirit",
          },
          { predefinedTraitNumber: 299, predefinedTraitResponse: "new spirit" },
        ],
      },
      {
        traitId: 18,
        traitName: "traitUseBrandGroomingIdValue",
        traitDescription: "Use - brand grooming",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 328, predefinedTraitResponse: "Kill" },
          { predefinedTraitNumber: 285, predefinedTraitResponse: "Mac2" },
          { predefinedTraitNumber: 286, predefinedTraitResponse: "Dee" },
          {
            predefinedTraitNumber: 300,
            predefinedTraitResponse: "new response",
          },
          { predefinedTraitNumber: 325, predefinedTraitResponse: "Army" },
        ],
      },
      {
        traitId: 28,
        traitName: "selfDescriptionSkin",
        traitDescription: "Self-description - skin type",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 80, predefinedTraitResponse: "Sensitive" },
          { predefinedTraitNumber: 79, predefinedTraitResponse: "Mostly dry" },
          { predefinedTraitNumber: 81, predefinedTraitResponse: "Combination" },
          { predefinedTraitNumber: 250, predefinedTraitResponse: "Grapes" },
          { predefinedTraitNumber: 278, predefinedTraitResponse: "Applesu" },
          { predefinedTraitNumber: 277, predefinedTraitResponse: "Jest" },
          { predefinedTraitNumber: 252, predefinedTraitResponse: "Cat" },
          { predefinedTraitNumber: 253, predefinedTraitResponse: "Jump" },
          { predefinedTraitNumber: 270, predefinedTraitResponse: "Checking" },
          { predefinedTraitNumber: 269, predefinedTraitResponse: "tester" },
          { predefinedTraitNumber: 268, predefinedTraitResponse: "Jack" },
          { predefinedTraitNumber: 267, predefinedTraitResponse: "Queen" },
          { predefinedTraitNumber: 266, predefinedTraitResponse: "King" },
          { predefinedTraitNumber: 265, predefinedTraitResponse: "Hot" },
          { predefinedTraitNumber: 264, predefinedTraitResponse: "Cold" },
          { predefinedTraitNumber: 263, predefinedTraitResponse: "Dry" },
          { predefinedTraitNumber: 262, predefinedTraitResponse: "Wet" },
          { predefinedTraitNumber: 261, predefinedTraitResponse: "Rocket" },
          { predefinedTraitNumber: 260, predefinedTraitResponse: "Vampire" },
          { predefinedTraitNumber: 259, predefinedTraitResponse: "Olive" },
          { predefinedTraitNumber: 258, predefinedTraitResponse: "Plum" },
          { predefinedTraitNumber: 257, predefinedTraitResponse: "Beans" },
          { predefinedTraitNumber: 256, predefinedTraitResponse: "Den" },
          { predefinedTraitNumber: 255, predefinedTraitResponse: "Army" },
          { predefinedTraitNumber: 254, predefinedTraitResponse: "Hill" },
          { predefinedTraitNumber: 319, predefinedTraitResponse: "new global" },
          { predefinedTraitNumber: 78, predefinedTraitResponse: "Mostly oily" },
          { predefinedTraitNumber: 77, predefinedTraitResponse: "Normal" },
        ],
      },
      {
        traitId: 31,
        traitName: "UseHairCareProduct",
        traitDescription: "traitUseHairCareProductIdValue",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 275, predefinedTraitResponse: "tester" },
          { predefinedTraitNumber: 320, predefinedTraitResponse: "Change" },
          { predefinedTraitNumber: 276, predefinedTraitResponse: "worker" },
        ],
      },
      {
        traitId: 51,
        traitName: "concernsSkin",
        traitDescription: "Concerns - Skin",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 104,
            predefinedTraitResponse: "Sagging Skin",
          },
          {
            predefinedTraitNumber: 103,
            predefinedTraitResponse: "Wrinkles and Fine Lines",
          },
          {
            predefinedTraitNumber: 105,
            predefinedTraitResponse: "Uneven Skin Tone",
          },
          { predefinedTraitNumber: 106, predefinedTraitResponse: "Dullness" },
          {
            predefinedTraitNumber: 107,
            predefinedTraitResponse: "Visible Pores",
          },
          { predefinedTraitNumber: 108, predefinedTraitResponse: "Acne" },
          { predefinedTraitNumber: 109, predefinedTraitResponse: "Aging-eyes" },
          {
            predefinedTraitNumber: 110,
            predefinedTraitResponse: "Dark Circles",
          },
          { predefinedTraitNumber: 111, predefinedTraitResponse: "Puffy Eyes" },
        ],
      },
      {
        traitId: 77,
        traitName: "myWrinklesAre",
        traitDescription: "Skin concern - wrinkles",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 58, predefinedTraitResponse: "Fine Lines" },
          {
            predefinedTraitNumber: 59,
            predefinedTraitResponse: "Barely there",
          },
          {
            predefinedTraitNumber: 60,
            predefinedTraitResponse: "Definitely there!",
          },
          {
            predefinedTraitNumber: 61,
            predefinedTraitResponse: "Expression Lines",
          },
          {
            predefinedTraitNumber: 313,
            predefinedTraitResponse: "New Global Test 1",
          },
        ],
      },
      {
        traitId: 98,
        traitName: "femcareProductUsed",
        traitDescription: "Use - femcare product",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 145,
            predefinedTraitResponse: "Pantyliners",
          },
          {
            predefinedTraitNumber: 141,
            predefinedTraitResponse: "Menstrual Cup",
          },
          {
            predefinedTraitNumber: 142,
            predefinedTraitResponse: "Menstrual Panties",
          },
          { predefinedTraitNumber: 143, predefinedTraitResponse: "Pads" },
          { predefinedTraitNumber: 144, predefinedTraitResponse: "Tampons" },
          { predefinedTraitNumber: 146, predefinedTraitResponse: "Others" },
        ],
      },
      {
        traitId: 99,
        traitName: "UseBrandTampon",
        traitDescription: "Use - brand tampon",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 242, predefinedTraitResponse: "OB" },
          { predefinedTraitNumber: 243, predefinedTraitResponse: "Deliplus" },
          {
            predefinedTraitNumber: 245,
            predefinedTraitResponse: "I do not use",
          },
          { predefinedTraitNumber: 244, predefinedTraitResponse: "Others" },
          { predefinedTraitNumber: 241, predefinedTraitResponse: "Tampax" },
        ],
      },
      {
        traitId: 129,
        traitName: "numberChildren",
        traitDescription: "# Children",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 157, predefinedTraitResponse: "4+" },
          { predefinedTraitNumber: 156, predefinedTraitResponse: "4" },
          { predefinedTraitNumber: 155, predefinedTraitResponse: "3" },
          { predefinedTraitNumber: 154, predefinedTraitResponse: "2" },
          { predefinedTraitNumber: 153, predefinedTraitResponse: "1" },
          { predefinedTraitNumber: 152, predefinedTraitResponse: "0" },
        ],
      },
      {
        traitId: 435,
        traitName: "UseBrandPads",
        traitDescription: "Use - brand pads",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 185,
            predefinedTraitResponse: "I do not use",
          },
          { predefinedTraitNumber: 179, predefinedTraitResponse: "Deliplus " },
          { predefinedTraitNumber: 180, predefinedTraitResponse: "Ausonia" },
          { predefinedTraitNumber: 184, predefinedTraitResponse: "Others" },
          {
            predefinedTraitNumber: 181,
            predefinedTraitResponse: "Evax Cottonlinke",
          },
          {
            predefinedTraitNumber: 182,
            predefinedTraitResponse: "Evax Fina y Segura",
          },
          {
            predefinedTraitNumber: 183,
            predefinedTraitResponse: "Evax liberty",
          },
          { predefinedTraitNumber: 178, predefinedTraitResponse: "No" },
          { predefinedTraitNumber: 177, predefinedTraitResponse: "Yes" },
        ],
      },
      {
        traitId: 436,
        traitName: "UseBrandPantiliners",
        traitDescription: "Use - brand pantiliners",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 239, predefinedTraitResponse: "Others" },
          { predefinedTraitNumber: 236, predefinedTraitResponse: "Evax" },
          { predefinedTraitNumber: 186, predefinedTraitResponse: "Carefree" },
          {
            predefinedTraitNumber: 240,
            predefinedTraitResponse: "I do not use",
          },
          { predefinedTraitNumber: 238, predefinedTraitResponse: "Ausonia" },
          { predefinedTraitNumber: 237, predefinedTraitResponse: "Deliplus" },
          { predefinedTraitNumber: 184, predefinedTraitResponse: "Others" },
        ],
      },
      {
        traitId: 740,
        traitName: "useFacialMoisturizer",
        traitDescription: "Use - Facial Treatment Essence",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 29, predefinedTraitResponse: "Yes" },
          { predefinedTraitNumber: 30, predefinedTraitResponse: "No" },
        ],
      },
      {
        traitId: 997,
        traitName: "traitPurchaseBeautyProductsIdValue",
        traitDescription: "traitPurchaseBeautyProductsIdValue",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 70, predefinedTraitResponse: "Bodybell" },
          {
            predefinedTraitNumber: 64,
            predefinedTraitResponse: "El Corte Ingles",
          },
          { predefinedTraitNumber: 68, predefinedTraitResponse: "Avenida" },
          {
            predefinedTraitNumber: 69,
            predefinedTraitResponse: "Another Hypermarket",
          },
          { predefinedTraitNumber: 62, predefinedTraitResponse: "Alcampo" },
          { predefinedTraitNumber: 75, predefinedTraitResponse: "Aromas" },
          { predefinedTraitNumber: 76, predefinedTraitResponse: "Recio" },
          { predefinedTraitNumber: 63, predefinedTraitResponse: "Carrefour" },
          { predefinedTraitNumber: 71, predefinedTraitResponse: "Gilgo" },
          { predefinedTraitNumber: 72, predefinedTraitResponse: "Marionnaud" },
          { predefinedTraitNumber: 73, predefinedTraitResponse: "Druni" },
          { predefinedTraitNumber: 67, predefinedTraitResponse: "Pharmacias" },
          { predefinedTraitNumber: 66, predefinedTraitResponse: "Mercadona" },
          { predefinedTraitNumber: 65, predefinedTraitResponse: "Juteco" },
          { predefinedTraitNumber: 74, predefinedTraitResponse: "If" },
        ],
      },
      {
        traitId: 1029,
        traitName: "EstimatedHouseholdSize",
        traitDescription: "Size - Est. Household",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 140, predefinedTraitResponse: "4+" },
          { predefinedTraitNumber: 137, predefinedTraitResponse: "1" },
          { predefinedTraitNumber: 138, predefinedTraitResponse: "2" },
          { predefinedTraitNumber: 139, predefinedTraitResponse: "3" },
        ],
      },
      {
        traitId: 1379,
        traitName: "useBeautyProduct",
        traitDescription: "traitUseBeautyProductIdValue",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 22,
            predefinedTraitResponse: "Cleansing Wipes",
          },
          {
            predefinedTraitNumber: 28,
            predefinedTraitResponse: "SPF Moisturizer",
          },
          {
            predefinedTraitNumber: 27,
            predefinedTraitResponse: "Moisturizing Cream",
          },
          { predefinedTraitNumber: 23, predefinedTraitResponse: "Serum" },
          {
            predefinedTraitNumber: 26,
            predefinedTraitResponse: "Moisturizing Lotion",
          },
          {
            predefinedTraitNumber: 25,
            predefinedTraitResponse: "Targeted Treatment",
          },
          { predefinedTraitNumber: 24, predefinedTraitResponse: "Eye Product" },
          {
            predefinedTraitNumber: 20,
            predefinedTraitResponse: "Cleansing Brush",
          },
          { predefinedTraitNumber: 21, predefinedTraitResponse: "Cleanser" },
        ],
      },
      {
        traitId: 1381,
        traitName: "skinFeel",
        traitDescription: "traitSkinFeelIdValue",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 83,
            predefinedTraitResponse: "Lightly hydrated",
          },
          {
            predefinedTraitNumber: 82,
            predefinedTraitResponse: "Like Nothing is There",
          },
          { predefinedTraitNumber: 84, predefinedTraitResponse: "Light Feel" },
          {
            predefinedTraitNumber: 86,
            predefinedTraitResponse: "Richly moisturized",
          },
          {
            predefinedTraitNumber: 85,
            predefinedTraitResponse: "Rich Deep Feel",
          },
        ],
      },
      {
        traitId: 1382,
        traitName: "scentedProducts",
        traitDescription: "traitScentedProductsIdValue",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 162, predefinedTraitResponse: "dont-care" },
          { predefinedTraitNumber: 161, predefinedTraitResponse: "love" },
          { predefinedTraitNumber: 132, predefinedTraitResponse: "love" },
          { predefinedTraitNumber: 133, predefinedTraitResponse: "dont-care" },
          { predefinedTraitNumber: 134, predefinedTraitResponse: "dont-like" },
          { predefinedTraitNumber: 163, predefinedTraitResponse: "dont-like" },
        ],
      },
      {
        traitId: 1527,
        traitName: "faceWorstZone",
        traitDescription: "traitFaceWorstZoneIdValue",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 117, predefinedTraitResponse: "Mouth" },
          { predefinedTraitNumber: 118, predefinedTraitResponse: "Cheek" },
          { predefinedTraitNumber: 119, predefinedTraitResponse: "Crowsfeet" },
          { predefinedTraitNumber: 120, predefinedTraitResponse: "Forehead" },
          { predefinedTraitNumber: 121, predefinedTraitResponse: "Undereye" },
          { predefinedTraitNumber: 122, predefinedTraitResponse: "Mouth" },
        ],
      },
      {
        traitId: 1528,
        traitName: "faceBestZone",
        traitDescription: "traitFaceBestZoneIdValue",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 116, predefinedTraitResponse: "Undereye" },
          { predefinedTraitNumber: 115, predefinedTraitResponse: "Forehead" },
          { predefinedTraitNumber: 114, predefinedTraitResponse: "Crowsfeet" },
          { predefinedTraitNumber: 112, predefinedTraitResponse: "Mouth" },
          { predefinedTraitNumber: 113, predefinedTraitResponse: "Cheek" },
        ],
      },
      {
        traitId: 1552,
        traitName: "traitBrandShampooIdValue",
        traitDescription: "All the shampoo brands",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 5,
            predefinedTraitResponse: "Herbal Essences",
          },
          { predefinedTraitNumber: 11, predefinedTraitResponse: "Syoss" },
          {
            predefinedTraitNumber: 3,
            predefinedTraitResponse: "Private label",
          },
          {
            predefinedTraitNumber: 100,
            predefinedTraitResponse: "Other professional brand",
          },
          { predefinedTraitNumber: 1, predefinedTraitResponse: "Yes" },
          {
            predefinedTraitNumber: 14,
            predefinedTraitResponse: "Other professional brand",
          },
          { predefinedTraitNumber: 12, predefinedTraitResponse: "Aussie" },
          {
            predefinedTraitNumber: 13,
            predefinedTraitResponse: "Other natural brand",
          },
          {
            predefinedTraitNumber: 4,
            predefinedTraitResponse: "Head&Shoulders",
          },
          { predefinedTraitNumber: 18, predefinedTraitResponse: "Deliplus" },
          { predefinedTraitNumber: 17, predefinedTraitResponse: "Gliss" },
          { predefinedTraitNumber: 16, predefinedTraitResponse: "Pantene" },
          { predefinedTraitNumber: 10, predefinedTraitResponse: "Tresemme" },
          {
            predefinedTraitNumber: 9,
            predefinedTraitResponse: "Original Remedies",
          },
          { predefinedTraitNumber: 8, predefinedTraitResponse: "Elvive" },
          { predefinedTraitNumber: 7, predefinedTraitResponse: "Hair Food" },
          {
            predefinedTraitNumber: 6,
            predefinedTraitResponse: "Garnier Fructis",
          },
          {
            predefinedTraitNumber: 15,
            predefinedTraitResponse: "Other pharmacy brand",
          },
          { predefinedTraitNumber: 19, predefinedTraitResponse: "Others" },
        ],
      },
      {
        traitId: 1662,
        traitName: "UsePadsAtNight",
        traitDescription: "traitUsePadsAtNightIdValue",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 246, predefinedTraitResponse: "Yes" },
          { predefinedTraitNumber: 247, predefinedTraitResponse: "Sometimes" },
          {
            predefinedTraitNumber: 248,
            predefinedTraitResponse: "I do not use",
          },
          {
            predefinedTraitNumber: 249,
            predefinedTraitResponse: "I do not use global",
          },
        ],
      },
      {
        traitId: 2426,
        traitName: "MousturizerBrandUsed",
        traitDescription:
          "Which  mousturizers brand do you use?(Choose all that apply)",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 50, predefinedTraitResponse: "Olay" },
          { predefinedTraitNumber: 51, predefinedTraitResponse: "Others" },
          {
            predefinedTraitNumber: 49,
            predefinedTraitResponse: "Bella Aurora",
          },
          { predefinedTraitNumber: 48, predefinedTraitResponse: "Axe" },
          { predefinedTraitNumber: 47, predefinedTraitResponse: "Nivea" },
        ],
      },
      {
        traitId: 2526,
        traitName: "DCRecommendToothpaste",
        traitDescription: "traitDCRecommendToothpaste",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 57, predefinedTraitResponse: "Don't use" },
          { predefinedTraitNumber: 56, predefinedTraitResponse: "Premium" },
          {
            predefinedTraitNumber: 55,
            predefinedTraitResponse: "Freshly Cosmetics",
          },
          {
            predefinedTraitNumber: 54,
            predefinedTraitResponse: "The ordinary",
          },
          { predefinedTraitNumber: 53, predefinedTraitResponse: "Garnier" },
          { predefinedTraitNumber: 52, predefinedTraitResponse: "L'Oreal" },
        ],
      },
      {
        traitId: 2536,
        traitName: "traitUseBrandConditionerIdValue",
        traitDescription: "Conditioner - brand used",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 102, predefinedTraitResponse: "Pantene" },
          {
            predefinedTraitNumber: 101,
            predefinedTraitResponse: "Other pharmacy brand",
          },
          {
            predefinedTraitNumber: 100,
            predefinedTraitResponse: "Other professional brand",
          },
          {
            predefinedTraitNumber: 99,
            predefinedTraitResponse: "Other natural brand",
          },
          { predefinedTraitNumber: 98, predefinedTraitResponse: "Aussie" },
          { predefinedTraitNumber: 97, predefinedTraitResponse: "Syoss" },
          {
            predefinedTraitNumber: 95,
            predefinedTraitResponse: "Original Remedies",
          },
          { predefinedTraitNumber: 94, predefinedTraitResponse: "Elvive" },
          { predefinedTraitNumber: 93, predefinedTraitResponse: "Hair Food" },
          {
            predefinedTraitNumber: 92,
            predefinedTraitResponse: "Garnier Fructis",
          },
          {
            predefinedTraitNumber: 91,
            predefinedTraitResponse: "Herbal Essences",
          },
          { predefinedTraitNumber: 96, predefinedTraitResponse: "Tresemme" },
          {
            predefinedTraitNumber: 89,
            predefinedTraitResponse: "Private label",
          },
          {
            predefinedTraitNumber: 90,
            predefinedTraitResponse: "Head&Shoulders",
          },
        ],
      },
      {
        traitId: 2581,
        traitName: "facialHairStyle",
        traitDescription: "What is your facial hair style",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 46,
            predefinedTraitResponse: "Not applicable",
          },
          { predefinedTraitNumber: 45, predefinedTraitResponse: "Shave" },
          {
            predefinedTraitNumber: 44,
            predefinedTraitResponse: "Moustache or goatee",
          },
          {
            predefinedTraitNumber: 43,
            predefinedTraitResponse: "Beard of a few days",
          },
          {
            predefinedTraitNumber: 42,
            predefinedTraitResponse: "Beard without mustache",
          },
          {
            predefinedTraitNumber: 41,
            predefinedTraitResponse: "Beard with mustache",
          },
        ],
      },
      {
        traitId: 2595,
        traitName: "deodorantBrandUsed",
        traitDescription:
          "Which deodorant brand do you use? (Choose all that apply)",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 36, predefinedTraitResponse: "Precision 5" },
          { predefinedTraitNumber: 35, predefinedTraitResponse: "Nivea" },
          { predefinedTraitNumber: 37, predefinedTraitResponse: "Rexona" },
          { predefinedTraitNumber: 38, predefinedTraitResponse: "Others" },
          { predefinedTraitNumber: 32, predefinedTraitResponse: "Gillette" },
          { predefinedTraitNumber: 31, predefinedTraitResponse: "Axe" },
          {
            predefinedTraitNumber: 33,
            predefinedTraitResponse: "Private label",
          },
          { predefinedTraitNumber: 34, predefinedTraitResponse: "Mercadona" },
        ],
      },
      {
        traitId: 2597,
        traitName: "traitRegularSleepIssuesIdValue",
        traitDescription: "Do you have regularly sleep issues?",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 158, predefinedTraitResponse: "Yes" },
          { predefinedTraitNumber: 159, predefinedTraitResponse: "Sometimes" },
          { predefinedTraitNumber: 160, predefinedTraitResponse: "No" },
        ],
      },
      {
        traitId: 2600,
        traitName: "traitSleepAidBrandP12MIdValue",
        traitDescription: "Which brand of sleep aid did you use in the P12M?",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 166,
            predefinedTraitResponse: "Aquilea Sueño",
          },
          { predefinedTraitNumber: 167, predefinedTraitResponse: "Dormidina" },
          { predefinedTraitNumber: 168, predefinedTraitResponse: "Dorminatur" },
          { predefinedTraitNumber: 169, predefinedTraitResponse: "Others" },
          {
            predefinedTraitNumber: 170,
            predefinedTraitResponse: "I do not use",
          },
          { predefinedTraitNumber: 164, predefinedTraitResponse: "Valeriana" },
          { predefinedTraitNumber: 165, predefinedTraitResponse: "ZZZQuil" },
        ],
      },
      {
        traitId: 2721,
        traitName: "latitude",
        traitDescription: "Latitude of the consumer provided postal address",
        predefinedTraitResponses: [],
      },
      {
        traitId: 3267,
        traitName: "traitBuyerMercadonaIdValue",
        traitDescription: "traitBuyerMercadonaIdValue",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 135, predefinedTraitResponse: "Yes" },
          { predefinedTraitNumber: 136, predefinedTraitResponse: "No" },
        ],
      },
      {
        traitId: 3596,
        traitName: "deuBrandVenusKnownTrier",
        traitDescription: "scoreDeuBrandVenusKnownTrierValue",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 149,
            predefinedTraitResponse: "I do not use",
          },
          {
            predefinedTraitNumber: 148,
            predefinedTraitResponse: "Occasionally",
          },
          {
            predefinedTraitNumber: 147,
            predefinedTraitResponse: "Whenever I have period",
          },
        ],
      },
      {
        traitId: 3957,
        traitName: "usaMetamucilKnownTrier",
        traitDescription: "Known Metamucil Triers for US PGE/Corporate",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 125,
            predefinedTraitResponse: "Recommended by celebrity / ambassador",
          },
          {
            predefinedTraitNumber: 124,
            predefinedTraitResponse: "Recommended by other consumers",
          },
          {
            predefinedTraitNumber: 123,
            predefinedTraitResponse: "Green packaking",
          },
          {
            predefinedTraitNumber: 127,
            predefinedTraitResponse: "Convey values relevant to me",
          },
          {
            predefinedTraitNumber: 128,
            predefinedTraitResponse: "Natural, organic products",
          },
          {
            predefinedTraitNumber: 126,
            predefinedTraitResponse:
              "Recommended by specialists or prestiguous institutions",
          },
        ],
      },
      {
        traitId: 4250,
        traitName: "phonemanufacturer",
        traitDescription: "phone manufacturer brand or name",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 150, predefinedTraitResponse: "Yes" },
          { predefinedTraitNumber: 151, predefinedTraitResponse: "No" },
        ],
      },
      {
        traitId: 4255,
        traitName: "sasas",
        traitDescription: "sdadasd sad asd sdas",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 178, predefinedTraitResponse: "No" },
          { predefinedTraitNumber: 177, predefinedTraitResponse: "Yes" },
        ],
      },
      {
        traitId: 4256,
        traitName: "headsetbrand",
        traitDescription: "headset brand name details",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 176,
            predefinedTraitResponse: "I do not use",
          },
          { predefinedTraitNumber: 175, predefinedTraitResponse: "Others" },
          {
            predefinedTraitNumber: 174,
            predefinedTraitResponse: "Ausonia Discreet",
          },
          { predefinedTraitNumber: 173, predefinedTraitResponse: "Deliplus" },
          { predefinedTraitNumber: 172, predefinedTraitResponse: "Indasec" },
          { predefinedTraitNumber: 171, predefinedTraitResponse: "Tena" },
        ],
      },
      {
        traitId: 4257,
        traitName: "headsetBrandName",
        traitDescription: "headset brand name details",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 40, predefinedTraitResponse: "No" },
          { predefinedTraitNumber: 39, predefinedTraitResponse: "Yes" },
        ],
      },
      {
        traitId: 4258,
        traitName: "headsetBrandModel",
        traitDescription: "headset brand model details",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 87, predefinedTraitResponse: "Yes" },
          { predefinedTraitNumber: 88, predefinedTraitResponse: "No" },
        ],
      },
      {
        traitId: 4259,
        traitName: "testerTra",
        traitDescription: "testing trait creation flow with sample values",
        predefinedTraitResponses: [
          { predefinedTraitNumber: 1, predefinedTraitResponse: "Yes" },
          { predefinedTraitNumber: 2, predefinedTraitResponse: "No" },
        ],
      },
      {
        traitId: 4417,
        traitName: "emailIndiaGoldenHouseholdsdfsdfsdfsdOptIndicator",
        traitDescription:
          "Opt indicator trait for Email Address channel for IND Golden Household",
        predefinedTraitResponses: [
          {
            predefinedTraitNumber: 130,
            predefinedTraitResponse:
              "This year I have used more than 2 different face cream brands",
          },
          {
            predefinedTraitNumber: 131,
            predefinedTraitResponse: "This year I have used 1 face cream brand",
          },
          {
            predefinedTraitNumber: 129,
            predefinedTraitResponse:
              "This year I have used 2 different face cream brands",
          },
          { predefinedTraitNumber: 134, predefinedTraitResponse: "dont-like" },
          { predefinedTraitNumber: 133, predefinedTraitResponse: "dont-care" },
          { predefinedTraitNumber: 132, predefinedTraitResponse: "love" },
        ],
      },
      {
        traitId: 5236,
        traitName: "BrushTechnology",
        traitDescription:
          "Gives us info on what technology of the brush a consumer has, one level down,\n\ni.e. Manual/OralB OR/OralB iO/Competitor/Battery",
        predefinedTraitResponses: [],
      },
    ],
    totalItems: 39,
    page: 1,
    itemsPerPage: 50,
  },
});

const getCreateTraitResponse = (global) =>
  global
    ? {
        apiVersion: "2.14.0",
        data: {
          items: [
            {
              requestId: 1117,
              errorMessage:
                'CALL consumer_data_dictionary.update_request(1117, \'approved\', \'gopal.n.1\', \'Auto Approved\', \'{\\"items\\":[{\\"marketingProgramPredefinedTraitResponseMapId\\":243,\\"new\\":true,\\"traitId\\":28,\\"marketingProgramNumber\\":290,\\"language\\":\\"Russian\\",\\"languageCode\\":\\"ru\\",\\"traitResponse\\":\\"RRR\\",\\"predefinedTraitNumber\\":255,\\"globalStandardResponse\\":\\"Army\\",\\"traitResponseLocalLanguage\\":\\"RRR\\"}],\\"warnings\\":[]}\'); - ER_SP_WRONG_NO_OF_ARGS: Incorrect number of arguments for PROCEDURE consumer_data_dictionary.update_request; expected 6, got 5',
              trait: {
                traitId: 28,
                traitName: "selfDescriptionSkin",
                traitDescription: "Self-description - skin type",
              },
              marketingProgram: {
                marketingProgramNumber: 290,
                description: "ESP Golden Household",
              },
              traitResponses: [
                {
                  traitResponse: "RRR",
                  traitResponseLocalLanguage: "RRR",
                  languageCode: "ru",
                  language: "Russian",
                  globalStandardResponse: "Army",
                  predefinedTraitNumber: 255,
                },
              ],
              status: "pending",
            },
          ],
        },
      }
    : {
        apiVersion: "1.9.2",
        data: {
          items: [
            {
              requestId: 1104,
              name: "selfDescriptionSkin",
              module: "traits",
              requestDate: "2022-09-14T17:47:07.000Z",
              status: "approved",
              requestedBy: "developer.approve",
              reviewedBy: "developer.approve",
              comments: "Auto Approved",
              output: {
                items: [
                  {
                    new: true,
                    traitId: 28,
                    language: "Russian",
                    languageCode: "ru",
                    traitResponse: "test",
                    marketingProgramNumber: 1,
                    traitResponseLocalLanguage: "тес",
                    marketingProgramPredefinedTraitResponseMapId: 236,
                  },
                  {
                    new: true,
                    traitId: 28,
                    language: "Chinese",
                    languageCode: "zh",
                    traitResponse: "test",
                    predefinedTraitNumber: 250,
                    globalStandardResponse: "Grapes",
                    marketingProgramNumber: 1,
                    traitResponseLocalLanguage: "測試",
                    marketingProgramPredefinedTraitResponseMapId: 237,
                  },
                ],
                warnings: [],
              },
              trait: {
                traitId: 28,
                traitName: "selfDescriptionSkin",
                traitDescription: "Self-description - skin type",
              },
              traitResponses: [
                {
                  language: "Russian",
                  languageCode: "ru",
                  traitResponse: "test",
                  traitResponseLocalLanguage: "тес",
                },
                {
                  language: "Chinese",
                  languageCode: "zh",
                  traitResponse: "test",
                  predefinedTraitNumber: 250,
                  globalStandardResponse: "Grapes",
                  traitResponseLocalLanguage: "測試",
                },
              ],
              marketingProgram: {
                description: "IND Golden Household",
                marketingProgramName: "GHH-IND-1",
                marketingProgramNumber: 1,
              },
              geoRegionCodes: ["AMA"],
            },
          ],
        },
      };

const getTraitsResponsesDetails = (_opts) => {
  const opts = _opts || {
    perPage: 48,
    startIndexOffset: 0,
  };

  const items = Array.from({ length: opts.perPage }).map((v, i) => ({
    traitId: opts.startIndexOffset ? i + 1 + opts.startIndexOffset : i + 1,
    traitName: `test-${i}`,
    traitDescription: `test - gen-${i}`,
    predefinedTraitResponses: [
      {
        predefinedTraitNumber: 1,
        predefinedTraitResponse: `Normal`,
      },
    ],
  }));

  return {
    apiVersion: "2.18.1",
    data: {
      items: [
        {
          traitId: 1,
          traitName: "childrenIdValue",
          traitDescription: "Children",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 307, predefinedTraitResponse: "Two" },
            { predefinedTraitNumber: 314, predefinedTraitResponse: "Three" },
            { predefinedTraitNumber: 306, predefinedTraitResponse: "One" },
            { predefinedTraitNumber: 341, predefinedTraitResponse: "Four" },
          ],
        },
        {
          traitId: 2,
          traitName: "referral",
          traitDescription: "Referral",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 309,
              predefinedTraitResponse: "Ember Spirit",
            },
            {
              predefinedTraitNumber: 297,
              predefinedTraitResponse: "Earth Spirit",
            },
            {
              predefinedTraitNumber: 295,
              predefinedTraitResponse: "Storm Spirit",
            },
            {
              predefinedTraitNumber: 312,
              predefinedTraitResponse: "new global",
            },
            { predefinedTraitNumber: 311, predefinedTraitResponse: "test1" },
            {
              predefinedTraitNumber: 310,
              predefinedTraitResponse: "GlobalResponse",
            },
            {
              predefinedTraitNumber: 298,
              predefinedTraitResponse: "Void spirit",
            },
            {
              predefinedTraitNumber: 299,
              predefinedTraitResponse: "new spirit",
            },
          ],
        },
        {
          traitId: 18,
          traitName: "traitUseBrandGroomingIdValue",
          traitDescription: "Use - brand grooming",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 328, predefinedTraitResponse: "Kill" },
            { predefinedTraitNumber: 285, predefinedTraitResponse: "Mac2" },
            { predefinedTraitNumber: 286, predefinedTraitResponse: "Dee" },
            {
              predefinedTraitNumber: 300,
              predefinedTraitResponse: "new response",
            },
            { predefinedTraitNumber: 325, predefinedTraitResponse: "Army" },
          ],
        },
        {
          traitId: 28,
          traitName: "selfDescriptionSkin",
          traitDescription: "Self-description - skin type",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 80, predefinedTraitResponse: "Sensitive" },
            {
              predefinedTraitNumber: 79,
              predefinedTraitResponse: "Mostly dry",
            },
            {
              predefinedTraitNumber: 81,
              predefinedTraitResponse: "Combination",
            },
            { predefinedTraitNumber: 250, predefinedTraitResponse: "Grapes" },
            { predefinedTraitNumber: 278, predefinedTraitResponse: "Applesu" },
            { predefinedTraitNumber: 277, predefinedTraitResponse: "Jest" },
            { predefinedTraitNumber: 252, predefinedTraitResponse: "Cat" },
            { predefinedTraitNumber: 253, predefinedTraitResponse: "Jump" },
            { predefinedTraitNumber: 270, predefinedTraitResponse: "Checking" },
            { predefinedTraitNumber: 269, predefinedTraitResponse: "tester" },
            { predefinedTraitNumber: 268, predefinedTraitResponse: "Jack" },
            { predefinedTraitNumber: 267, predefinedTraitResponse: "Queen" },
            { predefinedTraitNumber: 266, predefinedTraitResponse: "King" },
            { predefinedTraitNumber: 265, predefinedTraitResponse: "Hot" },
            { predefinedTraitNumber: 264, predefinedTraitResponse: "Cold" },
            { predefinedTraitNumber: 263, predefinedTraitResponse: "Dry" },
            { predefinedTraitNumber: 262, predefinedTraitResponse: "Wet" },
            { predefinedTraitNumber: 261, predefinedTraitResponse: "Rocket" },
            { predefinedTraitNumber: 260, predefinedTraitResponse: "Vampire" },
            { predefinedTraitNumber: 259, predefinedTraitResponse: "Olive" },
            { predefinedTraitNumber: 258, predefinedTraitResponse: "Plum" },
            { predefinedTraitNumber: 257, predefinedTraitResponse: "Beans" },
            { predefinedTraitNumber: 256, predefinedTraitResponse: "Den" },
            { predefinedTraitNumber: 255, predefinedTraitResponse: "Army" },
            { predefinedTraitNumber: 254, predefinedTraitResponse: "Hill" },
            {
              predefinedTraitNumber: 319,
              predefinedTraitResponse: "new global",
            },
            {
              predefinedTraitNumber: 78,
              predefinedTraitResponse: "Mostly oily",
            },
            { predefinedTraitNumber: 77, predefinedTraitResponse: "Normal" },
          ],
        },
        {
          traitId: 31,
          traitName: "UseHairCareProduct",
          traitDescription: "traitUseHairCareProductIdValue",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 275, predefinedTraitResponse: "tester" },
            { predefinedTraitNumber: 320, predefinedTraitResponse: "Change" },
            { predefinedTraitNumber: 276, predefinedTraitResponse: "worker" },
          ],
        },
        {
          traitId: 51,
          traitName: "concernsSkin",
          traitDescription: "Concerns - Skin",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 104,
              predefinedTraitResponse: "Sagging Skin",
            },
            {
              predefinedTraitNumber: 103,
              predefinedTraitResponse: "Wrinkles and Fine Lines",
            },
            {
              predefinedTraitNumber: 105,
              predefinedTraitResponse: "Uneven Skin Tone",
            },
            { predefinedTraitNumber: 106, predefinedTraitResponse: "Dullness" },
            {
              predefinedTraitNumber: 107,
              predefinedTraitResponse: "Visible Pores",
            },
            { predefinedTraitNumber: 108, predefinedTraitResponse: "Acne" },
            {
              predefinedTraitNumber: 109,
              predefinedTraitResponse: "Aging-eyes",
            },
            {
              predefinedTraitNumber: 110,
              predefinedTraitResponse: "Dark Circles",
            },
            {
              predefinedTraitNumber: 111,
              predefinedTraitResponse: "Puffy Eyes",
            },
          ],
        },
        {
          traitId: 77,
          traitName: "myWrinklesAre",
          traitDescription: "Skin concern - wrinkles",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 58,
              predefinedTraitResponse: "Fine Lines",
            },
            {
              predefinedTraitNumber: 59,
              predefinedTraitResponse: "Barely there",
            },
            {
              predefinedTraitNumber: 60,
              predefinedTraitResponse: "Definitely there!",
            },
            {
              predefinedTraitNumber: 61,
              predefinedTraitResponse: "Expression Lines",
            },
            {
              predefinedTraitNumber: 313,
              predefinedTraitResponse: "New Global Test 1",
            },
          ],
        },
        {
          traitId: 98,
          traitName: "femcareProductUsed",
          traitDescription: "Use - femcare product",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 145,
              predefinedTraitResponse: "Pantyliners",
            },
            {
              predefinedTraitNumber: 141,
              predefinedTraitResponse: "Menstrual Cup",
            },
            {
              predefinedTraitNumber: 142,
              predefinedTraitResponse: "Menstrual Panties",
            },
            { predefinedTraitNumber: 143, predefinedTraitResponse: "Pads" },
            { predefinedTraitNumber: 144, predefinedTraitResponse: "Tampons" },
            { predefinedTraitNumber: 146, predefinedTraitResponse: "Others" },
          ],
        },
        {
          traitId: 99,
          traitName: "UseBrandTampon",
          traitDescription: "Use - brand tampon",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 242, predefinedTraitResponse: "OB" },
            { predefinedTraitNumber: 243, predefinedTraitResponse: "Deliplus" },
            {
              predefinedTraitNumber: 245,
              predefinedTraitResponse: "I do not use",
            },
            { predefinedTraitNumber: 244, predefinedTraitResponse: "Others" },
            { predefinedTraitNumber: 241, predefinedTraitResponse: "Tampax" },
          ],
        },
        {
          traitId: 129,
          traitName: "numberChildren",
          traitDescription: "# Children",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 157, predefinedTraitResponse: "4+" },
            { predefinedTraitNumber: 156, predefinedTraitResponse: "4" },
            { predefinedTraitNumber: 155, predefinedTraitResponse: "3" },
            { predefinedTraitNumber: 154, predefinedTraitResponse: "2" },
            { predefinedTraitNumber: 153, predefinedTraitResponse: "1" },
            { predefinedTraitNumber: 152, predefinedTraitResponse: "0" },
          ],
        },
        {
          traitId: 435,
          traitName: "UseBrandPads",
          traitDescription: "Use - brand pads",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 185,
              predefinedTraitResponse: "I do not use",
            },
            {
              predefinedTraitNumber: 179,
              predefinedTraitResponse: "Deliplus ",
            },
            { predefinedTraitNumber: 180, predefinedTraitResponse: "Ausonia" },
            { predefinedTraitNumber: 184, predefinedTraitResponse: "Others" },
            {
              predefinedTraitNumber: 181,
              predefinedTraitResponse: "Evax Cottonlinke",
            },
            {
              predefinedTraitNumber: 182,
              predefinedTraitResponse: "Evax Fina y Segura",
            },
            {
              predefinedTraitNumber: 183,
              predefinedTraitResponse: "Evax liberty",
            },
            { predefinedTraitNumber: 178, predefinedTraitResponse: "No" },
            { predefinedTraitNumber: 177, predefinedTraitResponse: "Yes" },
          ],
        },
        {
          traitId: 436,
          traitName: "UseBrandPantiliners",
          traitDescription: "Use - brand pantiliners",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 239, predefinedTraitResponse: "Others" },
            { predefinedTraitNumber: 236, predefinedTraitResponse: "Evax" },
            { predefinedTraitNumber: 186, predefinedTraitResponse: "Carefree" },
            {
              predefinedTraitNumber: 240,
              predefinedTraitResponse: "I do not use",
            },
            { predefinedTraitNumber: 238, predefinedTraitResponse: "Ausonia" },
            { predefinedTraitNumber: 237, predefinedTraitResponse: "Deliplus" },
            { predefinedTraitNumber: 184, predefinedTraitResponse: "Others" },
          ],
        },
        {
          traitId: 740,
          traitName: "useFacialMoisturizer",
          traitDescription: "Use - Facial Treatment Essence",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 29, predefinedTraitResponse: "Yes" },
            { predefinedTraitNumber: 30, predefinedTraitResponse: "No" },
          ],
        },
        {
          traitId: 997,
          traitName: "traitPurchaseBeautyProductsIdValue",
          traitDescription: "traitPurchaseBeautyProductsIdValue",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 70, predefinedTraitResponse: "Bodybell" },
            {
              predefinedTraitNumber: 64,
              predefinedTraitResponse: "El Corte Ingles",
            },
            { predefinedTraitNumber: 68, predefinedTraitResponse: "Avenida" },
            {
              predefinedTraitNumber: 69,
              predefinedTraitResponse: "Another Hypermarket",
            },
            { predefinedTraitNumber: 62, predefinedTraitResponse: "Alcampo" },
            { predefinedTraitNumber: 75, predefinedTraitResponse: "Aromas" },
            { predefinedTraitNumber: 76, predefinedTraitResponse: "Recio" },
            { predefinedTraitNumber: 63, predefinedTraitResponse: "Carrefour" },
            { predefinedTraitNumber: 71, predefinedTraitResponse: "Gilgo" },
            {
              predefinedTraitNumber: 72,
              predefinedTraitResponse: "Marionnaud",
            },
            { predefinedTraitNumber: 73, predefinedTraitResponse: "Druni" },
            {
              predefinedTraitNumber: 67,
              predefinedTraitResponse: "Pharmacias",
            },
            { predefinedTraitNumber: 66, predefinedTraitResponse: "Mercadona" },
            { predefinedTraitNumber: 65, predefinedTraitResponse: "Juteco" },
            { predefinedTraitNumber: 74, predefinedTraitResponse: "If" },
          ],
        },
        {
          traitId: 1029,
          traitName: "EstimatedHouseholdSize",
          traitDescription: "Size - Est. Household",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 140, predefinedTraitResponse: "4+" },
            { predefinedTraitNumber: 137, predefinedTraitResponse: "1" },
            { predefinedTraitNumber: 138, predefinedTraitResponse: "2" },
            { predefinedTraitNumber: 139, predefinedTraitResponse: "3" },
          ],
        },
        {
          traitId: 1379,
          traitName: "useBeautyProduct",
          traitDescription: "traitUseBeautyProductIdValue",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 22,
              predefinedTraitResponse: "Cleansing Wipes",
            },
            {
              predefinedTraitNumber: 28,
              predefinedTraitResponse: "SPF Moisturizer",
            },
            {
              predefinedTraitNumber: 27,
              predefinedTraitResponse: "Moisturizing Cream",
            },
            { predefinedTraitNumber: 23, predefinedTraitResponse: "Serum" },
            {
              predefinedTraitNumber: 26,
              predefinedTraitResponse: "Moisturizing Lotion",
            },
            {
              predefinedTraitNumber: 25,
              predefinedTraitResponse: "Targeted Treatment",
            },
            {
              predefinedTraitNumber: 24,
              predefinedTraitResponse: "Eye Product",
            },
            {
              predefinedTraitNumber: 20,
              predefinedTraitResponse: "Cleansing Brush",
            },
            { predefinedTraitNumber: 21, predefinedTraitResponse: "Cleanser" },
          ],
        },
        {
          traitId: 1381,
          traitName: "skinFeel",
          traitDescription: "traitSkinFeelIdValue",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 83,
              predefinedTraitResponse: "Lightly hydrated",
            },
            {
              predefinedTraitNumber: 82,
              predefinedTraitResponse: "Like Nothing is There",
            },
            {
              predefinedTraitNumber: 84,
              predefinedTraitResponse: "Light Feel",
            },
            {
              predefinedTraitNumber: 86,
              predefinedTraitResponse: "Richly moisturized",
            },
            {
              predefinedTraitNumber: 85,
              predefinedTraitResponse: "Rich Deep Feel",
            },
          ],
        },
        {
          traitId: 1382,
          traitName: "scentedProducts",
          traitDescription: "traitScentedProductsIdValue",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 162,
              predefinedTraitResponse: "dont-care",
            },
            { predefinedTraitNumber: 161, predefinedTraitResponse: "love" },
            { predefinedTraitNumber: 132, predefinedTraitResponse: "love" },
            {
              predefinedTraitNumber: 133,
              predefinedTraitResponse: "dont-care",
            },
            {
              predefinedTraitNumber: 134,
              predefinedTraitResponse: "dont-like",
            },
            {
              predefinedTraitNumber: 163,
              predefinedTraitResponse: "dont-like",
            },
          ],
        },
        {
          traitId: 1527,
          traitName: "faceWorstZone",
          traitDescription: "traitFaceWorstZoneIdValue",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 117, predefinedTraitResponse: "Mouth" },
            { predefinedTraitNumber: 118, predefinedTraitResponse: "Cheek" },
            {
              predefinedTraitNumber: 119,
              predefinedTraitResponse: "Crowsfeet",
            },
            { predefinedTraitNumber: 120, predefinedTraitResponse: "Forehead" },
            { predefinedTraitNumber: 121, predefinedTraitResponse: "Undereye" },
            { predefinedTraitNumber: 122, predefinedTraitResponse: "Mouth" },
          ],
        },
        {
          traitId: 1528,
          traitName: "faceBestZone",
          traitDescription: "traitFaceBestZoneIdValue",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 116, predefinedTraitResponse: "Undereye" },
            { predefinedTraitNumber: 115, predefinedTraitResponse: "Forehead" },
            {
              predefinedTraitNumber: 114,
              predefinedTraitResponse: "Crowsfeet",
            },
            { predefinedTraitNumber: 112, predefinedTraitResponse: "Mouth" },
            { predefinedTraitNumber: 113, predefinedTraitResponse: "Cheek" },
          ],
        },
        {
          traitId: 1552,
          traitName: "traitBrandShampooIdValue",
          traitDescription: "All the shampoo brands",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 5,
              predefinedTraitResponse: "Herbal Essences",
            },
            { predefinedTraitNumber: 11, predefinedTraitResponse: "Syoss" },
            {
              predefinedTraitNumber: 3,
              predefinedTraitResponse: "Private label",
            },
            {
              predefinedTraitNumber: 100,
              predefinedTraitResponse: "Other professional brand",
            },
            { predefinedTraitNumber: 1, predefinedTraitResponse: "Yes" },
            {
              predefinedTraitNumber: 14,
              predefinedTraitResponse: "Other professional brand",
            },
            { predefinedTraitNumber: 12, predefinedTraitResponse: "Aussie" },
            {
              predefinedTraitNumber: 13,
              predefinedTraitResponse: "Other natural brand",
            },
            {
              predefinedTraitNumber: 4,
              predefinedTraitResponse: "Head&Shoulders",
            },
            { predefinedTraitNumber: 18, predefinedTraitResponse: "Deliplus" },
            { predefinedTraitNumber: 17, predefinedTraitResponse: "Gliss" },
            { predefinedTraitNumber: 16, predefinedTraitResponse: "Pantene" },
            { predefinedTraitNumber: 10, predefinedTraitResponse: "Tresemme" },
            {
              predefinedTraitNumber: 9,
              predefinedTraitResponse: "Original Remedies",
            },
            { predefinedTraitNumber: 8, predefinedTraitResponse: "Elvive" },
            { predefinedTraitNumber: 7, predefinedTraitResponse: "Hair Food" },
            {
              predefinedTraitNumber: 6,
              predefinedTraitResponse: "Garnier Fructis",
            },
            {
              predefinedTraitNumber: 15,
              predefinedTraitResponse: "Other pharmacy brand",
            },
            { predefinedTraitNumber: 19, predefinedTraitResponse: "Others" },
          ],
        },
        {
          traitId: 1662,
          traitName: "UsePadsAtNight",
          traitDescription: "traitUsePadsAtNightIdValue",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 246, predefinedTraitResponse: "Yes" },
            {
              predefinedTraitNumber: 247,
              predefinedTraitResponse: "Sometimes",
            },
            {
              predefinedTraitNumber: 248,
              predefinedTraitResponse: "I do not use",
            },
            {
              predefinedTraitNumber: 249,
              predefinedTraitResponse: "I do not use global",
            },
          ],
        },
        {
          traitId: 2426,
          traitName: "MousturizerBrandUsed",
          traitDescription:
            "Which  mousturizers brand do you use?(Choose all that apply)",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 50, predefinedTraitResponse: "Olay" },
            { predefinedTraitNumber: 51, predefinedTraitResponse: "Others" },
            {
              predefinedTraitNumber: 49,
              predefinedTraitResponse: "Bella Aurora",
            },
            { predefinedTraitNumber: 48, predefinedTraitResponse: "Axe" },
            { predefinedTraitNumber: 47, predefinedTraitResponse: "Nivea" },
          ],
        },
        {
          traitId: 2526,
          traitName: "DCRecommendToothpaste",
          traitDescription: "traitDCRecommendToothpaste",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 57, predefinedTraitResponse: "Don't use" },
            { predefinedTraitNumber: 56, predefinedTraitResponse: "Premium" },
            {
              predefinedTraitNumber: 55,
              predefinedTraitResponse: "Freshly Cosmetics",
            },
            {
              predefinedTraitNumber: 54,
              predefinedTraitResponse: "The ordinary",
            },
            { predefinedTraitNumber: 53, predefinedTraitResponse: "Garnier" },
            { predefinedTraitNumber: 52, predefinedTraitResponse: "L'Oreal" },
          ],
        },
        {
          traitId: 2536,
          traitName: "traitUseBrandConditionerIdValue",
          traitDescription: "Conditioner - brand used",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 102, predefinedTraitResponse: "Pantene" },
            {
              predefinedTraitNumber: 101,
              predefinedTraitResponse: "Other pharmacy brand",
            },
            {
              predefinedTraitNumber: 100,
              predefinedTraitResponse: "Other professional brand",
            },
            {
              predefinedTraitNumber: 99,
              predefinedTraitResponse: "Other natural brand",
            },
            { predefinedTraitNumber: 98, predefinedTraitResponse: "Aussie" },
            { predefinedTraitNumber: 97, predefinedTraitResponse: "Syoss" },
            {
              predefinedTraitNumber: 95,
              predefinedTraitResponse: "Original Remedies",
            },
            { predefinedTraitNumber: 94, predefinedTraitResponse: "Elvive" },
            { predefinedTraitNumber: 93, predefinedTraitResponse: "Hair Food" },
            {
              predefinedTraitNumber: 92,
              predefinedTraitResponse: "Garnier Fructis",
            },
            {
              predefinedTraitNumber: 91,
              predefinedTraitResponse: "Herbal Essences",
            },
            { predefinedTraitNumber: 96, predefinedTraitResponse: "Tresemme" },
            {
              predefinedTraitNumber: 89,
              predefinedTraitResponse: "Private label",
            },
            {
              predefinedTraitNumber: 90,
              predefinedTraitResponse: "Head&Shoulders",
            },
          ],
        },
        {
          traitId: 2581,
          traitName: "facialHairStyle",
          traitDescription: "What is your facial hair style",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 46,
              predefinedTraitResponse: "Not applicable",
            },
            { predefinedTraitNumber: 45, predefinedTraitResponse: "Shave" },
            {
              predefinedTraitNumber: 44,
              predefinedTraitResponse: "Moustache or goatee",
            },
            {
              predefinedTraitNumber: 43,
              predefinedTraitResponse: "Beard of a few days",
            },
            {
              predefinedTraitNumber: 42,
              predefinedTraitResponse: "Beard without mustache",
            },
            {
              predefinedTraitNumber: 41,
              predefinedTraitResponse: "Beard with mustache",
            },
          ],
        },
        {
          traitId: 2595,
          traitName: "deodorantBrandUsed",
          traitDescription:
            "Which deodorant brand do you use? (Choose all that apply)",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 36,
              predefinedTraitResponse: "Precision 5",
            },
            { predefinedTraitNumber: 35, predefinedTraitResponse: "Nivea" },
            { predefinedTraitNumber: 37, predefinedTraitResponse: "Rexona" },
            { predefinedTraitNumber: 38, predefinedTraitResponse: "Others" },
            { predefinedTraitNumber: 32, predefinedTraitResponse: "Gillette" },
            { predefinedTraitNumber: 31, predefinedTraitResponse: "Axe" },
            {
              predefinedTraitNumber: 33,
              predefinedTraitResponse: "Private label",
            },
            { predefinedTraitNumber: 34, predefinedTraitResponse: "Mercadona" },
          ],
        },
        {
          traitId: 2597,
          traitName: "traitRegularSleepIssuesIdValue",
          traitDescription: "Do you have regularly sleep issues?",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 158, predefinedTraitResponse: "Yes" },
            {
              predefinedTraitNumber: 159,
              predefinedTraitResponse: "Sometimes",
            },
            { predefinedTraitNumber: 160, predefinedTraitResponse: "No" },
          ],
        },
        {
          traitId: 2600,
          traitName: "traitSleepAidBrandP12MIdValue",
          traitDescription: "Which brand of sleep aid did you use in the P12M?",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 166,
              predefinedTraitResponse: "Aquilea Sueño",
            },
            {
              predefinedTraitNumber: 167,
              predefinedTraitResponse: "Dormidina",
            },
            {
              predefinedTraitNumber: 168,
              predefinedTraitResponse: "Dorminatur",
            },
            { predefinedTraitNumber: 169, predefinedTraitResponse: "Others" },
            {
              predefinedTraitNumber: 170,
              predefinedTraitResponse: "I do not use",
            },
            {
              predefinedTraitNumber: 164,
              predefinedTraitResponse: "Valeriana",
            },
            { predefinedTraitNumber: 165, predefinedTraitResponse: "ZZZQuil" },
          ],
        },
        {
          traitId: 2721,
          traitName: "latitude",
          traitDescription: "Latitude of the consumer provided postal address",
          predefinedTraitResponses: [],
        },
        {
          traitId: 3267,
          traitName: "traitBuyerMercadonaIdValue",
          traitDescription: "traitBuyerMercadonaIdValue",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 135, predefinedTraitResponse: "Yes" },
            { predefinedTraitNumber: 136, predefinedTraitResponse: "No" },
          ],
        },
        {
          traitId: 3596,
          traitName: "deuBrandVenusKnownTrier",
          traitDescription: "scoreDeuBrandVenusKnownTrierValue",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 149,
              predefinedTraitResponse: "I do not use",
            },
            {
              predefinedTraitNumber: 148,
              predefinedTraitResponse: "Occasionally",
            },
            {
              predefinedTraitNumber: 147,
              predefinedTraitResponse: "Whenever I have period",
            },
          ],
        },
        {
          traitId: 3957,
          traitName: "usaMetamucilKnownTrier",
          traitDescription: "Known Metamucil Triers for US PGE/Corporate",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 125,
              predefinedTraitResponse: "Recommended by celebrity / ambassador",
            },
            {
              predefinedTraitNumber: 124,
              predefinedTraitResponse: "Recommended by other consumers",
            },
            {
              predefinedTraitNumber: 123,
              predefinedTraitResponse: "Green packaking",
            },
            {
              predefinedTraitNumber: 127,
              predefinedTraitResponse: "Convey values relevant to me",
            },
            {
              predefinedTraitNumber: 128,
              predefinedTraitResponse: "Natural, organic products",
            },
            {
              predefinedTraitNumber: 126,
              predefinedTraitResponse:
                "Recommended by specialists or prestiguous institutions",
            },
          ],
        },
        {
          traitId: 4250,
          traitName: "phonemanufacturer",
          traitDescription: "phone manufacturer brand or name",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 150, predefinedTraitResponse: "Yes" },
            { predefinedTraitNumber: 151, predefinedTraitResponse: "No" },
          ],
        },
        {
          traitId: 4255,
          traitName: "sasas",
          traitDescription: "sdadasd sad asd sdas",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 178, predefinedTraitResponse: "No" },
            { predefinedTraitNumber: 177, predefinedTraitResponse: "Yes" },
          ],
        },
        {
          traitId: 4256,
          traitName: "headsetbrand",
          traitDescription: "headset brand name details",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 176,
              predefinedTraitResponse: "I do not use",
            },
            { predefinedTraitNumber: 175, predefinedTraitResponse: "Others" },
            {
              predefinedTraitNumber: 174,
              predefinedTraitResponse: "Ausonia Discreet",
            },
            { predefinedTraitNumber: 173, predefinedTraitResponse: "Deliplus" },
            { predefinedTraitNumber: 172, predefinedTraitResponse: "Indasec" },
            { predefinedTraitNumber: 171, predefinedTraitResponse: "Tena" },
          ],
        },
        {
          traitId: 4257,
          traitName: "headsetBrandName",
          traitDescription: "headset brand name details",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 40, predefinedTraitResponse: "No" },
            { predefinedTraitNumber: 39, predefinedTraitResponse: "Yes" },
          ],
        },
        {
          traitId: 4258,
          traitName: "headsetBrandModel",
          traitDescription: "headset brand model details",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 87, predefinedTraitResponse: "Yes" },
            { predefinedTraitNumber: 88, predefinedTraitResponse: "No" },
          ],
        },
        {
          traitId: 4259,
          traitName: "testerTra",
          traitDescription: "testing trait creation flow with sample values",
          predefinedTraitResponses: [
            { predefinedTraitNumber: 1, predefinedTraitResponse: "Yes" },
            { predefinedTraitNumber: 2, predefinedTraitResponse: "No" },
          ],
        },
        {
          traitId: 4417,
          traitName: "emailIndiaGoldenHouseholdsdfsdfsdfsdOptIndicator",
          traitDescription:
            "Opt indicator trait for Email Address channel for IND Golden Household",
          predefinedTraitResponses: [
            {
              predefinedTraitNumber: 130,
              predefinedTraitResponse:
                "This year I have used more than 2 different face cream brands",
            },
            {
              predefinedTraitNumber: 131,
              predefinedTraitResponse:
                "This year I have used 1 face cream brand",
            },
            {
              predefinedTraitNumber: 129,
              predefinedTraitResponse:
                "This year I have used 2 different face cream brands",
            },
            {
              predefinedTraitNumber: 134,
              predefinedTraitResponse: "dont-like",
            },
            {
              predefinedTraitNumber: 133,
              predefinedTraitResponse: "dont-care",
            },
            { predefinedTraitNumber: 132, predefinedTraitResponse: "love" },
          ],
        },
        {
          traitId: 5236,
          traitName: "BrushTechnology",
          traitDescription:
            "Gives us info on what technology of the brush a consumer has, one level down,\n\ni.e. Manual/OralB OR/OralB iO/Competitor/Battery",
          predefinedTraitResponses: [],
        },
        ...items,
      ],
      totalItems: 39 + items.length,
      page: 1,
      itemsPerPage: opts.perPage,
    },
  };
};

const getDummyDataModalApiResponse = () => {
  return {
    field1: "field1Val",
    field2: "field2Val",
    field3: "field3Val",
  };
};

const getDummyDataApiResponse = () => {
  return {
    items: [
      {
        field1: "field1Val",
        field2: "field2Val",
      },
      {
        field1: "field1Val",
        field2: "field2Val",
      },
      {
        field1: "field1Val",
        field2: "field2Val",
      },
    ],
    totalItems: 5,
    page: 1,
    itemsPerPage: 5,
  };
};

export default {
  getTraitEcosystemsApiResponse,
  getTraitMarketingProgramsApiResponse,
  getSourceMarketingProgramsApiResponse,
  getTraitsApiResponse,
  getSourcesApiResponse,
  getSourcesRequestsApiResponse,
  getMySourceRequestDetailsApiResponse,
  getMySegmentSourceRequestDetailsApiResponse,
  getSourceEcosystemsApiResponse,
  getTraitsApiErrorResponse,
  getNetworkErrorResponse,
  getMarketingProgramsApiResponse,
  getSearchMarketingProgramsApiResponse,
  getMarketingProgramsEcosystemsApiResponse,
  getMarketingProgramOptsApiResponse,
  getServiceNamesApiResponse,
  createOptMappingApiResponse,
  getEcosystemsApiResponse,
  getLegalEntitiesApiResponse,
  getTraitsRequestsApiResponse,
  getTraitRequestDetailsApiResponse,
  getMyRequestDetailsApiResponse,
  getUseCaseRequestDetailsApiResponse,
  getTraitResponseDetailsWithOutput,
  getTraitResponseDetailsApiResponse,
  getOptMappingRequestDetailsApiResponse,
  getUpdatedStatusApiResponse,
  createTraitRequestResponse,
  createSourceRequestApiResponse,
  createSegmentSourceRequestApiResponse,
  createMarketingProgramsEcosystemsApiResponse,
  createUseCaseApiResponse,
  getOptsApiResponse,
  getOptRequestDetailsApiResponse,
  getOptRequestDetailsWithMarketingProgramOtps,
  getOptVersionsResponse,
  getOptTextScopeMappingResponse,
  getOptTraitMappingsResponse,
  getOptMappingsApiResponse,
  getOptsRequestsApiResponse,
  getOptsRequestsResponse,
  getOptsTraitsRequestsApiResponse,
  getOptsTraitRequestDetailsApiResponse,
  createOptTraitsApiResponse,
  createNewOptApiResponse,
  createMarketingProgramsApiResponse,
  getMarketingProgramRequestDetailsApiResponse,
  getMarketingProgramEcosystemRequestDetailsApiResponse,
  getMarketingProgramsRequestsApiResponse,
  getJobsApiResponse,
  getJobDetailsApiResponse,
  getUseCasesApiResponse,
  getUseCaseDetailsApiResponse,
  getEventsApiResponse,
  getEventDetailsApiResponse,
  getEventProperties,
  getEventRequestSubmission,
  getEventRequestDetails,
  getTraitsResponsesDetails,
  getTraitsResponse,
  getTraitsResponses,
  getCreateTraitResponse,
  getEventsRequestsApiResponse,
  getEventsRequestDetailsApiResponse,
  getJobsApiResponsePagination,
  getEventMappings,
  getDummyDataApiResponse,
  getDummyDataModalApiResponse,
};
