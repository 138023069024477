import axios from "axios";

import applicationConfig from "../config/applicationConfig";

import logout from "./logout";

const { deploymentEnv } = applicationConfig;

const api = axios.create({
  baseURL: deploymentEnv === "local" ? "http://localhost:3100/api" : "/api",
  withCredentials: deploymentEnv === "local",
});

api.interceptors.request.use(async (config) => {
  const updatedConfig = config;
  const pingToken = localStorage.getItem("ping-token");
  // Append ping token if available in local storage
  if (pingToken && !updatedConfig.headers["x-crs-ping-token"]) {
    updatedConfig.headers["x-crs-ping-token"] = pingToken;
  }
  const userGroupsToken = localStorage.getItem("groups-token");
  // Append user groups token if available in local storage
  if (userGroupsToken && !updatedConfig.headers["x-crs-user-groups-token"]) {
    updatedConfig.headers[
      "x-crs-user-groups-token"
    ] = `Bearer ${userGroupsToken}`;
  }
  const pingSessionEnd = localStorage.getItem("session-end");
  // Append ping session end time if available in local storage
  if (userGroupsToken && !updatedConfig.headers["x-crs-ping-session-end"]) {
    updatedConfig.headers["x-crs-ping-session-end"] = pingSessionEnd;
  }
  localStorage.setItem("setBaseUrl", updatedConfig.baseURL);
  return updatedConfig;
});

api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response) {
      if (error.response.status === 401) {
        logout();
      }
      return Promise.reject(error.response);
    }
    // Handle error response from Auth token URL
    if (error.data) {
      return Promise.reject(error);
    }
    const networkError = new Error();
    networkError.data = {
      userMessage: "API Unavailable",
    };
    return Promise.reject(networkError);
  }
);

export default api;
