import PropTypes from "prop-types";

import useStyles from "./styles";

const Layout = ({ children }) => {
  const styles = useStyles();
  return (
    <>
      <main>
        <div className={styles.container}>
          <div className={styles.content}>{children}</div>
        </div>
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
