import { useEffect, useMemo, useState } from "react";

import {
  ThemeProvider,
  createTheme,
  withStyles,
  StylesProvider,
} from "@material-ui/core/styles";

import { BrowserRouter } from "react-router-dom";

import { lightTheme, darkTheme } from "./assets/styles/themes";

import CustomSnackbar from "./components/CustomSnackbar";
import Navigation from "./components/Navigation";

import ColorModeProvider from "./providers/ColorModeProvider";
import LoadingSpinnerProvider from "./providers/LoadingSpinnerProvider";
import NotificationProvider from "./providers/NotificationProvider";
import RequestProvider from "./providers/RequestProvider";
import UserProfileProvider from "./providers/UserProfileProvider";

import Routes from "./Routes";
import "./App.css";

const { initCache } = require("./utilities/cache");

const App = () => {
  const [mode, setMode] = useState(() =>
    localStorage.getItem("mode") ? localStorage.getItem("mode") : "light"
  );

  useEffect(() => {
    const storedMode = localStorage.getItem("mode");
    if (!storedMode) {
      localStorage.setItem("mode", "light");
    }
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        const newMode = mode === "light" ? "dark" : "light";
        setMode(newMode);
        localStorage.setItem("mode", newMode);
      },
      mode,
    }),
    [mode]
  );

  const theme = useMemo(
    () => createTheme(mode === "light" ? lightTheme : darkTheme),
    [mode]
  );

  useEffect(() => {
    initCache();
  }, []);

  const GlobalCss = withStyles((_theme) => ({
    "@global": {
      ".MuiAutocomplete-popper .MuiAutocomplete-option:nth-child(even)": {
        backgroundColor: _theme.colorTheme.selectMenuItem,
      },
    },
  }))(() => null);

  return (
    <BrowserRouter>
      <StylesProvider injectFirst>
        <ColorModeProvider value={colorMode}>
          <ThemeProvider theme={theme}>
            <GlobalCss />
            <NotificationProvider>
              <UserProfileProvider>
                <RequestProvider>
                  <div
                    style={{
                      backgroundColor: theme.palette.background.default,
                      width: "100%",
                      minHeight: "100%",
                    }}
                  >
                    <LoadingSpinnerProvider>
                      <Navigation />
                      <CustomSnackbar />
                      <Routes />
                    </LoadingSpinnerProvider>
                  </div>
                </RequestProvider>
              </UserProfileProvider>
            </NotificationProvider>
          </ThemeProvider>
        </ColorModeProvider>
      </StylesProvider>
    </BrowserRouter>
  );
};

export default App;
