import { useState, useRef, useEffect, useContext, createElement } from "react";

import {
  AppBar,
  Typography,
  Popper,
  ClickAwayListener,
  Paper,
  Grow,
  MenuItem,
  MenuList,
  styled,
  Switch,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTheme } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Circle } from "rc-progress";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import refreshToken from "../../api/post-refresh-token";

import { ReactComponent as LightLogo } from "../../assets/images/CRS_COLOR_LOGO_WO_TEXT.svg";
import { ReactComponent as DarkLogo } from "../../assets/images/CRS_LOGO_WO_TEXT.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import { ReactComponent as PgLogo } from "../../assets/images/pgLogo.svg";

import applicationConfig from "../../config/applicationConfig";

import useUserProfile from "../../hooks/useUserProfile";

import { ColorModeContext } from "../../providers/ColorModeProvider";

import Form from "../Form";

import useStyles from "./styles";

const Header = ({ logout }) => {
  const classes = useStyles();
  const colorMode = useContext(ColorModeContext);
  const totalSessionTime = applicationConfig.sessionTimeInMinutes * 60 * 1000;
  const timeoutWarningThreshold = 2 * 60 * 1000;
  const defaultTimer = () => Date.now() + totalSessionTime;
  const theme = useTheme();
  const { user } = useUserProfile();
  const [resetCounter, setResetCounter] = useState(Date.now());
  const [sessionExpireModal, setSessionExpireModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referenceType, setReferenceType] = useState("Consumer");
  const [sessionStartTime, setSessionStartTime] = useState(() => {
    let sessionTimer;
    const sessionEnd = Number(localStorage.getItem("session-end"));

    if (sessionEnd && sessionEnd > Date.now()) {
      sessionTimer = sessionEnd - Date.now();
    } else {
      sessionTimer = defaultTimer();
    }
    localStorage.setItem("session-timer", sessionTimer);

    return sessionTimer < totalSessionTime
      ? Date.now() + sessionTimer
      : sessionTimer;
  });
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (user.userId) {
      setResetCounter(Date.now());
    }
  }, [user]);

  const renderer = (tick) => {
    const { minutes, seconds, total, completed } = tick;
    const completePercentage = Math.round((total / totalSessionTime) * 100);

    if (completed && !loading && user.userId) {
      logout();
      return null;
    }

    const zeroPad = (time) => {
      return `0${time}`.slice(-2);
    };

    const handleProgressColorChange = () => {
      if (completePercentage < 17) {
        return "#f44336";
      }

      if (completePercentage >= 17 && completePercentage <= 33) {
        return "#ffc107";
      }

      if (completePercentage > 33) {
        return "#4caf50";
      }

      return "#fff";
    };

    const timer = (
      <Tooltip title="Session Timer">
        <IconButton className={classes.timerBtn}>
          <Circle
            percent={completePercentage}
            strokeWidth={8}
            strokeColor={handleProgressColorChange()}
          />
          <span className="timer-text">
            {zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </IconButton>
      </Tooltip>
    );

    const dialog = (manualOpen) => (
      <Dialog
        open={manualOpen || sessionExpireModal}
        onClose={() => setSessionExpireModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to stay signed in?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You session is about to expire. Signing you out in {timer}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActionFooter}>
          <Button
            onClick={() => logout(false)}
            className={classes.logoutTimerBtn}
          >
            Logout
          </Button>
          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              const response = await refreshToken();
              if (response?.token) {
                const sessionEnd =
                  Date.now() + Number(response.expiresIn) * 1000;
                localStorage.setItem("ping-token", response.token);
                localStorage.setItem("session-end", sessionEnd);
                localStorage.setItem(
                  "session-timer",
                  Number(response.expiresIn) * 1000
                );
                setSessionStartTime(sessionEnd);
                setResetCounter(Date.now());
              }
              setLoading(false);
            }}
            className={classes.loginTimerBtn}
          >
            {loading && (
              <Box
                sx={{
                  mr: 1,
                  mt: 0.5,
                }}
              >
                <CircularProgress size={20} color="white" />
              </Box>
            )}
            Stay signed in
          </Button>
        </DialogActions>
      </Dialog>
    );

    if (total < timeoutWarningThreshold) {
      return dialog(true);
    }

    return (
      <>
        {timer}
        {dialog()}
      </>
    );
  };

  const handleTimerTick = (tick) => {
    localStorage.setItem("session-timer", tick.total);
  };

  const referenceTypeField = [
    {
      type: applicationConfig.inputType.dropdown,
      props: {
        select: true,
        variant: "outlined",
        onChange: (event) => {
          setReferenceType(event.target.value);
          localStorage.setItem("reference-type", event.target.value);
        },
        inputProps: {
          "data-testid": "reference-type",
        },
        values: applicationConfig.referenceTypeInput,
        value: localStorage.getItem("reference-type") || referenceType,
      },
    },
  ];

  return (
    <AppBar position="fixed" className={classes.header}>
      <div
        className={clsx(
          classes.flexContainer,
          classes.padding,
          classes.headerWrapper
        )}
      >
        <div className={classes.headerTitle}>
          <Link to="/">
            <div className={classes.flexContainer}>
              <div
                className={clsx(classes.flexContainer, classes.logoContainer)}
              >
                <div className={classes.pgLogo}>
                  <PgLogo />
                </div>
                <Box sx={{ mt: 0.5 }}>
                  {colorMode.mode === "light" ? <LightLogo /> : <DarkLogo />}
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography>
                    Consumer 360 <sup>o</sup>
                  </Typography>
                </Box>
                <Typography className={clsx(classes.dcf, classes.margin)}>
                  CRS
                </Typography>
              </div>
              {applicationConfig.deploymentEnv !== "prd" && (
                <Form
                  fields={referenceTypeField}
                  fieldClassName={clsx(
                    classes.fieldContainer,
                    classes.flexContainer
                  )}
                />
              )}
            </div>
          </Link>
        </div>
        {user.userId && (
          <div className={clsx(classes.flexContainer, classes.iconContainer)}>
            <Countdown
              date={sessionStartTime}
              renderer={renderer}
              key={resetCounter}
              onTick={handleTimerTick}
            />
            <div
              className={clsx(classes.flexContainer, classes.usernameContainer)}
            >
              <ThemeSwitch
                onChange={colorMode.toggleColorMode}
                checked={localStorage.getItem("mode") === "dark"}
              />
              {user.firstName && (
                <>
                  <div className={classes.profilePicture}>
                    <span>{user.firstName.charAt(0).toUpperCase()}</span>
                    <span>{user.lastName.charAt(0).toUpperCase()}</span>
                  </div>
                  <Typography variant="h6">
                    {`${user.firstName} ${user.lastName}`}
                  </Typography>
                  <KeyboardArrowDownIcon
                    ref={anchorRef}
                    onClick={handleToggle}
                    data-testid="keyboardArrowDownIcon"
                  />
                </>
              )}
            </div>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "right top" : "right bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="menu-list-grow">
                        <MenuItem
                          className={classes.menuItem}
                          onClick={async (event) => {
                            await logout(false);
                            handleClose(event);
                          }}
                        >
                          {createElement(LogoutIcon, {
                            fill: theme.colorTheme.fill,
                          })}
                          {t("common.logout")}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )}
      </div>
    </AppBar>
  );
};

const ThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: theme.palette.static.white,
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          theme.palette.static.white
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.colorTheme.switchTrack,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.colorTheme.switchThumb,
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        theme.palette.static.white
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.colorTheme.switchTrack,
    borderRadius: 20 / 2,
  },
}));

Header.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default Header;
