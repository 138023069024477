import { useState, useEffect, useCallback } from "react";

import { Button, Typography, CircularProgress, Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import InfoIcon from "@material-ui/icons/Info";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, Prompt, useHistory, useLocation } from "react-router-dom";

import createSourceRequest from "../../../api/create-source-request";
import getMarketingProramDetails from "../../../api/get-marketing-program-details";
import getMarketingProgramsApi from "../../../api/get-marketing-programs";
import getReferenceData from "../../../api/get-reference-data";
import getRequestDetails from "../../../api/get-request-details";
import getSourcesApi from "../../../api/get-sources";
import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";
import { lightTheme } from "../../../assets/styles/themes";

import AccessDenied from "../../../components/AccessDenied";
import ConfirmationModal from "../../../components/ConfirmationModal";
import InlineMessage from "../../../components/InlineMessage";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import sourcesModuleConfig from "../../../config/sourcesModuleConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import usePrevious from "../../../hooks/usePrevious";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import useImmer from "../../../utilities/useImmer";
import NewSegmentSource from "../components/NewSegmentSource";

import DataSourceModal from "./components/DataSourceModal";
import SelectedSource from "./components/SelectedSource";
import SourcesRequestOutputModal from "./components/SourcesRequestOutputModal";
import createBackendPayload from "./helpers/index";
import validateNewSegmentSource from "./helpers/validateNewSegmentSource";
import useStyles from "./styles";

const CreateSourcesContainer = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const { user } = useUserProfile();
  const { addNotification } = useNotifier();
  const history = useHistory();
  const { t } = useTranslation();
  const [sourcesOptions, setSourcesOptions] = useState([]);

  const { createSourceConstants, requestStatus } = sourcesModuleConfig;
  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.createSources
  );

  const totalSteps = 3;
  const [formErrors, setFormErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const previousStep = usePrevious(currentStep);
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const [showSourceOutput, setShowSourceOutput] = useState(false);
  const [isNewDataSourceEditDisabled, setIsNewDataSourceEditDisabled] =
    useState(false);
  const [sourceResponse, setSourceResponse] = useState([]);
  // Marketing Program
  const [marketingProgramOptions, setMarketingProgramOptions] = useState([]);
  const [marketingProgramsLoading, setMarketingProgramsLoading] =
    useState(true);
  const [marketingProgram, setMarketingProgram] = useState({});

  const [sourceTableLoading, setSourceTableLoading] = useState(false);
  const [sourceOutput, setSourceOutput] = useState({});
  const [segmentSource, setSegmentSource] = useState({});

  const [newSegmentSourceModal, setNewSegmentSourceModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDatasourceDeleteModal, setOpenDatasourceDeleteModal] =
    useState(false);
  const [datasourceIndex, setDatasourceIndex] = useState(-1);
  const [segmentSourceValue, setSegmentSourceValue] = useState("");
  const [newSegmentSource, setNewSegmentSource] = useImmer({
    ecosystemSourceName: "",
    sourceIntegrationType: "",
  });
  const [sourcesLoading, setSourcesLoading] = useState(false);
  const [automationEnabled, setAutomationEnabled] = useState(false);

  const [mapToSegmentSource, setMapToSegmentSource] = useState("false");
  const [sourcesSelected, setSourcesSelected] = useState([]);
  const [openCreateDatasourceModal, setOpenCreateDatasourceModal] =
    useState(false);
  const [initialSourceOptions, setInitialSourceOptions] = useState([]);
  const [dataSourceValue, setDataSourceValue] = useState("");
  const [numberOfDataSourceInputs, setNumberOfDataSourceInputs] = useState(1);

  const initialFocusedValues = {
    marketingProgramDescription: false,
    sourceName: false,
    description: false,
    consumerFacingDescription: false,
    descriptionOfProcess: false,
    dataType: false,
    businessFacingName: false,
    dataSourceWebsite: false,
    url: false,
    registrationPageUrl: false,
    systemDisplayingPage: false,
    managedByExternalAgency: false,
    agencyName: false,
    coControllerInd: false,
    ropaId: false,
  };

  const [segmentSourcesLoading, setSegmentSourcesLoading] = useState(true);
  const [focused, setFocused] = useImmer(initialFocusedValues);
  const [ecoSystems, setEcoSystems] = useState([]);
  const [dataSourceInfo, setDataSourceInfo] = useState({});

  const [editDataSource, setEditDataSource] = useState({});
  const { request, setRequest } = useRequest();
  const location = useLocation();
  const [segmentSourcesOptions, setSegmentSourcesOptions] = useState([]);
  const [initialSegmentSourceOptions, setInitialSegmentSourceOptions] =
    useState([]);

  const isInfoStep = currentStep > totalSteps;
  const isRequestRevisionFlow = Object.keys(request).length !== 0;

  useEffect(async () => {
    if (currentStep === 2) {
      try {
        const response = await getMarketingProramDetails(
          marketingProgram.marketingProgramNumber
        );

        const responseEcosystem = response.ecosystems.find(
          (ecosystem) => ecosystem.ecosystemId === 1
        );

        if (responseEcosystem) {
          setAutomationEnabled(responseEcosystem.automationEnabled);
        } else {
          setAutomationEnabled(false);
        }
      } catch (error) {
        handleError({
          error,
          handle404: () => {
            setAutomationEnabled(false);
          },
          addNotification,
        });
      }
    }
  }, [currentStep, segmentSource]);

  useEffect(() => {
    if (currentStep === 2 && previousStep === 1 && !isRequestRevisionFlow) {
      setSegmentSource({});
      setMapToSegmentSource("false");
      setSegmentSourceValue("");
    }
  }, [currentStep, previousStep]);

  const checkSegmentSourceDisabled = () => {
    if (
      Object.keys(newSegmentSource).length > 0 &&
      Object.values(newSegmentSource).every((val) => val && val.length > 0)
    ) {
      const errors = validateNewSegmentSource(
        focused,
        newSegmentSource,
        marketingProgram
      );
      if (!Object.values(errors).every((error) => error === null)) {
        return true;
      }
      return false;
    }
    return true;
  };

  const newDataSource = (
    <div className="newDataSource">
      <Typography variant="h6">
        {t("create_trait.no_suggestion_match")}
      </Typography>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setOpenCreateDatasourceModal(true);
        }}
      >
        {t("create_source.new_trait")}
      </Button>
    </div>
  );

  const getSourcesFromApi = useCallback(async (searchText) => {
    let filter = {};
    if (searchText && searchText.length > 0) {
      filter = {
        searchText,
      };
    }
    const filterFieldMap = {
      searchText: "searchText",
      marketingProgram: "marketingProgram",
    };
    try {
      setSourcesLoading(true);
      const rsp1 = await getSourcesApi(
        parseSearchFilter(
          Object.keys(filter).map((fil) => ({
            name: fil,
            value: filter[fil],
          })),
          filterFieldMap,
          1,
          3
        )
      );
      if (searchText.length === 0) {
        setInitialSourceOptions([...rsp1.items, newDataSource]);
      }
      setSourcesOptions([...rsp1.items, newDataSource]);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setSourcesOptions([newDataSource]);
        },
        addNotification,
      });
    } finally {
      setSourcesLoading(false);
    }
  }, []);

  const debouncedSourcesFromApi = debounce(
    getSourcesFromApi,
    applicationConfig.waitTime
  );

  const memoizedSourcesFromApi = useCallback(async (searchText) => {
    debouncedSourcesFromApi(searchText);
  }, []);

  const validateForm = (step) => {
    const returnValue = {};
    if (step === 1) {
      if (isEmpty(marketingProgram)) {
        returnValue.marketingProgram = "Please select a marketing program";
      }
      if (
        sourcesSelected.length === 0 ||
        sourcesSelected.length !== numberOfDataSourceInputs
      ) {
        returnValue.dataSources = "Please select a data source";
      }
    }
    if (step === 2) {
      if (mapToSegmentSource === "true" && isEmpty(segmentSource)) {
        returnValue.segmentSource = "Please select a segment source";
      }
    }
    return returnValue;
  };

  const getMarketingProgramsFromApi = useCallback(async (searchText) => {
    let filter = { itemsPerPage: 3, page: 1 };
    if (searchText && searchText.length > 0) {
      filter = {
        ...filter,
        searchText,
      };
    }
    try {
      const rsp1 = await getMarketingProgramsApi(filter);
      setMarketingProgramOptions(rsp1.items);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setMarketingProgramOptions([]);
        },
        addNotification,
      });
    } finally {
      setMarketingProgramsLoading(false);
    }
  }, []);

  // Debounce & Memoize Api Calls
  const debouncedMarketingProgramsFromApi = debounce(
    getMarketingProgramsFromApi,
    applicationConfig.waitTime
  );
  const memoizedMarketingProgramsFromApi = useCallback((val) => {
    debouncedMarketingProgramsFromApi(val);
  }, []);

  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  useEffect(() => {
    const { requestDetails } = request;
    if (requestDetails) {
      if (requestDetails.dataSource) {
        setDataSourceInfo(requestDetails.dataSource);
        setSourcesSelected([
          {
            new: true,
            ...requestDetails.dataSource,
            dataType: requestDetails.dataSource.dataType.split(","),
            marketingProgramDescription:
              requestDetails.marketingProgram.description,
          },
        ]);
      } else {
        setSourcesSelected(
          requestDetails.dataSources.map((dataSource) => ({
            ...dataSource,
            dataType: dataSource?.dataType?.split(","),
            new: !dataSource.sourceId,
          }))
        );
        setNumberOfDataSourceInputs(requestDetails.dataSources.length);
      }
      setMarketingProgram({
        marketingProgramNumber:
          requestDetails.marketingProgram.marketingProgramNumber,
        description: requestDetails.marketingProgram.description,
        title: `${requestDetails.marketingProgram.marketingProgramNumber} - ${requestDetails.marketingProgram.description}`,
      });
      setMapToSegmentSource(
        requestDetails.mapToSegmentSource ? "true" : "false"
      );
      if (requestDetails.segmentSource) {
        if (requestDetails.segmentSource.ecosystemSourceId) {
          setSegmentSource({
            ...requestDetails.segmentSource,
            title: `${requestDetails.segmentSource.ecosystemSourceId} - ${requestDetails.segmentSource.ecosystemSourceName}`,
          });
        } else {
          setSegmentSource({
            ...requestDetails.segmentSource,
            title: requestDetails.segmentSource.ecosystemSourceName,
            isNewSource: true,
            sourceIntegrationType:
              requestDetails.segmentSource.ecosystemSourceType,
          });
        }
      }
    }
  }, [isRequestRevisionFlow]);

  useEffect(() => {
    if (location.pathname === applicationConfig.pathnames.sources_revision) {
      if (Object.keys(request).length === 0) {
        history.goBack();
      }
    }
  }, []);

  const newSource = (
    <div
      className={clsx(
        classes.flexContainer,
        classes.addTraitBtn,
        classes.noOptionsContainer
      )}
    >
      <Typography variant="h6">
        {t("create_trait.no_suggestion_match")}
      </Typography>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setNewSegmentSourceModal(true);
        }}
        data-testid="new-segment-source"
      >
        {t("common.labels.new_segment_source")}
      </Button>
    </div>
  );

  const handleSourceOutputModal = async (reqId) => {
    setSourceTableLoading(true);
    setShowSourceOutput(true);
    setSourceOutput({
      output: {
        warnings: [],
        items: [],
      },
    });
    try {
      const response = await getRequestDetails(reqId);
      setSourceOutput(response);
      setSourceTableLoading(false);
    } catch (error) {
      setShowSourceOutput(false);
      setSourceTableLoading(false);
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  useEffect(async () => {
    if (user.userId && isUserAuthorized) {
      increaseRequestsCount(2);
      await getMarketingProgramsFromApi("");
      await getSourcesFromApi("");
      decreaseRequestsCount(2);
    }
  }, [user]);

  useEffect(async () => {
    if (currentStep === 2) {
      try {
        const data = await getReferenceData(
          applicationConfig.referenceDataQueries.segmentSources
        );
        setEcoSystems(
          data.ecoSystems.map((x) => ({
            ...x,
            ecosystemSourceName: x.ecoSystemSourceName,
            ecosystemSourceId: x.ecoSystemSourceId,
          }))
        );
      } catch (error) {
        handleError({
          error,
          handle404: () => {
            setSegmentSourcesOptions([newSource]);
          },
          addNotification,
        });
      } finally {
        setSegmentSourcesLoading(false);
      }
    }
  }, [currentStep]);

  const sourceRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "sourceName",
      headerName: t("common.labels.source_name"),
      flex: 1,
      sortable: false,
    },
    {
      field: "marketingProgram",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
    },
    {
      field: "segmentSource",
      headerName: t("common.labels.segment_source"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      width: 150,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  handleSourceOutputModal(params.row.requestId);
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  useEffect(() => {
    if (dataSourceValue.length > 0) {
      memoizedSourcesFromApi(dataSourceValue);
    }
  }, [dataSourceValue]);

  useEffect(() => {
    if (ecoSystems.length > 0) {
      if (segmentSourceValue.length > 0) {
        setSegmentSourcesOptions([
          ...ecoSystems.filter((x) =>
            x.ecosystemSourceName.match(segmentSourceValue)
          ),
          newSource,
        ]);
      } else {
        setInitialSegmentSourceOptions([...ecoSystems.slice(0, 3), newSource]);
      }
    }
  }, [segmentSourceValue, ecoSystems]);

  const onCloseDeleteModal = () => {
    setSegmentSource({});
    setAutomationEnabled(false);
    setNewSegmentSource((draft) => {
      draft.ecosystemSourceName = marketingProgram.description;
      draft.sourceIntegrationType = "";
      draft.ecosystemSourceSlug = "";
      draft.ecosystemSourceKey = "";
    });
    setFocused((draft) => {
      draft.ecosystemSourceName = false;
      draft.sourceIntegrationType = false;
    });
    setOpenDeleteModal(false);
  };

  const onDataSourceDelete = () => {
    const newSources = [...sourcesSelected];
    newSources.splice(datasourceIndex, 1);
    setSourcesSelected(newSources);
    setNumberOfDataSourceInputs((x) => (x - 1 === 0 ? 1 : x - 1));
    setDatasourceIndex(-1);
    setOpenDatasourceDeleteModal(false);
  };

  const getConfirmationDetails = () => {
    const info = [
      {
        label: t("common.labels.marketing_program"),
        value: (
          <div>
            <Typography variant="h6">{marketingProgram.title}</Typography>
          </div>
        ),
      },
      {
        label: t("common.labels.sources"),
        value: sourcesSelected.map((source) => {
          if (source.new) {
            return (
              <div
                key={`${source.sourceName}`}
                className={clsx(classes.flexContainer, classes.marginTop20)}
              >
                <div
                  className={classes.newBtnContainer}
                  onClick={() => {
                    setEditDataSource(source);
                    setOpenCreateDatasourceModal(true);
                    setIsNewDataSourceEditDisabled(true);
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                >
                  <span className="traitName">{source.sourceName}</span>
                  <Button variant="contained">{t("common.new")}</Button>
                </div>
              </div>
            );
          }
          return (
            <div>
              {source.sourceId} - {source.sourceName}
            </div>
          );
        }),
      },
      {
        label: t("create_source.map_to_segment_source"),
        value: (
          <div>
            <Typography variant="h6">
              {mapToSegmentSource === "true" ? "Yes" : "No"}
            </Typography>
          </div>
        ),
      },
      ...(mapToSegmentSource === "true"
        ? [
            {
              label: t("common.labels.segment_source"),
              value: segmentSource.isNewSource ? (
                <div
                  key={`${segmentSource.title}`}
                  className={clsx(classes.flexContainer, classes.marginTop20)}
                >
                  <div
                    className={classes.newBtnContainer}
                    onClick={() => {
                      setNewSegmentSource(segmentSource);
                      setNewSegmentSourceModal(true);
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                  >
                    <span className="traitName">{segmentSource.title}</span>
                    <Button variant="contained">{t("common.new")}</Button>
                  </div>
                </div>
              ) : (
                <div>
                  <Typography variant="h6">{segmentSource.title}</Typography>
                </div>
              ),
            },
          ]
        : []),
    ];
    return info;
  };

  const renderStep = (step) => {
    if (step === 1) {
      const sourceDropdownValues =
        dataSourceValue.length > 0 ? sourcesOptions : initialSourceOptions;
      return (
        <div
          className={clsx(
            classes.grid,
            classes.marketingProgramSourceContainer,
            step === 2 && classes.columnGrid
          )}
          data-testid="sources-container"
        >
          <div className={classes.inputContainer}>
            <StyledTooltip
              placement="top-start"
              title={t("new_segment_source.marketing_program")}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <Typography>
                  {t("common.labels.marketing_program")}
                  <i style={{ color: "red" }}>*</i>
                </Typography>
                <InfoIcon className={classes.infoIcon} />
              </div>
            </StyledTooltip>
            <Autocomplete
              id="combo-box-demo"
              options={marketingProgramOptions.map((option) => ({
                ...option,
                title: `${option.marketingProgramNumber} - ${option.description}`,
              }))}
              closeIcon={<></>}
              loading={marketingProgramsLoading}
              getOptionLabel={(option) => option.title || ""}
              onChange={(event, value) => {
                if (value && value.description) {
                  setMarketingProgram(value);
                  onCloseDeleteModal();
                  setNewSegmentSource((draft) => {
                    draft.ecosystemSourceName = value.description;
                    draft.sourceIntegrationType = "";
                  });
                  const filteredSources = [...sourcesSelected].filter(
                    (y) => !y.new
                  );
                  const diff = sourcesSelected.length - filteredSources.length;
                  setSourcesSelected(filteredSources);
                  setNumberOfDataSourceInputs((x) =>
                    x - diff < 1 ? 1 : x - diff
                  );
                } else {
                  setMarketingProgram({
                    description: "",
                  });
                }
              }}
              value={marketingProgram}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  helperText={
                    <InlineMessage message={formErrors.marketingProgram} />
                  }
                  error={!!formErrors.marketingProgram}
                  placeholder={t("new_source.placeholders.marketing_program")}
                  onChange={(event) => {
                    setMarketingProgramsLoading(true);
                    setMarketingProgramOptions([]);
                    memoizedMarketingProgramsFromApi(event.target.value);
                  }}
                />
              )}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>
              {t("common.labels.sources")}
              <i style={{ color: "red" }}>*</i>
            </Typography>
            {[...Array(numberOfDataSourceInputs).keys()].map((index) => {
              const sourceName = sourcesSelected[index]?.sourceName;
              const sourceId = sourcesSelected[index]?.sourceId;
              return (
                <div className={classes.dataSourceInput} key={sourceName}>
                  <Autocomplete
                    id="sources"
                    classes={{
                      listbox: classes.listbox,
                    }}
                    className={
                      sourcesSelected[index] ? classes.disablePointerEvents : {}
                    }
                    options={sourceDropdownValues
                      .filter((_source) => {
                        if (_source.type !== "div") {
                          const isExisting = sourcesSelected.find(
                            (existingTrait) =>
                              existingTrait.sourceName === _source.sourceName
                          );
                          if (isExisting) {
                            return false;
                          }
                          return true;
                        }
                        return true;
                      })
                      .map((option) => {
                        if (option.type !== "div") {
                          return {
                            ...option,
                            title: `${option.sourceId} - ${option.sourceName}`,
                          };
                        }
                        return option;
                      })}
                    inputValue={dataSourceValue}
                    closeIcon={<></>}
                    loading={sourcesLoading}
                    getOptionLabel={(option) => {
                      if (option.type !== "div") {
                        return option.title || "";
                      }
                      return "";
                    }}
                    renderOption={(option) => {
                      if (option.type !== "div") {
                        return option.title;
                      }
                      return newDataSource;
                    }}
                    onChange={(event, value) => {
                      if (value.type !== "div") {
                        setSourcesSelected((cs) => {
                          const sources = [...cs];
                          sources[index] = { ...value };
                          return sources;
                        });
                      }
                    }}
                    disabled={
                      !(
                        marketingProgram !== null &&
                        Object.keys(marketingProgram).length > 0
                      )
                    }
                    renderInput={(params) => {
                      const newParams = {
                        ...params,
                        inputProps: {
                          ...params.inputProps,
                          value: undefined, // Remove the value property
                        },
                      };
                      return (
                        <div style={{ position: "relative" }}>
                          {sourcesSelected[index]?.new && (
                            <Button
                              style={{
                                position: "absolute",
                                left: "1%",
                                top: "15%",
                                backgroundColor: lightTheme.palette.blue.darker,
                                color: lightTheme.palette.static.white,
                              }}
                            >
                              New
                            </Button>
                          )}
                          <TextField
                            {...newParams}
                            value={
                              sourceId && sourceName
                                ? `${sourceId} - ${sourceName}`
                                : sourceName
                            }
                            inputProps={{
                              ...newParams.inputProps,
                              value:
                                sourceId && sourceName
                                  ? `${sourceId} - ${sourceName}`
                                  : sourceName,
                            }}
                            variant="outlined"
                            className={
                              sourcesSelected[index]?.new
                                ? classes.newTextField
                                : ""
                            }
                            placeholder={
                              isEmpty(marketingProgram)
                                ? "Please select Marketing Program First"
                                : "Example: i2/?/1/IND/Asia/GHH/Direxions"
                            }
                            helperText={
                              <InlineMessage
                                message={
                                  !sourcesSelected[index]
                                    ? formErrors.dataSources
                                    : ""
                                }
                              />
                            }
                            error={
                              !sourcesSelected[index] &&
                              !!formErrors.dataSources
                            }
                            onChange={(event) => {
                              setSourcesLoading(true);
                              setSourcesOptions([]);
                              setDataSourceValue(event.target.value);
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                  {numberOfDataSourceInputs > 1 ||
                  sourcesSelected.length >= 1 ? (
                    <>
                      {sourcesSelected[index]?.new && (
                        <div
                          className={classes.autoCompleteEditContainer}
                          onClick={() => {
                            setEditDataSource({
                              ...sourcesSelected[index],
                              index,
                            });
                            setOpenCreateDatasourceModal(true);
                          }}
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                        >
                          <EditOutlinedIcon />
                        </div>
                      )}
                      <div
                        className={classes.autoCompleteDeleteContainer}
                        onClick={() => {
                          setOpenDatasourceDeleteModal(true);
                          setDatasourceIndex(index);
                        }}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </div>
                    </>
                  ) : (
                    <div />
                  )}
                </div>
              );
            })}
          </div>
          <div className={classes.addDataSourcesBtn}>
            <AddCircleOutlineIcon />
            <Button
              onClick={() => {
                setNumberOfDataSourceInputs((x) => x + 1);
              }}
            >
              {t("common.add_datasource")}
            </Button>
          </div>
        </div>
      );
    }

    if (step === 2) {
      const sourceOptions =
        segmentSourceValue.length !== 0
          ? segmentSourcesOptions
          : initialSegmentSourceOptions;
      return (
        <div className={classes.step2Wrapper}>
          <div className={classes.mapToSegmentSourceWrapper}>
            <Typography>Map To Segment Source</Typography>
            <div className={classes.mapToSegmentSourceBtns}>
              <Button
                className={mapToSegmentSource === "true" ? "active" : ""}
                onClick={() => setMapToSegmentSource("true")}
              >
                Yes
              </Button>
              <Button
                className={mapToSegmentSource === "false" ? "active" : ""}
                onClick={() => setMapToSegmentSource("false")}
              >
                No
              </Button>
            </div>
          </div>
          {mapToSegmentSource === "true" && !automationEnabled && (
            <Alert severity="info" style={{ margin: "15px 0" }}>
              {t("create_source.segment_automation_message")}
              <Link
                to={{
                  pathname: `/marketing-programs/manage?mpNumber=${
                    marketingProgram.marketingProgramNumber
                  }&ecoSystem=${"1"}`,
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                click here
              </Link>
            </Alert>
          )}
          {mapToSegmentSource === "true" &&
            (Object.keys(segmentSource || {}).length === 0 ? (
              <div
                className={clsx(
                  classes.inputContainer,
                  classes.segmentSourceAutoComplete
                )}
              >
                <Typography>
                  {t("common.labels.segment_source")}
                  <i style={{ color: "red" }}>*</i>
                </Typography>
                <Autocomplete
                  id="combo-box-demo"
                  options={sourceOptions.map((option) => {
                    if (option.type !== "div") {
                      return {
                        ...option,
                        title: `${option.ecosystemSourceId} - ${option.ecosystemSourceName}`,
                      };
                    }
                    return option;
                  })}
                  getOptionLabel={(option) => {
                    if (option.type !== "div") {
                      return option.title || "";
                    }
                    return "";
                  }}
                  renderOption={(option) => {
                    if (option.type !== "div") {
                      return option.title;
                    }
                    return newSource;
                  }}
                  closeIcon={<></>}
                  loading={segmentSourcesLoading}
                  isDisabled={
                    !(
                      marketingProgram !== null &&
                      Object.keys(marketingProgram).length > 0
                    )
                  }
                  placeholder={t(
                    "new_segment_source.placeholders.segment_source"
                  )}
                  onChange={(event, value) => {
                    setSegmentSource(value);
                    setFormErrors((x) => {
                      const a = { ...x };
                      delete a.segmentSource;
                      delete a.segmentSourceValidation;
                      return a;
                    });
                  }}
                  inputValue={segmentSourceValue}
                  setValue={(val) => {
                    if (val && val.type !== "div") {
                      if (val) {
                        setSegmentSource({
                          ...val,
                          isNewSource: false,
                        });
                        setFormErrors((x) => {
                          const a = { ...x };
                          delete a.segmentSource;
                          delete a.segmentSourceValidation;
                          return a;
                        });
                      }
                    }
                  }}
                  renderInput={(params) => {
                    return (
                      <div style={{ position: "relative" }}>
                        <TextField
                          {...params}
                          value={segmentSourceValue}
                          variant="outlined"
                          placeholder={
                            isEmpty(marketingProgram)
                              ? "Please select Marketing Program First"
                              : "Example: i2/?/1/IND/Asia/GHH/Direxions"
                          }
                          helperText={
                            <InlineMessage message={formErrors.segmentSource} />
                          }
                          error={!!formErrors.segmentSource}
                          onChange={(event) => {
                            setSegmentSourceValue(event.target.value);
                            setSegmentSourcesOptions([]);
                            setSegmentSourcesLoading(true);
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            ) : (
              <SelectedSource
                isNewSource={segmentSource.isNewSource}
                sourceName={segmentSource.title}
                onDelete={() => {
                  setOpenDeleteModal(true);
                }}
                onClick={() => {
                  if (segmentSource.isNewSource) {
                    setNewSegmentSource({
                      ecosystemSourceName: segmentSource.ecosystemSourceName,
                      sourceIntegrationType:
                        segmentSource.sourceIntegrationType,
                    });
                    setNewSegmentSourceModal(true);
                  } else {
                    setOpenDeleteModal(true);
                  }
                }}
              />
            ))}
          {formErrors.segmentSourceValidation && (
            <InlineMessage message={formErrors.segmentSourceValidation} />
          )}
        </div>
      );
    }

    if (step === 3) {
      const details = getConfirmationDetails();
      return details.map((info) => {
        return (
          <div
            className={clsx(
              classes.flexContainer,
              classes.infoContainer,
              classes.justifyContent
            )}
            key={`${info.label}${info.index}`}
          >
            <Typography className="label" variant="h6">
              {info.label} :
            </Typography>
            <Typography variant="h6">{info.value}</Typography>
          </div>
        );
      });
    }

    return (
      <div
        style={{
          height: 70 * sourceResponse.length + 60,
          overflow: "hidden",
          maxHeight: "calc(100vh - 300px)",
        }}
      >
        <Table columns={sourceRequestTableColumns} rows={sourceResponse} />
      </div>
    );
  };

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied
      goToLink="/sources"
      goToText={t("access_denied.go_to_sources")}
    />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        steps={[
          t("create_source.headings.step_1"),
          t("create_source.headings.step_2"),
          t("create_source.headings.step_3"),
        ]}
        currentStep={currentStep}
        loading={loading && currentStep === 1}
        headerText={t("sources_container.sources_management")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ? (
                  <div className={classes.backBtn}>
                    {!isRequestRevisionFlow ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to="/sources"
                        classes={{
                          root: globalStyles.btn,
                        }}
                      >
                        {t("back")}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        classes={{
                          root: globalStyles.btn,
                        }}
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        {t("back")}
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                      setIsNewDataSourceEditDisabled(false);
                    }}
                  >
                    BACK
                  </Button>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                className="nextStep"
                classes={{
                  root: globalStyles.btn,
                }}
                onClick={async () => {
                  if (currentStep === totalSteps) {
                    try {
                      increaseRequestsCount();
                      const payload = createBackendPayload(
                        marketingProgram,
                        sourcesSelected,
                        segmentSource,
                        mapToSegmentSource
                      );

                      if (!isRequestRevisionFlow) {
                        const data = await createSourceRequest(payload);
                        const { items } = data;
                        setSourceResponse(
                          items[0].dataSources.map((item, index) => ({
                            id: index,
                            ...item,
                            requestId: items[0].requestId,
                            status:
                              items[0].status[0].toUpperCase() +
                              items[0].status.slice(1),
                            sourceName: item.sourceId
                              ? `${item.sourceId} - ${item.sourceName}`
                              : item.sourceName,
                            marketingProgram:
                              items[0].marketingProgram.description,
                            segmentSource:
                              items[0].segmentSource?.ecosystemSourceName || "",
                          }))
                        );
                        setCurrentStep(currentStep + 1);
                      } else {
                        const data = await updateRequest(
                          request.requestId,
                          payload,
                          applicationConfig.modules.data_sources
                        );
                        setIsRequestRevisionUpdated(true);
                        setRequest(data);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                      }
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    const errors = validateForm(currentStep);
                    setFormErrors(errors);
                    if (Object.keys(errors).length > 0) {
                      return null;
                    }
                    setCurrentStep(currentStep + 1);
                  }
                  return null;
                }}
              >
                {currentStep === totalSteps && loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.7,
                    }}
                  >
                    <CircularProgress color="white" size={20} />
                  </Box>
                )}
                {createSourceConstants.sourceFooterText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={classes.lastStepFooter}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/sources"
                >
                  {t("common.labels.back_to_sources")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${sourceResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div
          className={clsx(
            classes.traitContainer,
            isInfoStep && classes.paddingZero
          )}
        >
          {renderStep(currentStep)}
        </div>
      </InputFlow>
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("dialogs.confirm_remove")}
        message={t("create_segment_source.segment_source_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          onCloseDeleteModal();
        }}
        type="error"
      />
      <ConfirmationModal
        open={openDatasourceDeleteModal}
        onClose={() => setOpenDatasourceDeleteModal(false)}
        title={t("dialogs.confirm_remove")}
        message="Would you like to remove this data source?"
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          onDataSourceDelete();
        }}
        type="error"
      />
      {newSegmentSourceModal && (
        <NewSegmentSource
          isOpen={newSegmentSourceModal}
          newSegmentSource={newSegmentSource}
          setNewSegmentSource={setNewSegmentSource}
          setNewSegmentSourceModal={setNewSegmentSourceModal}
          focused={focused}
          setFocused={setFocused}
          marketingProgram={marketingProgram}
          isDisabled={isInfoStep || currentStep === 3}
          setSegmentSource={setSegmentSource}
          currentStep={currentStep}
          checkSegmentSourceDisabled={checkSegmentSourceDisabled}
        />
      )}
      {showSourceOutput && (
        <SourcesRequestOutputModal
          isOpen={showSourceOutput}
          isLoading={sourceTableLoading}
          requestId={sourceOutput.requestId}
          data={sourceOutput.output}
          setShowSourceOutput={() => setShowSourceOutput(false)}
        />
      )}
      <DataSourceModal
        isNewDataSourceEditDisabled={isNewDataSourceEditDisabled}
        editDataSource={editDataSource}
        setEditDataSource={setEditDataSource}
        marketingProgram={marketingProgram}
        dataSourceInfo={dataSourceInfo}
        openCreateDatasourceModal={openCreateDatasourceModal}
        setOpenCreateDatasourceModal={setOpenCreateDatasourceModal}
        addNewSource={({ index, ...rest }, type) => {
          if (
            type === "new" &&
            sourcesSelected.some((x) => x.sourceName === rest.sourceName)
          ) {
            return;
          }
          if (type === "new") {
            setSourcesSelected((x) => [...x, rest]);
          } else {
            const sourcesSelectedData = sourcesSelected;
            sourcesSelectedData[index] = rest;
            setSourcesSelected(sourcesSelectedData);
          }
        }}
      />
      <Prompt
        message={() => {
          if (isRequestRevisionFlow) {
            return true;
          }
          return t("prompt.progress_lost");
        }}
        when={
          !isInfoStep &&
          (sourcesSelected.filter((source) => source.sourceName).length > 0 ||
            !isEmpty(marketingProgram) ||
            !isEmpty(segmentSource))
        }
      />
    </>
  );
};

export default CreateSourcesContainer;
