import React from "react";

import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import CreateFlow from "./CreateFlow";
import Search from "./Search";

const DummyModule = React.memo(
  ({ basePath }) => {
    return (
      <Switch>
        <Route
          exact
          path={`${basePath}`}
          component={() => {
            return <Search />;
          }}
        />
        <Route exact path={`${basePath}/map`} component={CreateFlow} />
        <Route
          exact
          path={`${basePath}/requests/revision`}
          component={CreateFlow}
        />
        <Route
          exact
          path={`${basePath}/*`}
          render={() => <Redirect to={`${basePath}`} />}
        />
      </Switch>
    );
  },
  () => true
);

DummyModule.defaultProps = {
  basePath: applicationConfig.basePaths.dummy,
};

DummyModule.propTypes = {
  basePath: PropTypes.string,
};

export default DummyModule;
