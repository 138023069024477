import getDummyData from "../api/get-dummy-data";

const dummyModuleConfig = {
  createFlowConstants: {
    creationHeadings: {
      1: "step1",
      2: "step2",
      3: "step3",
    },
    revisionHeadings: {
      1: "step1",
      2: "step2",
      3: "step3",
    },
    footerText: {
      1: "proceed to step 2",
      2: "proceed to confirmation",
      3: "submit details",
    },
  },
  dummyColumns: [
    {
      field: "field1",
      headerName: "Field1",
      flex: 0.5,
      sortable: false,
      clickable: true,
      clickableCellData: {
        params: ["field1"],
        api: getDummyData,
      },
      disableToggle: true,
    },
    {
      field: "field2",
      headerName: "Field2",
      flex: 0.5,
      sortable: false,
      clickable: false,
      disableToggle: true,
    },
  ],
  searchDefaultFilterState: {
    field1: false,
  },
  searchFilters: [
    {
      name: "field1",
      label: "Field1",
    },
    {
      name: "field2",
      label: "Field2",
    },
  ],
};

export default dummyModuleConfig;
