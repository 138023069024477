import { useState, useRef } from "react";

import { Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import getDummyData from "../../../api/get-dummy-data";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import Table from "../../../components/Table";
import Tabs from "../../../components/Tabs";

import dummyModuleConfig from "../../../config/dummyModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";

import DetailsModal from "../components/DetailsModal";

import useStyles from "./styles";

const Search = () => {
  const { dummyColumns, searchDefaultFilterState, searchFilters } =
    dummyModuleConfig;
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [filters, setFilters] = useState({});
  const childRef = useRef();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cellData, setCellData] = useState({
    data: [],
    field: "field1",
    row: {},
  });

  const { user } = useUserProfile();
  const { loading } = useLoadingSpinner();

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.dummy
  );

  const resetDrawer = () => {
    setDrawerOpen((x) => !x);
    setCellData({
      data: [],
      field: "traitId",
      row: {},
    });
  };

  return !loading && !isUserAuthorized ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            Dummy Search Page
          </Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          tabList={[
            {
              label: "Dummy",
              onClick: () => history.push("/dummy?displayTab=dummy"),
            },
          ]}
          classes={{
            indicator: globalStyles.indicator,
          }}
        />
        <div className={classes.flexContainer}>
          <Button
            btnText="CREATE"
            onClick={() => {}}
            variant="contained"
            classes={{
              root: globalStyles.btn,
            }}
            goToLink="/dummy/map"
            style={{
              marginRight: "34px",
            }}
          />
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              setFilters(data);
            }}
            searchFilters={searchFilters}
            defaultFilterState={searchDefaultFilterState}
          />
        </div>
      </div>
      <Paper data-testid="traits-container" elevation={2}>
        <div className={classes.dummyContainer}>
          <Table
            initApiCall={() => getDummyData()}
            moduleName="dummy"
            filters={filters}
            hidePagination
            columns={dummyColumns}
            setLoadingCellData={setLoadingCellData}
            renderCellData={(data, field, row) =>
              setCellData({ data, field, row })
            }
            exportMainSheetName="dummySheet"
            openModal={() => setDrawerOpen(true)}
          />
        </div>
      </Paper>
      {cellData.field === "field1" && drawerOpen && (
        <DetailsModal
          title={`Dummy ID - ${cellData.row.field1}`}
          subtitle="This ID has following mappings"
          data={cellData.data}
          loading={loadingCellData}
          open={drawerOpen}
          onClose={() => resetDrawer()}
        />
      )}
    </Layout>
  );
};

export default Search;
