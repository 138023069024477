import PropTypes from "prop-types";

import useStyles from "./styles";

const ResponseHeaderPane = ({ response }) => {
  const responseHeaders = [];
  const styles = useStyles();

  if (!(response == null)) {
    if ("headers" in response) {
      Object.entries(response.headers).forEach(([key, value]) => {
        responseHeaders.push({
          key,
          value,
        });
      });
    }
  }
  const renderedHeaders = responseHeaders.map(({ key, value, index }) => {
    return (
      <tr key={index}>
        <td className={styles.bodyCell}>{key}</td>
        <td>{value}</td>
      </tr>
    );
  });
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.headerCell}>Key</th>
          <th className={styles.valueCell}>Value</th>
        </tr>
      </thead>
      <tbody>{renderedHeaders}</tbody>
    </table>
  );
};

ResponseHeaderPane.propTypes = {
  response: PropTypes.object.isRequired,
};

export default ResponseHeaderPane;
