import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core";
import prettyBytes from "pretty-bytes";
import PropTypes from "prop-types";

import ResponseTabGroup from "../../TabGroups/ResponseTabGroup";

const useStyles = makeStyles((theme) => ({
  responseContainer: {
    margin: theme.spacing(2, 0),
  },
  responseMeta: {
    display: "flex",
    marginTop: theme.spacing(1.5),
  },
  w28: {
    width: "7rem",
  },
  w24: {
    width: "6rem",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "medium",
  },
}));

const Response = ({ response, loading }) => {
  const [doc, setDoc] = useState("{}");
  const styles = useStyles();

  useEffect(() => {
    if (response === null) return;
    const jsonResponse = JSON.stringify(response.data, null, 2);
    setDoc(jsonResponse);
  }, [response, loading]);

  const hasResponse = !(response == null);

  let time = "";
  let status = "";
  let size = "";

  if (hasResponse) {
    const hasCustomData = "customData" in response;
    const hasData = "data" in response;
    const hasHeaders = "headers" in response;

    status = hasResponse ? response.status : 0;

    if (hasData && hasHeaders) {
      size = prettyBytes(
        (hasResponse ? JSON.stringify(response.data).length : 0) +
          (hasResponse ? JSON.stringify(response.headers).length : 0)
      );
    }

    if (hasCustomData) {
      time = response.customData.time;
    }
  }

  const RenderedResponseMeta = () => {
    return (
      <div className={styles.responseMeta}>
        <span className={styles.w28}>Status: {status}</span>
        <span className={styles.w24}>Time: {time}</span>
        <span className={styles.w24}>Size: {size}</span>
      </div>
    );
  };

  return (
    <div className={styles.responseContainer}>
      <span className={styles.title}>Response</span>
      {response ? <RenderedResponseMeta /> : null}
      <ResponseTabGroup
        doc={doc}
        setDoc={setDoc}
        response={response}
        loading={loading}
      />
    </div>
  );
};

Response.propTypes = {
  response: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Response;
