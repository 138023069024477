import { useState } from "react";

import { Typography, Tabs, Tab, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import useGlobalStyles from "../../../../assets/styles/global";

import { KeyValueSeparator } from "../../../../components/AccordionRowDetails";
import CustomModal from "../../../../components/CustomModal";

import pageAccessConfig from "../../../../config/pageAccessConfig";

import useUserProfile from "../../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../../utilities/checkUserAuthorization";

import useStyles from "./styles";

const DetailsModal = ({ title, subtitle, data, loading, open, onClose }) => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const { user } = useUserProfile();
  const history = useHistory();

  const rows = [
    {
      label: "Field1",
      value: data.field1,
    },
    {
      label: "Field2",
      value: data.field2,
    },
    {
      label: "Field3",
      value: data.field3,
    },
  ];

  const renderUi = () => {
    return (
      <table className={globalClasses.table}>
        <tbody>
          {rows.map((row, ind) => (
            <KeyValueSeparator
              keyVal={row.label}
              value={row.value}
              ind={ind.toString()}
              separator={null}
            />
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      loading={loading}
      data={rows}
      showCloseIcon
    >
      <div className={classes.container}>
        {loading && (
          <Typography variant="body2">{t("common.loading")}...</Typography>
        )}
        <div>
          <Tabs
            value={tab}
            onChange={(_, number) => setTab(number)}
            aria-label="basic tabs"
            classes={{
              indicator: globalClasses.indicator,
            }}
          >
            <Tab label="Details" />
          </Tabs>
          {renderUi()}
          {checkUserAuthorization(user.access, pageAccessConfig.dummy) && (
            <Button
              onClick={() => {
                history.push({
                  pathname: "/dummy/map",
                  state: {},
                });
              }}
              classes={{
                root: globalClasses.btn,
              }}
              variant="outlined"
              style={{
                marginTop: "34px",
                justifySelf: "flex-end",
              }}
            >
              Dummy
            </Button>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

DetailsModal.defaultProps = {
  title: null,
};

DetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    field1: PropTypes.string.isRequired,
    field2: PropTypes.string.isRequired,
    field3: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DetailsModal;
