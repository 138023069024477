import { makeStyles, Select, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";

const requestMethods = [
  {
    slug: "get",
    method: "GET",
  },
  {
    slug: "post",
    method: "POST",
  },
  {
    slug: "put",
    method: "PUT",
  },
  {
    slug: "patch",
    method: "PATCH",
  },
  {
    slug: "delete",
    method: "DELETE",
  },
];

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
  },
  select: {
    padding: theme.spacing(1, 2),
    boxSizing: "border-box",
    width: "112px",
    "&:hover": {
      borderColor: theme.palette.orange.main,
    },
    "&:focus": {
      outline: "none",
    },
  },
  input: {
    marginLeft: theme.spacing(1.5),
    width: "100%",
    padding: theme.spacing(1, 2),
    border: "1px solid",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.colorTheme.greyishWhite3,
    "&:hover": {
      borderColor: theme.palette.orange.main,
    },
    "&:focus": {
      outline: `2px solid ${theme.palette.orange.main}`,
    },
  },
  button: {
    marginLeft: theme.spacing(1.5),
    padding: theme.spacing(1, 3),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.orange.main,
    "&:hover": {
      backgroundColor: theme.palette.orange.main,
    },
  },
}));

const UrlEditor = ({ url, setUrl, reqMethod, setReqMethod, onInputSend }) => {
  const classes = useStyles();
  return (
    <>
      <form className={classes.form}>
        <Select
          value={reqMethod}
          onChange={(e) => setReqMethod(e.target.value)}
          classes={{
            root: classes.select, // Apply custom select style
          }}
          variant="outlined"
        >
          {requestMethods.map((option) => (
            <MenuItem key={option.slug} value={option.method}>
              {option.method}
            </MenuItem>
          ))}
        </Select>
        <input
          className={classes.input}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <button
          className={classes.button}
          type="button"
          onClick={(e) => onInputSend(e)}
        >
          Send
        </button>
      </form>
    </>
  );
};

UrlEditor.propTypes = {
  url: PropTypes.string.isRequired,
  setUrl: PropTypes.func.isRequired,
  reqMethod: PropTypes.string.isRequired,
  setReqMethod: PropTypes.func.isRequired,
  onInputSend: PropTypes.func.isRequired,
};

export default UrlEditor;
