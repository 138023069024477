import { useState, useEffect } from "react";

import { Button, Typography, CircularProgress, Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, Prompt } from "react-router-dom";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import dummyModuleConfig from "../../../config/dummyModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";

import useStyles from "./styles";

const CreateFlow = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  const { user } = useUserProfile();
  const { requestStatus } = applicationConfig;
  const { request, setRequest } = useRequest();
  const history = useHistory();
  const location = useLocation();

  let requestId = null;

  if (location.state) {
    requestId = location.state.requestId;
  }

  //   useEffect(() => {
  //     if (
  //       location.pathname === applicationConfig.pathnames.traits_requests_revision
  //     ) {
  //       if (Object.keys(request).length === 0 && !requestId) {
  //         history.goBack();
  //       }
  //     }
  //   }, []);

  const isRequestRevisionFlow =
    Object.keys(request).length !== 0 || Boolean(requestId);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow) {
        setRequest({});
      }
    };
  }, []);

  const [mappingResponse, setMappingResponse] = useState([]);
  const { createFlowConstants } = dummyModuleConfig;

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.createTraits
  );

  const totalSteps = 3;

  const [currentStep, setCurrentStep] = useState(1);

  const { t } = useTranslation();

  const { loading } = useLoadingSpinner();

  const isInfoStep = currentStep > totalSteps;

  useEffect(async () => {
    if (user.userId && isUserAuthorized) {
      //   increaseRequestsCount(2);
      //   api calls
      //   decreaseRequestsCount(2);
    }
  }, [user]);

  const getConfirmationDetails = () => {
    const info = [
      {
        label: "label1",
        value: "value1, value2",
      },
      {
        label: "label2",
        value: "value2",
      },
      {
        label: "label3",
        value: "value3",
      },
    ];
    return info;
  };

  const handleOutputModal = async () => {
    // Handle output modal code
  };

  const createRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      width: 150,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  // Handle Modal
                  handleOutputModal();
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  const renderStep = (step) => {
    if (step === 1) {
      if (loading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      return (
        <div className={classes.center} data-testid="dummy-container">
          <Typography className="label">Form Render</Typography>
        </div>
      );
    }
    if (step === 2) {
      if (loading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      return (
        <div className={classes.marketingProgramContainer}>
          <div
            className={clsx(classes.flexContainer, classes.justifyContent)}
            key="marketing-program"
          >
            <Typography className="label">Form Render</Typography>
          </div>
          <div
            className={clsx(classes.flexContainer, classes.justifyContent)}
            key="ecosystem-container"
          >
            <Typography className="label">Children</Typography>
          </div>
        </div>
      );
    }
    if (step === 3) {
      const details = getConfirmationDetails();
      const detailsDOM = details.map((info) => {
        return (
          <div
            className={clsx(
              classes.flexContainer,
              classes.infoContainer,
              classes.justifyContent
            )}
            style={{ alignItems: "flex-start" }}
            key={`${info.label}${info.index}`}
          >
            <Typography className="label" variant="h6">
              {info.label} :
            </Typography>
            <Typography variant="h6">{info.value}</Typography>
          </div>
        );
      });
      return <>{detailsDOM}</>;
    }
    return (
      <div
        style={{
          height: 70 * mappingResponse.length + 40,
          maxHeight: "calc(100vh - 300px)",
          overflow: "hidden",
        }}
      >
        <Table columns={createRequestTableColumns} rows={mappingResponse} />
      </div>
    );
  };

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied goToLink="/dummy" goToText="GO TO DUMMY" />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        steps={["step1", "step2", "step3"]}
        currentStep={currentStep}
        loading={loading && currentStep === 1}
        header={
          <>
            <Typography variant="h4" gutterBottom>
              {isRequestRevisionFlow
                ? createFlowConstants.revisionHeadings[currentStep]
                : createFlowConstants.creationHeadings[currentStep]}
            </Typography>
          </>
        }
        headerText="Module Header"
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ? (
                  <div className={classes.backBtn}>
                    <Button
                      variant="outlined"
                      color="primary"
                      classes={{
                        root: globalStyles.btn,
                      }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    classes={{
                      root: globalStyles.btn,
                    }}
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    BACK
                  </Button>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                disabled={loading}
                className="nextStep"
                classes={{
                  root: globalStyles.btn,
                }}
                onClick={async () => {
                  setCurrentStep(currentStep + 1);
                  if (currentStep === 3) {
                    setMappingResponse([
                      {
                        id: "0",
                        requestId: "CRS123",
                        status: "APPROVED",
                      },
                    ]);
                  }
                }}
              >
                {currentStep === totalSteps && loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
                {createFlowConstants.footerText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/dummy"
                >
                  Back to Dummy
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${mappingResponse[0]?.requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div
          className={clsx(classes.container, isInfoStep && classes.paddingZero)}
        >
          {renderStep(currentStep)}
        </div>
      </InputFlow>
      <Prompt
        message={() => {
          return t("prompt.progress_lost");
        }}
        when={!isInfoStep}
      />
    </>
  );
};

export default CreateFlow;
