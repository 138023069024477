import { useRef, useState } from "react";

import { Button } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import useGlobalStyles from "../../../../assets/styles/global";
import { AccordionModalDetails } from "../../../../components/AccordionModal";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import isEmpty from "../../../../utilities/isEmpty";
import useImmer from "../../../../utilities/useImmer";
import NewEventProperty from "../NewEventProperty";

const EventProperties = ({
  eventProperties,
  eventPropertiesKeys,
  onEditProperty,
  onDeleteProperty,
  viewOnly,
  setEventProperties,
}) => {
  const { t } = useTranslation();
  const globalStyles = useGlobalStyles();
  const modalRef = useRef();
  const [openVersionModal, setOpenVersionModal] = useState(false);
  const [editRow, setEditRow] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [propertyInfo, setPropertyInfo] = useImmer({
    propertyName: "",
    description: "",
    dataType: "",
    sampleValues: "",
    required: false,
    personalData: false,
    personalDataNonPii: false,
    healthData: false,
  });

  const handleSubmit = (propertiesInfo, type) => {
    if (editRow) {
      onEditProperty({
        ...editRow,
        propertyName: propertiesInfo.propertyName,
        description: propertiesInfo.description,
        sampleValues: propertiesInfo.sampleValues,
        dataType: propertiesInfo.dataType,
        required: propertiesInfo.required,
        personalData: propertiesInfo.personalData,
        personalDataNonPii: propertiesInfo.personalDataNonPii,
        healthData: propertiesInfo.healthData,
      });
      setEditRow(null);
    } else if (type === "multiple") {
      setEventProperties([
        ...eventProperties,
        ...propertiesInfo.map((property) => ({
          propertyName: property.propertyName,
          dataType: property.dataType,
          description: property.description,
          sampleValues: property.sampleValues,
          required: property.required,
          personalData: property.personalData,
          personalDataNonPii: property.personalDataNonPii,
          healthData: property.healthData,
          new: true,
        })),
      ]);
    } else {
      const propertyDetail = {
        propertyName: propertiesInfo.propertyName,
        dataType: propertiesInfo.dataType,
        description: propertiesInfo.description,
        sampleValues: propertiesInfo.sampleValues,
        required: propertiesInfo.required,
        personalData: propertiesInfo.personalData,
        personalDataNonPii: propertiesInfo.personalDataNonPii,
        healthData: propertiesInfo.healthData,
        new: true,
      };
      const currentEventsProps = [...eventProperties, propertyDetail];
      setEventProperties(currentEventsProps);
      modalRef.current.scrollTop = 0;
    }
    setOpenVersionModal(false);
  };

  return (
    <>
      <AccordionModalDetails
        noScroll
        ref={modalRef}
        emptyDataMessage={t("create_events.no_event_properties_added")}
        keys={eventPropertiesKeys}
        rows={eventProperties}
        rowKeys={{
          title: "propertyName",
          subTitle: "description",
        }}
        showDelete={(row) => row.new === true && !viewOnly}
        showEdit={(row) => row.new === true && !viewOnly}
        onEdit={(row) => {
          setOpenVersionModal(true);
          setEditRow(row);
          setPropertyInfo(row);
        }}
        onDelete={(row) => {
          setEditRow(row);
          setOpenDeleteModal(true);
        }}
      />
      {!viewOnly && (
        <div className={globalStyles.addTraitBtn}>
          <AddCircleOutlineIcon />
          <Button
            onClick={() => {
              setOpenVersionModal(true);
            }}
          >
            {t("create_events.create_event_property")}
          </Button>
        </div>
      )}
      {openVersionModal && (
        <NewEventProperty
          existingEventProperties={eventProperties}
          isEditFlow={!isEmpty(editRow)}
          title={t("create_event.create_event_property.new_property")}
          openModal={openVersionModal}
          handleClose={() => {
            setOpenVersionModal(false);
            setEditRow();
          }}
          handleSubmit={handleSubmit}
          setPropertyInfo={setPropertyInfo}
          propertyInfo={propertyInfo}
        />
      )}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
          setEditRow(null);
        }}
        title={t("dialogs.confirm_remove")}
        message={t("create_event.create_event_property.delete_property")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          onDeleteProperty(editRow);
          setEditRow(null);
          setOpenDeleteModal(false);
        }}
        type="error"
      />
    </>
  );
};

EventProperties.defaultProps = {
  onEditProperty: () => {},
  onDeleteProperty: () => {},
  viewOnly: false,
};

EventProperties.propTypes = {
  onEditProperty: PropTypes.func,
  onDeleteProperty: PropTypes.func,
  viewOnly: PropTypes.bool,
  eventProperties: PropTypes.arrayOf({
    id: PropTypes.string,
    propertyName: PropTypes.string,
    description: PropTypes.string,
    dataType: PropTypes.string,
    sampleValues: PropTypes.string,
  }).isRequired,
  setEventProperties: PropTypes.func.isRequired,
  eventPropertiesKeys: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default EventProperties;
