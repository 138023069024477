import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  themeWrapper: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    "& .MuiDataGrid-columnHeader--moving.MuiDataGrid-columnHeader": {
      backgroundColor: theme.palette.background.default,
    },
  },
  container: {
    padding: "0 60px 0 134.5px",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    [theme.breakpoints.up(2000)]: {
      maxWidth: "1994.5px",
    },
    [theme.breakpoints.up(2410)]: {
      maxWidth: "2334.5px",
    },
    [theme.breakpoints.down(1950)]: {
      maxWidth: "1879.5px",
    },
    [theme.breakpoints.down(1880)]: {
      maxWidth: "1674.5px",
    },
    [theme.breakpoints.down(1700)]: {
      maxWidth: "1574.5px",
    },
    [theme.breakpoints.down(1580)]: {
      maxWidth: "1474.5px",
      padding: "0 58px 0 132.5px",
    },
    [theme.breakpoints.down(1465)]: {
      maxWidth: "1324.5px",
    },
    height: "100vh",
  },
  header: {
    paddingTop: "75px",
    marginBottom: "14px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.colorTheme.white,
  },
}));

export default useStyles;
