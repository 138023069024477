import { useState } from "react";

import { Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import useNotifier from "../../../../../hooks/useNotifier";

import api from "../../../../../utilities/api";
import handleError from "../../../../../utilities/handleError";

import convertKeyValueToObject from "../../../utils/convertKeyValueToObject";

import UrlEditor from "../../Panes/RequestUrl/UrlEditor";
import RequestTabGroup from "../../TabGroups/RequestTabGroup";

const keyPairInitState = [
  {
    id: uuidv4(),
    keyItem: "",
    valueItem: "",
  },
];

const useStyles = makeStyles(() => ({
  heading: {
    margin: "0px 0px 20px 0px",
    fontSize: "30px",
    fontWeight: "900",
    textAlign: "center",
    color: "#f97316",
  },
}));

const Request = ({ setResponse, setLoading }) => {
  const [url, setUrl] = useState();
  const [reqMethod, setReqMethod] = useState("GET");
  const [queryParams, setQueryParams] = useState(keyPairInitState);
  const [headers, setHeaders] = useState(keyPairInitState);
  const [body, setBody] = useState("{\n\t\n}");
  const { addNotification } = useNotifier();

  const validateUrlCheck = (inputUrl) => {
    const regex = /^\//.test(inputUrl);
    return regex;
  };

  const styles = useStyles();

  const handleOnInputSend = async (e) => {
    setLoading(true);
    e.preventDefault();
    const requestBody = body.toString();
    let data;
    try {
      data = JSON.parse(requestBody);
    } catch (error) {
      addNotification("Invalid JSON data.");
    }

    try {
      const validateUrl = validateUrlCheck(url);
      if (!validateUrl) {
        addNotification(
          "Only Relative URLs are supported, e.g. /traits, /logging-configs."
        );
        setLoading(false);
        return;
      }
      const response = await api({
        url,
        method: reqMethod,
        params: convertKeyValueToObject(queryParams),
        headers: convertKeyValueToObject(headers),
        data,
      });
      setResponse(response);
    } catch (error) {
      handleError({
        error,
        handle404: false,
        addNotification,
      });
      setResponse(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Typography className={styles.heading}>API Explorer</Typography>
      <UrlEditor
        url={url}
        setUrl={setUrl}
        reqMethod={reqMethod}
        setReqMethod={setReqMethod}
        onInputSend={handleOnInputSend}
      />
      <RequestTabGroup
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        headers={headers}
        setHeaders={setHeaders}
        body={"{\n\t\n}"}
        setBody={setBody}
      />
    </>
  );
};

Request.propTypes = {
  setResponse: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default Request;
