function updateQuillContent(
  originalContent,
  valueMap,
  removeHighlight = false
) {
  const regex = /\[([^\]]+)\]/g; // Matches text inside square brackets
  return originalContent.replace(regex, (match, capturedText) => {
    if (!removeHighlight) {
      const highlightedText = valueMap?.[capturedText]
        ? `<span style="color:red">${valueMap?.[capturedText]}</span>`
        : match;
      return highlightedText; // Replace if found in map, else keep original
    }
    return valueMap?.[capturedText] || match;
  });
}

export default updateQuillContent;
