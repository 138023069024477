import { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import PropTypes from "prop-types";

const KeyValueEditor = ({ keyPair, setKeyPair, onKeyPairRemove }) => {
  const [keyValue, setKeyValue] = useState(keyPair);
  const [debouncedKeyValue, setDebouncedKeyValue] = useState(keyValue);

  const useStyles = makeStyles((theme) => ({
    button: {
      marginLeft: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      border: "1px solid",
      "&:hover": {
        backgroundColor: "rgba(255, 0, 0, 0.1)",
      },
      backgroundColor: theme.colorTheme.white,
    },
    container: {
      display: "flex",
      marginBottom: theme.spacing(3),
    },
    input: {
      padding: "0.375rem 1rem 0.375rem 1rem",
      width: "100%",
      border: `1px solid ${theme.colorTheme.greyishWhite}`,
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        borderColor: theme.palette.orange.light,
      },
      "&:focus": {
        outlineColor: theme.palette.orange.main,
        outlineStyle: "solid",
      },
    },
    inputMargin: {
      marginLeft: theme.spacing(3),
    },
  }));

  const styles = useStyles();

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedKeyValue(keyValue);
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [keyValue]);

  useEffect(() => {
    setKeyPair(debouncedKeyValue);
  }, [debouncedKeyValue]);

  const handleOnChange = (e) => {
    setKeyValue((prevState) => ({
      ...prevState,
      id: keyValue.id,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <>
      <div className={styles.container}>
        <input
          type="text"
          className={styles.input}
          placeholder="Key"
          name="keyItem"
          onChange={(e) => handleOnChange(e)}
        />
        <input
          type="text"
          className={clsx(styles.input, styles.inputMargin)}
          placeholder="Value"
          name="valueItem"
          onChange={(e) => handleOnChange(e)}
        />
        <button
          type="button"
          className={styles.button}
          onClick={() => onKeyPairRemove(keyPair)}
        >
          Remove
        </button>
      </div>
    </>
  );
};

KeyValueEditor.propTypes = {
  keyPair: PropTypes.string.isRequired,
  setKeyPair: PropTypes.func.isRequired,
  onKeyPairRemove: PropTypes.func.isRequired,
};

export default KeyValueEditor;
