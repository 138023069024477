import clsx from "clsx";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import JsonEditorPane from "../Panes/Json/JsonEditorPane";
import KeyValuePane from "../Panes/KeyValue/KeyValuePane";

import "./index.css";

const RequestTabGroup = ({
  queryParams,
  setQueryParams,
  headers,
  setHeaders,
  body,
  setBody,
}) => {
  const requestTabs = [
    {
      slug: "query-params",
      title: "Query Params",
      panel: KeyValuePane,
      paneValue: queryParams,
      setPaneValue: setQueryParams,
    },
    {
      slug: "headers",
      title: "Headers",
      panel: KeyValuePane,
      paneValue: headers,
      setPaneValue: setHeaders,
    },
    {
      slug: "body",
      title: "Body",
      panel: JsonEditorPane,
      paneValue: body,
      setPaneValue: setBody,
    },
  ];

  return (
    <Tabs
      forceRenderTabPanel
      className="tabs-container"
      selectedTabClassName="selected-tab"
    >
      <TabList className="tab-list">
        {requestTabs.map((tab) => (
          <Tab className="tab-item" key={tab.slug}>
            {tab.title}
          </Tab>
        ))}
      </TabList>

      {requestTabs.map((tab) => (
        <TabPanel
          className={clsx("react-tabs__tab-panel", "tab-panel")}
          key={tab.slug}
        >
          <tab.panel
            paneValue={tab.paneValue}
            setPaneValue={tab.setPaneValue}
          />
        </TabPanel>
      ))}
    </Tabs>
  );
};

RequestTabGroup.propTypes = {
  queryParams: PropTypes.string.isRequired,
  setQueryParams: PropTypes.func.isRequired,
  headers: PropTypes.string.isRequired,
  setHeaders: PropTypes.func.isRequired,
  body: PropTypes.string.isRequired,
  setBody: PropTypes.string.isRequired,
};

export default RequestTabGroup;
