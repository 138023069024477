import MockData from "../mocks/data";

const getDummyData = async (id) => {
  if (id) {
    return MockData.getDummyDataModalApiResponse();
  }
  return MockData.getDummyDataApiResponse();
};

export default getDummyData;
