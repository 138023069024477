import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  table: {
    textAlign: "left",
  },
  headerCell: {
    width: "9rem",
    paddingBottom: "0.375rem",
  },
  valueCell: {
    width: "15rem",
  },
  bodyCell: {
    paddingBottom: "0.25rem",
  },
}));

export default useStyles;
