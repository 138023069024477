import PropTypes from "prop-types";
import { ThreeDots } from "react-loader-spinner";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import JsonEditorPane from "../Panes/Json/JsonEditorPane";
import ResponseHeaderPane from "../Panes/ResponseHeader/ResponseHeaderPane";

import "./index.css";

const ResponseTabGroup = ({ doc, setDoc, response, loading }) => {
  const responseTabs = [
    {
      slug: "response-body",
      title: "Response Body",
    },
    {
      slug: "response-header",
      title: "Response Header",
    },
  ];
  return (
    <>
      <Tabs
        forceRenderTabPanel
        className="tabs-container"
        selectedTabClassName="selected-tab"
      >
        <TabList className="tab-list">
          {responseTabs.map((tab) => (
            <Tab className="tab-item" key={tab.slug}>
              {tab.title}
            </Tab>
          ))}
        </TabList>

        <div className="tab-panel">
          {loading ? (
            <ThreeDots height="30" width="30" color="gray" visible />
          ) : (
            <>
              <TabPanel>
                <JsonEditorPane
                  paneValue={doc}
                  setPaneValue={setDoc}
                  isEditable={false}
                />
              </TabPanel>
              <TabPanel>
                <ResponseHeaderPane response={response} />
              </TabPanel>
            </>
          )}
        </div>
      </Tabs>
    </>
  );
};

ResponseTabGroup.propTypes = {
  doc: PropTypes.string.isRequired,
  setDoc: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  response: PropTypes.object.isRequired,
};

export default ResponseTabGroup;
