export const lightTheme = {
  palette: {
    type: "light",
    primary: {
      main: "#1976d2",
      light: "#2699FB",
      light1: "rgba(25, 118, 210, 0.5)",
      light2: "rgba(38, 153, 251, 0.2)",
    },
    secondary: {
      main: "#6DC24B",
    },
    warning: {
      main: "#FFDA29",
      light: "#FF9D00",
    },
    error: {
      main: "#FF0000",
      dark: "#c61d1d",
      delete: "#EF3441",
      main1: "#F5A623",
      main2: "#FB262C",
      light: "#FF7C7C",
      light2: "#FF6161",
    },
    green: {
      main: "#3CC66B",
      main1: "#00ff00",
      main2: "#039103",
      main3: "#28C095",
      light1: "#86ffaf",
      light2: "#9CDA00",
    },
    background: {
      default: "#F7F6F8",
      default1: "#FFFFFF",
    },
    blue: {
      main: "#3682F2",
      dark: "#0E61DD",
      light: "#57AEFA",
      darker: "#0151B6",
      light2: "#2699FB",
    },
    orange: {
      main: "#F97316",
      light: "#EA580C",
      light1: "#ffedd5",
    },
    static: {
      white: "#fff",
      dullWhite: "#FFFFFE",
      blandWhite: "#F6F9FC",
      mutedWhite: "#FEFEFF",
      lightGrey: "#FDFDFD",
      mildGrey: "#eee",
      mediumGrey: "#999",
      dullGrey: "#454455",
      darkGrey: "#313030",
      blandGrey: "#303238",
      darkerGrey: "#303030",
      pureGrey: "#001E3C",
      almostBlack: "#282B31",
      orange: "#E8934A",
      blandGreen: "#A2F88F",
      strongPing: "#E84A6D",
      subtleYellow: "#E9E94A",
      hardRed: "#f44336",
      lightRed: "#E94E42",
      dullRed: "#f8c9c6",
      pureYellow: "#ffeb3b",
      mediumLavender: "#8759B7",
      darkLavender: "#5B4FB2",
      darkPink: "#AB65BB",
      lightTeal: "#C4FFFD",
      lighterTeal: "#C7FFEA",
      dullTeal: "#DBFAFF",
    },
  },
  divider: "#dddddd",
  shape: {
    borderRadius: "0.375rem",
  },
  colorTheme: {
    white: "#ffffff",
    black: "#000000",
    white1: "#FAFAFA",
    grey: "#3B3E45",
    greyishWhite: "#E0E0E0",
    greyishWhite1: "#00000029",
    greyishWhite2: "#A6A6A6",
    greyishWhite3: "#C4C4C4",
    greyishBlack: "#666666",
    greyishBlack2: "#999999",
    greyishBlack3: "#777777",
    greyishWhite4: "#f2f2f2",
    bluishWhite: "#CBE8FC",
    modalTitle: "#000000DE",
    backgroundColor: "#fff",
    fill: "#292B31",
    switchTrack: "#aab4be",
    switchThumb: "#001e3c",
    selectMenuItem: "#f4f4f5",
    headerBackgroundColor: "#F6F9FC",
    tableRowEven: "#FAFAFA",
    tableRowOdd: "#FEFFFF",
    tableDrawerBackgroundColor: "#fff",
    blue: "#0080FE",
    pink: "#F900FF",
    indicatorColor: "#1976d2",
  },
};

export const darkTheme = {
  palette: {
    type: "dark",
    primary: {
      main: "#1976d2",
      light: "#2699FB",
      light1: "rgba(25, 118, 210, 0.5)",
      light2: "rgba(38, 153, 251, 0.2)",
    },
    secondary: {
      main: "#6DC24B",
    },
    warning: {
      main: "#FFDA29",
      light: "#FF9D00",
    },
    error: {
      main: "#FF0000",
      dark: "#c61d1d",
      delete: "#EF3441",
      main1: "#F5A623",
      main2: "#FB262C",
      light: "#FF7C7C",
      light2: "#FF6161",
    },
    green: {
      main: "#3CC66B",
      main1: "#00ff00",
      main2: "#039103",
      main3: "#28C095",
      light1: "#86ffaf",
      light2: "#9CDA00",
    },
    background: {
      default: "#292B31",
      default1: "#292B30",
    },
    blue: {
      main: "#3682F2",
      dark: "#0E61DD",
      light: "#57AEFA",
      darker: "#0151B6",
      light2: "#2699FB",
    },
    orange: {
      main: "#F97316",
      light: "#EA580C",
    },
    static: {
      white: "#fff",
      dullWhite: "#FFFFFE",
      blandWhite: "#F6F9FC",
      mutedWhite: "#FEFEFF",
      lightGrey: "#FDFDFD",
      mildGrey: "#eee",
      mediumGrey: "#999",
      dullGrey: "#454455",
      darkGrey: "#313030",
      blandGrey: "#303238",
      darkerGrey: "#303030",
      pureGrey: "#001E3C",
      almostBlack: "#282B31",
      orange: "#E8934A",
      blandGreen: "#A2F88F",
      strongPing: "#E84A6D",
      subtleYellow: "#E9E94A",
      hardRed: "#f44336",
      lightRed: "#E94E42",
      dullRed: "#f8c9c6",
      pureYellow: "#ffeb3b",
      mediumLavender: "#8759B7",
      darkLavender: "#5B4FB2",
      darkPink: "#AB65BB",
      lightTeal: "#C4FFFD",
      lighterTeal: "#C7FFEA",
      dullTeal: "#DBFAFF",
    },
  },
  divider: "#dddddd",
  shape: {
    borderRadius: "0.375rem",
  },
  colorTheme: {
    white: "#000000",
    black: "#ffffff",
    white1: "#FAFAFA",
    grey: "#F7F7F8",
    greyishWhite: "#E0E0E0",
    greyishWhite1: "#00000029",
    greyishWhite2: "#A6A6A6",
    greyishWhite3: "#C4C4C4",
    greyishWhite4: "#363636",
    greyishBlack: "#666666",
    greyishBlack2: "#999999",
    greyishBlack3: "#777777",
    bluishWhite: "#CBE8FC",
    modalTitle: "#000000DE",
    backgroundColor: "#37373C",
    fill: "#F7F6F8",
    switchTrack: "#8796A5",
    switchThumb: "#003892",
    selectMenuItem: "#3e4149",
    headerBackgroundColor: "#303238",
    tableRowEven: "#292B31",
    tableRowOdd: "#2C2E34",
    tableDrawerBackgroundColor: "#2F3037",
    blue: "#0080FE",
    pink: "#F900FF",
    indicatorColor: "#ffffff",
  },
};
