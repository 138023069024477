import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "./config/applicationConfig";

import ConsentsModule from "./modules/Consents";
import DummyModule from "./modules/Dummy";
import EventsModule from "./modules/Events";
import GraphQLWrapper from "./modules/GraphQLWrapper/GraphQLWrapper";
import HomePage from "./modules/HomePage";
import InsightsModule from "./modules/Insights";
import MarketingProgramsModule from "./modules/MarketingPrograms";
import MonitoringModule from "./modules/Monitoring";
import OptsModule from "./modules/Opts";
import PostmanWrapper from "./modules/Postman";
import SourcesModule from "./modules/Sources";
import SwaggerWrapper from "./modules/Swagger/SwaggerWrapper";
import TasksModule from "./modules/Tasks";
import TraitsModule from "./modules/Traits";
import TraitsResponsesManagementModule from "./modules/TraitsResponses";
import UseCaseManagementModule from "./modules/UseCaseManagement";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/tasks" render={() => <TasksModule />} />
      <Route path="/traits" render={() => <TraitsModule />} />
      <Route path="/sources" render={() => <SourcesModule />} />
      <Route path="/opts" render={() => <OptsModule />} />
      <Route path="/monitoring" render={() => <MonitoringModule />} />
      {applicationConfig.deploymentEnv === "local" && (
        <Route path="/dummy" render={() => <DummyModule />} />
      )}
      <Route
        path="/marketing-programs"
        render={() => <MarketingProgramsModule />}
      />
      {applicationConfig.deploymentEnv !== "prd" && (
        <Route path="/consents" render={() => <ConsentsModule />} />
      )}
      <Route path="/use-cases" render={() => <UseCaseManagementModule />} />
      <Route
        path={["/trait-responses", "/event-property-responses"]}
        render={() => <TraitsResponsesManagementModule />}
      />
      <Route path="/insights" render={() => <InsightsModule />} />
      <Route path="/events" render={() => <EventsModule />} />
      <Route path="/utilities/graphql" render={() => <GraphQLWrapper />} />
      <Route path="/utilities/swagger" render={() => <SwaggerWrapper />} />
      {applicationConfig.deploymentEnv !== "prd" && (
        <Route path="/utilities/postman" render={() => <PostmanWrapper />} />
      )}
      <Route path="/*" render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default Routes;
