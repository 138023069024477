import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import KeyValueEditor from "./KeyValueEditor";

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.orange.light,
    border: "1px solid",
    borderColor: theme.palette.orange.light,
    "&:hover": {
      backgroundColor: theme.palette.orange.light1,
    },
    backgroundColor: theme.colorTheme.white,
  },
}));

const KeyValuePane = ({ paneValue, setPaneValue }) => {
  const styles = useStyles();

  const onKeyPairAdd = () => {
    setPaneValue((upDatedPaneValue) => [
      ...upDatedPaneValue,
      {
        id: uuidv4(),
        keyItem: "",
        valueItem: "",
      },
    ]);
  };

  const onKeyPairRemove = (keyPair) => {
    let newKeyValues = [...paneValue];
    newKeyValues = newKeyValues.filter((x) => x.id !== keyPair.id);
    setPaneValue(newKeyValues);
  };

  const onKeyPairUpdate = (keyPair) => {
    const elementIndex = paneValue.findIndex(
      (element) => element.id === keyPair.id
    );
    const newKeyValues = [...paneValue];
    newKeyValues[elementIndex] = {
      ...newKeyValues[elementIndex],
      keyItem: keyPair.keyItem,
      valueItem: keyPair.valueItem,
    };
    setPaneValue(newKeyValues);
  };

  const renderedList = paneValue.map((keyPair) => {
    return (
      <KeyValueEditor
        key={keyPair.id}
        keyPair={keyPair}
        setKeyPair={(keyPairValue) => onKeyPairUpdate(keyPairValue)}
        onKeyPairRemove={() => onKeyPairRemove(keyPair)}
      />
    );
  });

  return (
    <>
      <div className="">
        {renderedList}
        <button
          type="button"
          className={styles.button}
          onClick={() => onKeyPairAdd()}
        >
          Add
        </button>
      </div>
    </>
  );
};

KeyValuePane.propTypes = {
  paneValue: PropTypes.string.isRequired,
  setPaneValue: PropTypes.func.isRequired,
};

export default KeyValuePane;
