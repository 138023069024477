import "./Postman.css";

import { useState } from "react";

import Layout from "./components/Layout/Layout";
import Request from "./components/Workspace/Request/RequestPanel";
import Response from "./components/Workspace/Response/ResponsePanel";

const PostmanWrapper = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <div className="postman-container">
      <Layout>
        <Request setResponse={setResponse} setLoading={setLoading} />
        <Response response={response} loading={loading} />
      </Layout>
    </div>
  );
};

export default PostmanWrapper;
